import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { workspaceServiceClient } from 'services/backofficeIntegration/http/client/createWorkspaceServiceClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type WorkspaceMemberDto = {
  createdAt?: string;
  /**
   * not set for workspaces imported from legacy team
   */
  createdByUserId?: string;
  id?: string;
  /**
   * for legacy import
   */
  importedFromLegacyTeamCustomerId?: number;
  legacyCustomerId?: number;
  role?: string;
  updatedAt?: string;
  userId?: string;
  workspaceId?: string;
};

type SuccessDto = BaseSuccessDto<WorkspaceMemberDto[]>;

type GetWorkspaceMembersOptions = {
  customerId: number;
};

export const httpGetWorkspaceMembers = mutationEndpoint({
  callEndpoint: ({ customerId }: GetWorkspaceMembersOptions) => {
    return workspaceServiceClient
      .get<SuccessDto>(`/v1/customers/${customerId}/workspace-members`)
      .then(res => res.data.data);
  }
});
