import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import Toast from 'components/toasts/Toast';
import { ImageQuality } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { Payload } from 'features/aiImages/AiImagesPage/useImageGenerator';
import { useGetOptimizedBackgroundImagePrompt } from 'features/design-templates/useGetOptimizedBackgroundImagePrompt';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import AxiosClient from 'services/api';
import { designTemplatesServiceClient } from 'services/backofficeIntegration/http/client/createDesignTemplatesServiceClient';
import { invalidateAiImagesLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/aiImages/httpGetAiImagesLimitations';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import {
  TemplateSelectionsCreateCmd,
  TemplateSelectionsPlacidTemplate,
  TemplateSelectionsTemplateSelection
} from 'services/generated/template-service/types.gen';

type MutationOptions = {
  prompt: string;
  amount: number;
  width?: number;
  height?: number;
  imageQuality?: ImageQuality;
};

export type ResponseDto = {
  image: string;
  accessToken: string;
  templates: Array<TemplateSelectionsPlacidTemplate>;
};

const getCanvasSchemaWithImage = (
  template: TemplateSelectionsPlacidTemplate,
  backgroundImage?: string
) => {
  // canvasSchema contains a prebuild structure to be filled
  if (!template.canvasSchema || !backgroundImage) {
    return template.canvasSchema;
  }

  const prefilledCanvasSchema = { ...template.canvasSchema };
  // Add the background image to all image layers in the canvasSchema
  Object.keys(prefilledCanvasSchema).forEach(key => {
    if (prefilledCanvasSchema[key].type === 'image') {
      // Placid seem to require a plain object
      prefilledCanvasSchema[key] = { image: backgroundImage, type: 'image' };
    }
  });

  return prefilledCanvasSchema;
};

export const useCreateDesignTemplates = () => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  const { mutateAsync: getOptimizedImagePrompt } = useGetOptimizedBackgroundImagePrompt();

  return useMutation({
    mutationFn: async (options: MutationOptions) => {
      if (!workspaceId) {
        throw new Error('Workspace ID is missing, please enable brand voice feature flag');
      }

      let optimizedImagePrompt = await getOptimizedImagePrompt(options.prompt);
      if (!optimizedImagePrompt) {
        optimizedImagePrompt = options.prompt;
      }

      const result = await Promise.all([
        AxiosClient.post<Payload, Payload>('/image-generations', {
          requestCount: 1,
          prompt: optimizedImagePrompt,
          width: options.width,
          height: options.height,
          quality: options.imageQuality
        }).then(response => {
          if (response.status && response.message === 'success') {
            return response.data.image_generations.map(result => result.url);
          }

          throw new Error(response.message);
        }),
        designTemplatesServiceClient
          .post<
            TemplateSelectionsTemplateSelection,
            AxiosResponse<TemplateSelectionsTemplateSelection>,
            TemplateSelectionsCreateCmd
          >(`/workspaces/${workspaceId}/template-selections`, {
            imageAIModel: options.imageQuality || 'high',
            imageHeight: options.height || 1080,
            imageQuality: options.imageQuality || 'high',
            imageWidth: options.width || 1920,
            maxNumberOfTemplates: options.amount,
            prompt: options.prompt
          })
          .then(response => response.data)
      ]);

      const [imageResult, templateResult] = result;
      if (!imageResult || !templateResult) {
        return null;
      }

      const imageSource = imageResult[0];

      return {
        image: imageSource,
        accessToken: templateResult.placidAccessToken,
        templates: templateResult.selectedTemplates.map(template => ({
          ...template,
          canvasSchema: getCanvasSchemaWithImage(template, imageSource)
        }))
      };
    },
    onSettled: () => {
      invalidateAiImagesLimitationsQueries();
      invalidateCustomerAllLimitationsQueries();
    },
    onError: () => {
      Toast.commonError();
    }
  });
};
