import { ModelsGormMap } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoiceFromUrl';

export type GeneralTone = {
  label: string;
  value: number;
};

export const mapRecordToGeneralTone = (gormMap: ModelsGormMap): GeneralTone[] => {
  return Object.entries(gormMap).map(([label, value]) => ({
    label,
    value
  }));
};
