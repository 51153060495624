import { Button, Link, TextField, Typography } from '@mui/material';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { CloseModal } from 'components/modals/types';
import { StyledTextareaAutosize } from 'components/StyledTextareaAutosize';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { informationModalHeight } from 'features/information/InformationModal';
import { TagField } from 'features/information/TagField';
import { useIsTeamOwner } from 'features/team/useIsTeamOwner';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import {
  ModalBodyWithColumnDirectionAndSpaceBetween,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { Form, Formik, useField } from 'formik';
import { useRef } from 'react';
import {
  CreateInformationParams,
  httpCreateInformation
} from 'services/backofficeIntegration/http/endpoints/infomration/httpCreateInformation';
import {
  httpGetInformationCategories,
  InformationCategoryDto
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { invalidateInformationLimitationsQuery } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationLimitations';
import {
  InformationDto,
  invalidateInformationListQuery
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import {
  httpUpdateInformation,
  UpdateInformationParams
} from 'services/backofficeIntegration/http/endpoints/infomration/httpUpdateInformation';
import {
  getNextPageParam,
  PaginatedListParams
} from 'services/backofficeIntegration/http/paginatedListEndpoint';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { object, string } from 'yup';

type Information = {
  name: string;
  description: string;
  content: string;
  tag: InformationCategoryDto | null;
};

const NAME_MAX_CHAR = 50;
const DESCRIPTION_MAX_CHAR = 500;
const CONTENT_MAX_CHAR = 5000;

const validationSchema = object().shape({
  name: string().required().max(NAME_MAX_CHAR),
  description: string().max(DESCRIPTION_MAX_CHAR),
  content: string().max(CONTENT_MAX_CHAR).required(),
  tag: object().required()
});

type Props = {
  preselectedContent?: string;
  preselectedInformation?: InformationDto;
  preselectedTag?: InformationCategoryDto;
  onBackClick: () => void;
} & CloseModal;

export function CopyPasteView({
  preselectedContent,
  preselectedInformation,
  preselectedTag,
  onBackClick,
  closeModal
}: Props) {
  const isEditing = !!preselectedInformation;

  const initialValues: Information = {
    name: (preselectedInformation?.name ?? '').substring(0, NAME_MAX_CHAR),
    description: (preselectedInformation?.description ?? '').substring(0, DESCRIPTION_MAX_CHAR),
    content: (preselectedContent ?? preselectedInformation?.content ?? '').substring(0, CONTENT_MAX_CHAR),
    tag: preselectedTag ?? null
  };

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();
  const isOwner = useIsTeamOwner();

  const { mutate: createInformation } = useMutation({
    mutationFn: (params: CreateInformationParams) => httpCreateInformation.callEndpoint(params),
    onSuccess: () => {
      invalidateInformationListQuery();
      invalidateInformationLimitationsQuery();
    },
    onError: (error: unknown) => {
      const errorCode = getErrorCodeFromAxiosError(error);

      if (errorCode) {
        Toast.error(`information.error.${errorCode}`, {
          upgrade: (text: string) =>
            isOwner && <Link onClick={showUpgradeSubscriptionModal}>{text}</Link>
        });
        return;
      }

      Toast.apiError();
    }
  });

  const { mutate: updateInformation } = useMutation({
    mutationFn: (params: UpdateInformationParams) => httpUpdateInformation.callEndpoint(params),
    onSuccess: () => {
      invalidateInformationListQuery();
    }
  });

  const handleSubmitClick = (values: Information) => {
    // Safty check, should be handled by validationSchema already
    if (!values.tag) {
      Toast.error('information.modal.copy_paste.error.missing_tag');
      return;
    }

    if (isEditing) {
      updateInformation({
        type: 'text',
        informationId: preselectedInformation?.id,
        name: values.name,
        description: values.description,
        content: values.content,
        category_id: values.tag.id
      });
    } else {
      createInformation({
        type: 'text',
        name: values.name,
        description: values.description,
        content: values.content,
        category_id: values.tag.id
      });

      trackingWrapper.track('informationCreated', {
        name: values.name,
        tag: values.tag.name
      });
    }

    closeModal();
  };

  const formikSubmitRef = useRef<() => void>();

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="information.modal.title" />}
      description={<FormattedMessage id="information.modal.copy_paste.body" />}
      helpLink="aiWriter.inspirations.aiwriter.main.information.help_link"
      closeModal={closeModal}
      height={informationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirectionAndSpaceBetween
      }}
      footer={
        <>
          <Button variant="text" onClick={onBackClick}>
            <FormattedMessage id="common.back" />
          </Button>
          <Button
            {...withGtmInteraction(
              isEditing
                ? gtmIds.brandHub.information.updated
                : gtmIds.brandHub.information.createdInformationFromCopyPaste
            )}
            type="button"
            variant="contained"
            onClick={() => formikSubmitRef.current?.()}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </>
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitClick}
        innerRef={formikRef => (formikSubmitRef.current = formikRef?.submitForm)}
      >
        <FormBox>
          <NameField name="name" />
          <DescriptionField name="description" />
          <ContentField name="content" />
          <TagField name="tag" />
        </FormBox>
      </Formik>
    </ModalWithDividedHeaderLayout>
  );
}

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.colors.error};
`;

const FormBox = styled(Form)`
  display: flex;
  flex-direction: column;

  flex: 1;
  gap: ${({ theme }) => theme.spacings.three};
`;

const NameField = (props: { name: string }) => {
  const { name } = props;
  const [field, meta] = useField(name);

  const tr = useTr();

  return (
    <FieldBox>
      <ColoredTypography variant="body2" color="blackMediumEmphasis">
        <FormattedMessage id="information.modal.copy_paste.name" />
      </ColoredTypography>
      <TextField
        {...field}
        {...props}
        error={!!meta.error && meta.touched}
        fullWidth
        placeholder={tr('information.modal.copy_paste.name.placeholder')}
        helperText={
          !!meta.error &&
          tr('information.modal.copy_paste.error.text_limit', { limit: NAME_MAX_CHAR })
        }
      />
    </FieldBox>
  );
};

const DescriptionField = (props: { name: string }) => {
  const { name } = props;
  const [field, meta] = useField(name);

  const tr = useTr();

  return (
    <FieldBox>
      <ColoredTypography variant="body2" color="blackMediumEmphasis">
        <FormattedMessage id="information.modal.copy_paste.description" />
      </ColoredTypography>
      <TextField
        {...field}
        {...props}
        error={!!meta.error && meta.touched}
        fullWidth
        placeholder={tr('information.modal.copy_paste.description.placeholder')}
        helperText={
          !!meta.error &&
          tr('information.modal.copy_paste.error.text_limit', { limit: DESCRIPTION_MAX_CHAR })
        }
      />
    </FieldBox>
  );
};

function ContentField(props: { name: string }) {
  const { name } = props;
  const [field] = useField(name);

  const tr = useTr();
  const isLimitReached = field.value && field.value.length > CONTENT_MAX_CHAR
  return (
    <FieldBox>
      <ColoredTypography variant="body2" color="blackMediumEmphasis">
        <FormattedMessage id="information.modal.copy_paste.content" />
      </ColoredTypography>
        <StyledTextareaAutosize
          {...field}
          {...props}
          placeholder={tr('information.modal.copy_paste.content.placeholder')}
          maxRows={5}
          $hasError={isLimitReached}
        />
      <FlexContainer direction="row" alignItems="center" justifyContent="space-between">
        {isLimitReached && (
          <ErrorText variant="caption">
            <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.definition_word_count_exceed_text" />
          </ErrorText>
        )}
        <Caption $hasError={isLimitReached}>
          {field.value?.length ?? 0}/{CONTENT_MAX_CHAR}
        </Caption>
      </FlexContainer>
    </FieldBox>
  );
}

const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

const Caption = styled(ColoredTypography).attrs(({ $hasError }: { $hasError?: boolean }) => ({
  variant: 'caption',
  color: $hasError ? 'error' : 'blackMediumEmphasis'
}))<{ $hasError?: boolean }>`
  margin-left: ${({ $hasError }) => ($hasError ? undefined : 'auto')};
`;

export function useGetInformationCategoriesQuery(params: {
  name?: string;
  is_paginated?: 0 | 1;
  has_information?: 0 | 1;
}) {
  return useInfiniteQuery({
    queryKey: httpGetInformationCategories.makeQueryKey(params),
    queryFn: (context: { pageParam?: PaginatedListParams }) => {
      const { pageParam = {} } = context;
      return httpGetInformationCategories.callEndpoint({
        ...params,
        ...pageParam,
        // this should be a temporary solution
        is_paginated: params?.is_paginated,
        has_information: params?.has_information
      });
    },
    getNextPageParam: getNextPageParam
  });
}
