import { useModal } from 'components/modals';
import { ModalType } from 'components/modals/modalComponents';
import { ModalProps } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRequestIdHeaderStore } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';

type ReceivedNavigationMessage = {
  type: 'nav';
  path: string;
};

type ReceivedWindowSizeMessage = {
  type: 'size';
  width: number;
  height: number;
};

type ReceivedModalMessage = {
  type: 'show-modal';
  modalId: ModalType;
  options?: ModalProps;
};

type ReceivedRequestIdHeaderMessage = {
  type: 'set-request-id';
  requestId: string;
};

type ReceivedToastErrorCommonMessage = {
  type: 'show-toast-error-common';
};

type ReceivedIframeMessage =
  | ReceivedNavigationMessage
  | ReceivedWindowSizeMessage
  | ReceivedModalMessage
  | ReceivedRequestIdHeaderMessage
  | ReceivedToastErrorCommonMessage;

export function useIframeMessageListener() {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const setRequestId = useRequestIdHeaderStore(state => state.setRequestIdHeader);

  useEffect(() => {
    const onEventMessage = (evt: MessageEvent) => {
      // TODO not working on local
      // if (evt.origin !== brandHubIframeUrl) {
      //   return;
      // }

      const data = evt.data as ReceivedIframeMessage;

      if (data.type === 'nav') {
        navigate(data.path);
      }

      if (data.type === 'show-modal') {
        showModal(data.modalId, data?.options);
      }

      if (data.type === 'size') {
        // if needed, set the size of the iframe
      }

      if (data.type === 'set-request-id') {
        setRequestId(data.requestId);
      }

      if (data.type === 'show-toast-error-common') {
        Toast.commonError();
      }
    };

    window.addEventListener('message', onEventMessage);
    return () => {
      window.removeEventListener('message', onEventMessage);
    };
  }, [navigate, setRequestId, showModal]);
}
