import { GenerateDesignTemplatesOptions } from 'features/design-templates/form/DesignTemplatesForm';
import { PlacidTemplate } from 'features/design-templates/types';
import { useCreateDesignTemplates } from 'features/design-templates/useCreateDesignTemplates';
import { useState } from 'react';

export const useTemplateCreation = () => {
  const { mutateAsync: createDesignTemplates, isLoading: isFetchingTemplates } =
    useCreateDesignTemplates();
  const [templates, setTemplates] = useState<PlacidTemplate[] | null>([]);
  const [createDesignTemplatesOptions, setCreateDesignTemplatesOptions] =
    useState<GenerateDesignTemplatesOptions | null>(null);

  const handleGenerateDesignTemplates = async (options: GenerateDesignTemplatesOptions) => {
    setCreateDesignTemplatesOptions(options);
    setTemplates(null);

    const result = await createDesignTemplates(options);
    if (!result) {
      return;
    }

    setTemplates(
      result.templates.map(t => {
        return { ...t, accessToken: result.accessToken, iterationId: crypto.randomUUID() };
      })
    );

    return true;
  };

  return {
    templates,
    setTemplates,
    createDesignTemplatesOptions,
    isFetchingTemplates,
    handleGenerateDesignTemplates
  };
};
