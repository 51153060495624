import { AutoAwesomeRounded } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip
} from '@mui/material';
import { IntlMessageKeys } from 'features/i18n/IntlMessageKeys';
import { useRef, useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import useTr from 'utils/hooks/useTr';

const optimizeOptions = [
  'general',
  'product',
  'realistic',
  'stock',
  'illustrations',
  'graphics'
] as const;

type OptimizeOptions = typeof optimizeOptions[number];

const optimizeOptionsMap: Record<
  OptimizeOptions,
  { promptKey: string; translation: IntlMessageKeys }
> = {
  general: {
    promptKey: 'general use case',
    translation: 'ai_images.generator_page.optimize_prompt.general'
  },
  product: {
    promptKey: 'Product presentation',
    translation: 'ai_images.generator_page.optimize_prompt.product'
  },
  realistic: {
    promptKey: 'Realistic photos',
    translation: 'ai_images.generator_page.optimize_prompt.realistic'
  },
  stock: {
    promptKey: 'Stock photography',
    translation: 'ai_images.generator_page.optimize_prompt.stock'
  },
  illustrations: {
    promptKey: 'Illustrations',
    translation: 'ai_images.generator_page.optimize_prompt.illustrations'
  },
  graphics: {
    promptKey: 'Graphics',
    translation: 'ai_images.generator_page.optimize_prompt.graphics'
  }
};

type Props = {
  prompt: string;
  optimizeIsFetching: boolean;
  size?: 'small' | 'medium' | 'large';
  onOptimizeClick: (promptKey: string) => void;
};

export const OptimizeButton = ({
  prompt,
  optimizeIsFetching,
  onOptimizeClick,
  size = 'large'
}: Props) => {
  const translate = useTr();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const handleMenuToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleMenuClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);

    const targetPromptKey = optimizeOptionsMap[optimizeOptions[index]].promptKey;

    onOptimizeClick(targetPromptKey);
  };

  const isOptimizePromptButtonDisabled = !prompt.trim() || optimizeIsFetching;

  return (
    <>
      <Tooltip title={translate('ai_images.generator_page.optimize_prompt.tip')}>
        <span>
          <Button
            ref={anchorRef}
            sx={{ textTransform: 'none' }}
            size={size}
            id={gtmIds.aiImages.generatorPage.optimizeButton}
            onClick={handleMenuToggle}
            disabled={isOptimizePromptButtonDisabled}
            startIcon={optimizeIsFetching ? <CircularProgress size={16} /> : <AutoAwesomeRounded />}
          >
            {translate('ai_images.generator_page.optimize_prompt.label')}
          </Button>
        </span>
      </Tooltip>

      <Popper
        sx={{
          zIndex: 99
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList autoFocusItem>
                  {optimizeOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {translate(optimizeOptionsMap[optimizeOptions[index]].translation)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
