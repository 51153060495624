import Divider from '@mui/material/Divider';
import { ColoredTypography } from 'components/ColoredTypography';
import FlexContainer from 'components/FlexContainer';
import { AzureLoginButton } from 'features/social/AzureLogin';
import { GoogleLoginButtonWithProvider } from 'features/social/GoogleLoginButtonWithProvider';
import { LinkedInLoginButton } from 'features/social/LinkedInLogin';
import { OidcLoginButton } from 'features/social/OidcLogin';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export type SocialProviderComponentProps = {
  formName: 'login' | 'register';
  newsletterSubscribed?: boolean;
  isPaid?: boolean;
  campaignUrl?: string;
  preferredPricingId?: number;
  freeTrial?: boolean;
};

type Props = SocialProviderComponentProps;

export const SocialRegisterOrLoginForm = ({
  formName,
  newsletterSubscribed,
  isPaid,
  campaignUrl,
  preferredPricingId,
  freeTrial
}: Props) => (
  <Root gap="three" alignItems="center">
    <GoogleLoginButtonWithProvider
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
      preferredPricingId={preferredPricingId}
      freeTrial={freeTrial}
    />
    <LinkedInLoginButton
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
      preferredPricingId={preferredPricingId}
      freeTrial={freeTrial}
    />
    <AzureLoginButton
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
      preferredPricingId={preferredPricingId}
      freeTrial={freeTrial}
    />
    <OidcLoginButton
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
      preferredPricingId={preferredPricingId}
      freeTrial={freeTrial}
    />

    <DividerContainer direction="row" justifyContent="center" alignItems="center">
      <MuiDivider />
      <StyledTypography variant="caption" color="blackDisabled">
        <FormattedMessage id="common.or" />
      </StyledTypography>
      <MuiDivider />
    </DividerContainer>
  </Root>
);

const DividerContainer = styled(FlexContainer)`
  width: 45%;
`;

const StyledTypography = styled(ColoredTypography)`
  padding: ${({ theme }) => theme.spacings.three};
`;

const Root = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const MuiDivider = styled(Divider)`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
`;
