import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SearchField } from 'components/SearchField';
import { TeamMemberFilter } from 'features/aiWriter/AiWriterProjectOverview/TeamMemberFilter';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import { useInformationProgressNotification } from 'features/information/useInformationProgressNotification';
import { useShowInformationModal } from 'features/information/useShowInformationModal';
import { FilterButton } from 'pages/brand-hub/FilterButton';
import { InformationListTable } from 'pages/brand-hub/informationListTable';
import { TagFilter } from 'pages/brand-hub/tagFilter';
import { BrandHubWarningBanner } from 'pages/brand-hub/warningBanner';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TeamMemberDto } from 'services/backofficeIntegration/http/dtos/TeamMemberDto';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { httpGetInformationLimitations } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationLimitations';
import {
  GetInformationListParams,
  httpGetInformationList
} from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

export function BrandHubInformationTab() {
  const [isFilteringActive, setIsFilteringActive] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const offset = limit * page;

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const [filteredTeamMember, setFilteredTeamMember] = useState<TeamMemberDto | undefined>();
  const [filteredTag, setFilteredTag] = useState<InformationCategoryDto | undefined>();

  const customerRole = useAppSelector(getCustomerRole);
  const isNotOwner = customerRole !== CustomerRole.OWNER;

  const customerHasTeam = useAppSelector(state => state.customer.hasTeam);
  const isTeamMember = customerHasTeam && isNotOwner;

  const queryParams: GetInformationListParams = {
    sort: 'updated_at',
    direction: 'desc',
    offset,
    limit,
    category_id: filteredTag?.id,
    search: debouncedSearchQuery || undefined,
    author_id: filteredTeamMember?.customer_id
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: httpGetInformationList.makeQueryKey(queryParams),
    queryFn: () => httpGetInformationList.callEndpoint(queryParams),
    keepPreviousData: true
  });

  const { data: limitationData, isSuccess: isLimitationQuerySuccess } = useQuery({
    queryKey: httpGetInformationLimitations.makeQueryKey(),
    queryFn: () => httpGetInformationLimitations.callEndpoint()
  });

  const showInformationModal = useShowInformationModal();
  const createProgressToast = useInformationProgressNotification();

  function handleChangePage(_event: unknown | null, page: number) {
    setPage(page);
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
    setPage(0);
  }

  const handleToggleFiltering = () => {
    setIsFilteringActive(!isFilteringActive);
  };

  const handleCreateInformationClick = () => {
    showInformationModal({
      onUploadStarted: () => createProgressToast()
    });
  };

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    );
  }

  const informationList = data.data;
  const total = data.total;

  const isOneAvailableWarning = isLimitationQuerySuccess && limitationData.data.available === 1;
  const isOutOfCreditsWarning =
    isLimitationQuerySuccess &&
    !limitationData.data.isUnlimited &&
    limitationData.data.available === 0;

  return (
    <Root>
      {isOutOfCreditsWarning && (
        <WarningWrapper>
          <BrandHubWarningBanner
            text={
              isTeamMember
                ? 'flashHub.information.limitations.out_of_credits.no_owner_message'
                : 'flashHub.information.limitations.out_of_credits'
            }
            variant="orange"
            gtmUpgradeId="flashHub.information.upgrade"
          />
        </WarningWrapper>
      )}

      {isOneAvailableWarning && (
        <WarningWrapper>
          <BrandHubWarningBanner
            text={
              isTeamMember
                ? 'flashHub.information.limitations.one_left.no_owner_message'
                : 'flashHub.information.limitations.one_left'
            }
            variant="yellow"
            gtmUpgradeId="flashHub.information.upgrade"
          />
        </WarningWrapper>
      )}

      <TableHeader>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          disabled={isOutOfCreditsWarning}
          onClick={handleCreateInformationClick}
          {...withGtmInteraction(gtmIds.brandHub.information.attemptToCreateInformation)}
        >
          <FormattedMessage id="flashHub.information.create_button" />
        </Button>
        <ActionBox>
          <FilterButton
            isFilteringActive={isFilteringActive}
            onToggleFilteringClick={handleToggleFiltering}
          />
          <SearchField value={searchQuery} onChange={handleSearchChange} />
        </ActionBox>
      </TableHeader>
      {isFilteringActive && (
        <FiltersBox>
          <TagFilter value={filteredTag} setValue={setFilteredTag} />
          <TeamMemberFilter value={filteredTeamMember} setValue={setFilteredTeamMember} />
        </FiltersBox>
      )}
      <InformationListTable
        informationList={informationList}
        limit={limit}
        page={page}
        total={total}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.large};

  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

const FiltersBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
`;
