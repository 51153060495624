import { AxiosInstance } from 'axios';
import { apiTokenHeader } from 'services/backofficeIntegration/http/backofficeHeaders';

export const withApiToken = (client: AxiosInstance) => {
  client.interceptors.request.use(request => {
    Object.assign(request.headers, apiTokenHeader(false));

    return request;
  });
};
