import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as CrownIcon } from 'assets/icons/crown.svg';
import { CustomerRole } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { useState } from 'react';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled, { css } from 'styled-components';
import { resetButton } from 'styles/styledComponents/resets';

type Props = {
  text: string;
  variant: Variant;
  gtmUpgradeId?: string;
  rounded?: boolean;
};

type Variant = 'yellow' | 'orange';

export function BrandHubWarningBanner({ text, variant, gtmUpgradeId, rounded }: Props) {
  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const [showWarningBanner, setShowWarningBanner] = useState(true);

  const teamRole = useAppSelector(state => state.customer.teamRole);
  const isOwner = teamRole === CustomerRole.OWNER;

  const handleUpgradeClick = () => {
    showUpgradeSubscriptionModal();
  };

  const handleCloseClick = () => {
    setShowWarningBanner(false);
  };

  if (showWarningBanner) {
    return (
      <Root $variant={variant} $rounded={rounded}>
        <Header>
          <InfoOutlinedIcon />
          <Typography variant="body1" color="inherit">
            <FormattedMessage id={text} />
          </Typography>
        </Header>

        {isOwner && (
          <ActionBox>
            <StyledButton
              {...withGtmInteraction(gtmUpgradeId)}
              onClick={handleUpgradeClick}
              $variant={variant}
            >
              <CrownIcon />
              <FormattedMessage id="flashHub.brandVoice.limitations.upgrade" />
            </StyledButton>
            <IconButton onClick={handleCloseClick}>
              <StyledCloseIcon $variant={variant} />
            </IconButton>
          </ActionBox>
        )}
      </Root>
    );
  }

  return null;
}

const fontColor = css<{ $variant: Variant }>`
  color: ${({ theme }) =>
    theme.mode === 'dark' ? theme.colors.textContrast : theme.colors.textPrimary};
`;

const Root = styled.div<{ $variant: Variant; $rounded?: boolean }>`
  padding: ${({ theme }) => `${theme.spacings.xsmall} ${theme.spacings.medium}`};
  border-radius: ${({ theme, $rounded }) => ($rounded ? '24px' : theme.borderRadius.small)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme, $variant }) => {
    if ($variant === 'yellow') {
      return theme.colors.palette.yellow__500main;
    } else if ($variant === 'orange') {
      return theme.colors.palette.orange__500main;
    }
  }};

  ${fontColor}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

const ActionBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const StyledButton = styled.button<{ $variant: Variant }>`
  ${resetButton}

  ${fontColor}

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

const StyledCloseIcon = styled(CloseIcon)<{ $variant: Variant }>`
  ${fontColor}
`;
