import { useMutation } from '@tanstack/react-query';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type OptimizePromptParams = {
  prompt: string;
  keywords?: string;
};

export const usePromptOptimizerMutation = () => {
  const dispatch = useAppDispatch();
  const audiences = useAppSelector(getUserAudiences);

  const { data: customerPreferences } = useCustomerPreferences();

  return useMutation({
    mutationFn: async ({ prompt, keywords }: OptimizePromptParams) => {
      const language = customerPreferences?.preferredLanguage ?? 'en';
      const country = customerPreferences?.preferredLanguageCountry ?? 'us';
      if (!language || !country) {
        throw new Error('Language or country not found');
      }

      const audience = getPreferredAudience({ audiences, locale: { language, country } });
      if (!audience || !audience.model_id) {
        throw new Error(`Audience (${JSON.stringify(audience || {})}) not found`);
      }

      const result = await generateTexts(
        {
          audienceModelId: audience.model_id,
          nTimes: 1,
          nTextItems: 1,
          outputType: 'picprompt_optimizer'
        },
        { text: prompt, keywords },
        {
          onCustomError: [
            'ERROR_ALL_WORDS_USED',
            () => {
              dispatch(setWordsLimitReached(true));
            }
          ]
        }
      );

      if (result && result.outputs.length > 0) {
        return result.outputs[0].text;
      }

      throw new Error('No optimized prompt returned');
    }
  });
};
