/**
 * Segment.com has upgraded their JavaScript SDK package to v2.
 * This allows us to use the NPM package instead of the snippet.
 *
 * Source: https://github.com/segmentio/analytics-next/tree/master/packages/browser#readme
 * Documentation: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 */
import { getTrackingClient } from 'features/tracking/segment/getTrackingClient';
import { useEffect } from 'react';

export const SegmentLoader = () => {
  useEffect(() => {
    initializeSegment();
  }, []);

  return null;
};

function initializeSegment() {
  const segment = getTrackingClient();

  segment.load(
    {
      writeKey: process.env.REACT_APP_SEGMENT_API_WRITE_KEY
    },
    {
      integrations: {
        'Segment.io': {
          // Handled by out rewrite proxy
          // Paths are like
          // - https://app.neuroflash.com/v1/t
          // - https://app.neuroflash.com/v1/i
          apiHost:
            location.hostname === 'localhost'
              ? 'localhost:4200/api/segment-tracking-service'
              : process.env.REACT_APP_SEGMENT_API_HOST,
          protocol: location.hostname === 'localhost' ? 'http' : 'https'
        }
      }
    }
  );
}
