import { HelpOutlineRounded } from '@mui/icons-material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { ExtendedPromptInput } from 'components/ExtendedPromptInput';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import {
  ImageQuality,
  imageQualityOptions,
  ResolutionOption,
  resolutionOptionMap,
  resolutionOptions
} from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { OptimizeButton } from 'features/aiImages/AiImagesPage/OptimizeButton';
import { useSelectRandomImagePromptExample } from 'features/aiImages/AiImagesPage/promptExamples';
import { usePromptOptimizerMutation } from 'features/aiImages/AiImagesPage/usePromptOptimizerMutation';
import { getGetIsFeatureAvailable } from 'features/features/store/selectors';
import FormattedMessage from 'features/i18n/FormattedMessage';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { ChangeEvent, useState } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

const imageQualitySelectOptions: Record<string, ImageQuality> = {
  best_for_text: 'best_for_text',
  best: 'best',
  default: 'default',
  premium: 'premium'
};

const imageQualityOptionsWithResolution: Array<ImageQuality> = [
  imageQualitySelectOptions.default,
  imageQualitySelectOptions.best,
  imageQualitySelectOptions.best_for_text,
  imageQualitySelectOptions.premium
];

const ImageQualityUpgradeButton = ({ onClick }: { onClick?: () => void }) => (
  <IconButton onClick={onClick}>
    <YellowCrownImage />
  </IconButton>
);

export type GenerateDesignTemplatesOptions = {
  prompt: string;
  amount: number;
  width: number;
  height: number;
  imageQuality: ImageQuality;
};

type Props = {
  onGenerateClick: (options: GenerateDesignTemplatesOptions) => void;
  isGenerateDisabled?: boolean;
};

export const DesignTemplatesForm = ({ onGenerateClick, isGenerateDisabled }: Props) => {
  const translate = useTr();

  const { mutateAsync: optimizePrompt, isLoading: isPromptOptimizerLoading } =
    usePromptOptimizerMutation();

  const getRandomImagePrompt = useSelectRandomImagePromptExample();

  const [prompt, setPrompt] = useState('');
  const [initialPrompt, setInitialPrompt] = useState('');

  const [imageQuality, setImageQuality] = useState<ImageQuality>('default');
  const [resolution, setResolution] = useState<ResolutionOption>('1:1');

  const [isQualitySelectOpen, setIsQualitySelectOpen] = useState(false);
  const [isResolutionSelectOpen, setIsResolutionSelectOpen] = useState(false);

  const canSelectBestImageQuality = useAppSelector(getGetIsFeatureAvailable)('bestImageQuality');
  const canSelectBestForTextImageQuality =
    useAppSelector(getGetIsFeatureAvailable)('bestForTextImageQuality');
  const canChangeImagesResolutions = useAppSelector(getGetIsFeatureAvailable)('imagesResolutions');

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const availableResolutions = imageQualityOptionsWithResolution.includes(imageQuality)
    ? resolutionOptions
    : [resolutionOptions[0]];

  const hasOptimizedPrompt = initialPrompt !== prompt;

  const handleQualitySelectClose = () => {
    setIsQualitySelectOpen(false);
  };

  const handleQualitySelectOpen = () => {
    setIsQualitySelectOpen(true);
  };

  const handleQualityUpgradeClick = () => {
    showUpgradeSubscriptionModal();

    setIsQualitySelectOpen(false);
  };

  const handleImageQualityChange = (event: SelectChangeEvent<ImageQuality>) => {
    const newQuality = event.target.value as ImageQuality;

    // This blocks "Upgrade" option from being selected
    if (!imageQualityOptions.includes(newQuality)) {
      return;
    }

    if (newQuality === imageQualitySelectOptions.best && !canSelectBestImageQuality) {
      return;
    }

    if (
      newQuality === imageQualitySelectOptions.best_for_text &&
      !canSelectBestForTextImageQuality
    ) {
      return;
    }

    if (newQuality === imageQualitySelectOptions.premium && !canSelectBestImageQuality) {
      return;
    }

    setImageQuality(newQuality);
    setIsQualitySelectOpen(false);

    if (!imageQualityOptionsWithResolution.includes(newQuality) && resolution !== '1:1') {
      setResolution('1:1');
    }
  };

  const handleBestForTextDisabledClick = () => {
    if (canSelectBestForTextImageQuality) {
      return;
    }

    handleQualityUpgradeClick();
  };

  const handleBestDisabledClick = () => {
    if (canSelectBestImageQuality) {
      return;
    }

    handleQualityUpgradeClick();
  };

  const handlePromptTextChange = (text: string) => {
    setPrompt(text);
    setInitialPrompt(text);
  };

  const handleExamplePromptButtonClick = () => {
    setPrompt(getRandomImagePrompt());
  };

  const handleOptimizePromptClick = async (keywords: string) => {
    const result = await optimizePrompt({ prompt, keywords });

    setPrompt(result);
  };

  const handlePromptUndoClick = () => {
    setPrompt(initialPrompt);
  };

  const handleGenerateButtonClick = () => {
    onGenerateClick({
      prompt,
      amount: 6,
      width: resolutionOptionMap[resolution].width,
      height: resolutionOptionMap[resolution].height,
      imageQuality
    });
  };

  const handleLearnPromptClick = () => {
    window.open(translate('ai_images.generator_page.learn_prompt.link'), '_blank');
  };

  const handleResolutionChange = (e: SelectChangeEvent<ResolutionOption>) => {
    const selectedResolution = e.target.value as ResolutionOption;

    // This blocks "Upgrade" option from being selected
    if (!availableResolutions.includes(selectedResolution) || !canChangeImagesResolutions) {
      return;
    }

    setResolution(selectedResolution);
    setIsResolutionSelectOpen(false);
  };

  const handleResolutionUpgradeClick = () => {
    showUpgradeSubscriptionModal();
    setIsResolutionSelectOpen(false);
  };

  const handleResolutionSelectClose = () => {
    setIsResolutionSelectOpen(false);
  };

  const handleResolutionSelectOpen = () => {
    setIsResolutionSelectOpen(true);
  };

  return (
    <Root>
      <ExtendedPromptInput
        value={prompt}
        placeholder={translate('ai_images.generator_page.prompt_field.placeholder')}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
          handlePromptTextChange(event.target.value)
        }
        id={gtmIds.aiImages.generatorPage.promptInput}
        autoFocus={true}
        actions={
          <>
            <OptimizeButton
              size="small"
              prompt={prompt}
              onOptimizeClick={handleOptimizePromptClick}
              optimizeIsFetching={isPromptOptimizerLoading}
            />
            <Separator />
            <Button
              id={gtmIds.aiImages.generatorPage.exampleButton}
              onClick={handleExamplePromptButtonClick}
              startIcon={<PrimaryLightbulb />}
              size="small"
            >
              {translate('ai_images.generator_page.example_button.label')}
            </Button>
          </>
        }
        footer={hasOptimizedPrompt && <StyledUndoIcon onClick={handlePromptUndoClick} />}
      />

      <FlexContainer alignItems="flex-end">
        <Button
          variant="text"
          startIcon={<HelpOutlineRounded />}
          onClick={handleLearnPromptClick}
          size="small"
        >
          <FormattedMessage id="ai_images.generator_page.learn_prompt.button" />
        </Button>
      </FlexContainer>

      <StyledButtonsContainer>
        <FormControl {...withTestId('image-quality-button')}>
          <InputLabel>
            <FormattedMessage id="ai_images.generator_page.quality.label" />
          </InputLabel>

          <QualitySelect
            open={isQualitySelectOpen}
            onClose={handleQualitySelectClose}
            onOpen={handleQualitySelectOpen}
            value={imageQuality}
            onChange={handleImageQualityChange}
            label={<FormattedMessage id="ai_images.generator_page.quality.label" />}
            {...withGtmInteraction(gtmIds.aiImages.generatorPage.qualitySelect)}
          >
            <MenuItem value={imageQualitySelectOptions.default}>
              <FormattedMessage id="ai_images.generator_page.quality.default" />
            </MenuItem>

            <PotentialDisabledQualitySelectItem
              value={imageQualitySelectOptions.best_for_text}
              onClick={handleBestForTextDisabledClick}
            >
              <PotentialDisabledQualitySelectItemText>
                <FormattedMessage id="ai_images.generator_page.quality.best_for_text" />
              </PotentialDisabledQualitySelectItemText>

              {!canSelectBestForTextImageQuality && (
                <ImageQualityUpgradeButton onClick={handleQualityUpgradeClick} />
              )}
            </PotentialDisabledQualitySelectItem>

            <PotentialDisabledQualitySelectItem
              value={imageQualitySelectOptions.best}
              onClick={handleBestDisabledClick}
            >
              <PotentialDisabledQualitySelectItemText>
                <FormattedMessage id="ai_images.generator_page.quality.best" />
              </PotentialDisabledQualitySelectItemText>

              {!canSelectBestImageQuality && (
                <ImageQualityUpgradeButton onClick={handleQualityUpgradeClick} />
              )}
            </PotentialDisabledQualitySelectItem>

            <PotentialDisabledQualitySelectItem
              value={imageQualitySelectOptions.premium}
              onClick={handleBestDisabledClick}
            >
              <PotentialDisabledQualitySelectItemText>
                <FormattedMessage id="ai_images.generator_page.quality.premium" />
              </PotentialDisabledQualitySelectItemText>
              {!canSelectBestImageQuality && (
                <ImageQualityUpgradeButton onClick={handleQualityUpgradeClick} />
              )}
            </PotentialDisabledQualitySelectItem>
          </QualitySelect>
        </FormControl>

        <FormControl {...withTestId('ratio-size-button')}>
          <InputLabel>
            <FormattedMessage id="ai_images.generator_page.images.ratio_size" />
          </InputLabel>

          <Select
            open={isResolutionSelectOpen}
            onClose={handleResolutionSelectClose}
            onOpen={handleResolutionSelectOpen}
            value={resolution}
            onChange={handleResolutionChange}
            label={translate('ai_images.generator_page.images.ratio_size')}
          >
            {!canChangeImagesResolutions && (
              <ResolutionUpgradeMenuItem onClick={handleResolutionUpgradeClick}>
                <FormattedMessage id="ai_images.generator_page.images.ratio_upgrade_hint" />
              </ResolutionUpgradeMenuItem>
            )}

            {availableResolutions.map(option => (
              <MenuItem
                key={option}
                value={option}
                disabled={!canChangeImagesResolutions && resolutionOptionMap[option].premium}
              >
                {`${option} (${resolutionOptionMap[option].width}px x ${resolutionOptionMap[option].height}px)`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <GenerateButton
          id={gtmIds.aiImages.generatorPage.generateButton}
          variant="contained"
          disabled={!prompt || isGenerateDisabled}
          startIcon={isGenerateDisabled && <CircularProgress size={20} />}
          onClick={handleGenerateButtonClick}
          size="large"
          {...withTestId('generate-image-button')}
        >
          {translate('ai_images.generator_page.generate_button.label')}
        </GenerateButton>
      </StyledButtonsContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};

  margin-top: ${({ theme }) => theme.spacings.three};

  > * {
    flex: 1;
  }

  @media (max-width: ${mobileWindowWidthCSS}) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
`;

const GenerateButton = styled(Button)``;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  height: 19px;
  width: 1px;
  margin: 0 5px;
`;

const StyledUndoIcon = styled(UndoIcon)`
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
  width: 18px;
`;

const PrimaryLightbulb = styled(LightbulbIcon)`
  color: ${({ theme }) => theme.colors.primaryColorMain};
`;

const QualitySelect = styled(Select)`
  min-width: 120px;
` as typeof Select;

const PotentialDisabledQualitySelectItem = styled(MenuItem)`
  // MUI by default changes the opacity for disabled items, which is also applied to children
  // But we wanna show a upgrade chip, so we need to restore the opacity
  &&.Mui-disabled {
    opacity: 1;

    // For whatever reason changing the opacity re-enables changing the background-color
    // Therefore we need to unset it to no suggest the item can be selected
    &:hover {
      background-color: unset;
    }
  }
  display: flex;
  justify-content: space-between;
`;

const PotentialDisabledQualitySelectItemText = styled(Typography)<{ $isDisabled?: boolean }>`
  // As we removed the opacity reducing in disabled state, we simply change the text color
  color: ${({ theme, $isDisabled }) =>
    $isDisabled ? theme.colors.textDisabled : theme.colors.textPrimary};
`;

const ResolutionUpgradeMenuItem = styled(MenuItem)`
  color: #ed6c02;
`;
