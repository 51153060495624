import { IconButton, Tooltip, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import {
  SmallerCrownCircularProgress,
  SmallerEmotionalityCircularProgress,
  SmallerReadabilityCircularProgress} from 'features/aiWriter/AiWriterSidebar/steps/flashScore/progress-circles';
import { useHasForbiddenDocumentLanguage } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useHasForbiddenDocumentLanguage';
import { ActionsSpinner } from 'features/aiWriter/markdown/actions';
import { TextInspiration } from 'features/aiWriter/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  getPricingPlan
} from 'features/pricing/store/selectors';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

type Props = {
  inspiration: TextInspiration;
};

export const TextInspirationTileFlashScoreRating = ({ inspiration }: Props) => {
  const translate = useTr();
  const disableFlashScoreRating = useHasForbiddenDocumentLanguage();
  const pricingPlan = useAppSelector(getPricingPlan);
  const isOnPlanBelowPro = ['free', 'lite', 'starter', 'standard'].includes(pricingPlan);

  if (disableFlashScoreRating || !inspiration.flashScore) {
    return null;
  }

  if (inspiration.isLoading) {
    return <ActionsSpinner key="spinner" />;
  }

  const { readability, emotionality } = inspiration.flashScore;

  if (isOnPlanBelowPro) {
    return (
      <FlexContainer direction="row" gap="small">
        <Tooltip
          title={
            <FormattedMessage
              id="flashScore.tooltip.upgrade"
            />
          }
        >
          <UnstyledIconButton size="small" color="default">
            <SmallerCrownCircularProgress
              value={0}
            />
          </UnstyledIconButton>
        </Tooltip>
      </FlexContainer>
    );
  }

  return (
    <FlexContainer direction="row" gap="small">
      <Tooltip
        title={
          <FormattedMessage
            id="flashScore.tooltip.emotionality"
            values={{
              emotionality: emotionality.percentage,
              emotionalityLabel: translate(emotionality.label)
            }}
          />
        }
      >
        <UnstyledIconButton size="small" color="default">
          <SmallerEmotionalityCircularProgress
            value={emotionality.percentage}
            label={<Typography fontSize={9}>{emotionality.percentage}</Typography>}
          />
        </UnstyledIconButton>
      </Tooltip>

      <Tooltip
        title={
          <FormattedMessage
            id="flashScore.tooltip.readability"
            values={{
              readability: readability.percentage,
              readabilityLabel: translate(readability.label)
            }}
          />
        }
      >
        <UnstyledIconButton size="small" color="default">
          <SmallerReadabilityCircularProgress
            value={readability.percentage}
            label={<Typography fontSize={9}>{readability.percentage}</Typography>}
          />
        </UnstyledIconButton>
      </Tooltip>
    </FlexContainer>
  );
};

// This is a hack to provide a tooltip to the element even if it sits inside a button,
// but without being interactive as a button.
const UnstyledIconButton = styled(IconButton)`
  &,
  &:hover,
  &:focus-visible {
    all: unset;
    display: inline-flex;
    cursor: pointer;
    pointer-events: all;
  }
`;
