import { useMutation } from '@tanstack/react-query';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { useAppSelector } from 'store/hooks';

type OptimizedImagePromptJson = {
  text_prompt: string;
};

export const useGetOptimizedBackgroundImagePrompt = () => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const audiences = useAppSelector(getUserAudiences);
  const { data: customerPreferences } = useCustomerPreferences();

  return useMutation({
    mutationFn: async (inputPrompt: string) => {
      const language = customerPreferences?.preferredLanguage ?? 'en';
      const country = customerPreferences?.preferredLanguageCountry ?? 'us';
      if (!language || !country) {
        throw new Error('Language or country not found');
      }

      const audience = getPreferredAudience({ audiences, locale: { language, country } });
      if (!audience || !audience.model_id) {
        throw new Error(`Audience (${JSON.stringify(audience || {})}) not found`);
      }

      if (!workspaceId) {
        throw new Error('Workspace ID is missing, please enable brand voice feature flag');
      }

      const optimizedImagePromptResult = await generateTexts(
        {
          audienceModelId: audience.model_id,
          nTimes: 1,
          nTextItems: 1,
          outputType: 'placid_text_to_imageprompt_json'
        },
        { text: inputPrompt }
      );

      try {
        // Text is a formatted JSON string
        const json = optimizedImagePromptResult?.outputs[0]?.text || '';
        if (json && json.length > 0) {
          // Parse the JSON string to get the optimized image prompt
          const parsedJson = JSON.parse(json) as OptimizedImagePromptJson;
          if (parsedJson && parsedJson.text_prompt) {
            return parsedJson.text_prompt;
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error parsing optimized image prompt JSON:', error);
      }

      return null;
    }
  });
};
