import { useInfiniteQuery } from '@tanstack/react-query';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import {
  GetBrandVoicesParams,
  httpGetBrandVoicesList
} from 'services/backofficeIntegration/http/endpoints/new-brand-voice/httpGetBrandVoicesList';
import { ModelsBrandVoice } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import { brandVoicesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/brandVoicesQueryKeys';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type QueryOptions = Partial<
  Pick<GetBrandVoicesParams['body'], 'size' | 'sort' | 'name' | 'search'>
> & {
  sort?: 'empty' | 'most_recent' | 'updated_at';
};

export type SortingMode = NonNullable<QueryOptions['sort']>;

export const useGetBrandVoicesInfinite = (queryOptions?: QueryOptions) => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const pageSize = queryOptions?.size || 6;

  const queryKey = [
    ...brandVoicesQueryKeys.forList(),
    'infinite',
    queryOptions?.size,
    queryOptions?.sort,
    queryOptions?.name,
    queryOptions?.search
  ];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, ...rest } = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      assertNonNullable(workspaceId, 'workspaceId is null');
      const response = await httpGetBrandVoicesList.callEndpoint({
        workspaceId,
        body: {
          withRelations: true,
          page: pageParam,
          size: pageSize,
          sort: queryOptions?.sort,
          name: queryOptions?.name,
          search: queryOptions?.search
        }
      });

      if (!response.data) {
        throw new Error('Failed to fetch brand voices');
      }

      return response.data;
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage.page) {
          return undefined;
        }

        const { currentPage = 1, totalPages = 1 } = lastPage.page;
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      enabled: !!workspaceId
    }
  );

  const brandVoices =
    data?.pages?.reduce<ModelsBrandVoice[]>((acc, page) => {
      // Adding optional chaining to prevent runtime errors
      return acc.concat(page?.data || []);
    }, []) || [];

  // Adding optional chaining to prevent runtime errors
  const totalItems = data?.pages?.[0]?.page?.totalElements || 0;

  return {
    brandVoices,
    totalItems,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    ...rest
  };
};
