import { useGetWorkspaceId } from 'features/workspaces/useGetWorkspaceId';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { useEffect } from 'react';

export const WorkspaceLoader = () => {
  const { data: workspaceId } = useGetWorkspaceId();
  const setWorkspaceId = useWorkspaceStore(state => state.setWorkspaceId);

  useEffect(() => {
    if (workspaceId) {
      setWorkspaceId(workspaceId);
    }
  }, [setWorkspaceId, workspaceId]);

  return null;
};
