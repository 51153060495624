import { Product } from 'services/api/customer/types';
import { ApiCommonErrorCode, ApiExtendedResponse } from 'services/api/types';
import { safeEnum } from 'utils/safeEnum';

export type GetPricingResponse = ApiExtendedResponse<BOACLPricing[], ApiCommonErrorCode>;

export const AppPricingType = safeEnum.make({
  Free: 'Free',
  Lite: 'Lite',
  Basic: 'Basic',
  BasicS: 'Basic S',
  PowerVip: 'Power (VIP)',
  PowerLower: 'Power (lower)',
  PowerHigher: 'Power (higher)',
  Power: 'Power',
  Premium: 'Premium',
  Enterprise: 'Enterprise',
  Business: 'Business',
  NoLimitations: 'no limitations',
  Standard: 'Standard',
  Starter: 'Starter',
  Pro: 'Pro'
} as const);

export type AppPricingType = safeEnum.infer<typeof AppPricingType>;

export const ApiPricingType = safeEnum.make({
  Flex: 'SaaS Flex',
  Light: 'SaaS Light',
  Medium: 'SaaS Medium',
  Advanced: 'SaaS Advanced'
} as const);

export type ApiPricingType = safeEnum.infer<typeof ApiPricingType>;

export type PricingType = ApiPricingType | AppPricingType;

export interface BOACLPricing {
  id: number;
  allow_registration: 1 | 0;
  type: PricingType;
  cost: string;
  currency: string;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
  stripe_price: string;
  stripe_product_id: null;
  product_label: string;
  description?: null | string;
  is_annual: 1 | 0;
  is_free: 1 | 0;
  is_metered_price: 1 | 0;
  is_trial: 1 | 0;
  is_default: 1 | 0;
  ignore_restrictions: 1 | 0;
  has_words_limit: 1 | 0;
  has_fixed_seats: 1 | 0;
  supports_team: 1 | 0;
  words_limit: number;
  gpt4_words_limit: number;
  m_r_r: number;
  a_r_r: number;
  period: number;
  products?: Product[];
}

export type CouponResponse = {
  id: number;
  name: string;
  amount_off: number | null;
  created_at: string;
  duration: string;
  duration_in_months: number | null;
  max_redemptions: number;
  percent_off: number | null;
  redeem_by: string;
  stripe_id: string;
  updated_at: string;
};
