/* eslint-disable @typescript-eslint/no-explicit-any */
import { array, bool, object, string } from 'yup';

import { getValidationForField } from './getValidationForField';

export const uniqueValues = (field: string, message: string) => ({
  name: 'uniqueValues',
  test: (data: Array<{ [field: string]: any }>) => {
    const fieldsList = data.map(row => row[field]);
    const uniqueFields = new Set(fieldsList);
    return fieldsList.length === uniqueFields.size;
  },
  message
});

export const wordTagsSchema = array()
  .of(
    object().shape({
      word: string(),
      isAvailable: bool(),
      checked: bool()
    })
  )
  .min(1, 'validation.word_tag.words_not_empty')
  .test({
    message: 'validation.word_tag.words_must_be_valid',
    test: wordTags =>
      wordTags?.every(tag => !!(tag.isAvailable && tag.checked && tag.word)) ?? false
  });

export const passwordRules = (
  tr: (id: string, values?: Record<string, string | number> | undefined) => string
) =>
  string()
    .matches(/[a-z]/, tr('validation.password.lowercase'))
    .matches(/[A-Z]/, tr('validation.password.uppercase'))
    .matches(/[0-9]/, tr('validation.password.number'))
    .matches(/[@+$!%*#?&^_-]/, tr('validation.password.special_char'))
    .min(8, tr('validation.password.length'))
    .required(tr(getValidationForField('password')));
