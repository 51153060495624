import { WordAttributeOptionValue } from 'types/ReduxStateTypes';

export const wordAttributeOppositeValue: Record<WordAttributeOptionValue, boolean> = {
  easy: false,
  complex: true,

  happy: false,
  sad: true,

  exciting: false,
  calm: true,

  powerful: false,
  gentle: true
};
