import { Typography } from '@mui/material';
import Toast from 'components/toasts/Toast';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { retrieveRequestIdHeader } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';
import useTr from 'utils/hooks/useTr';

type Props = {
  children: ReactNode;
};

export const ToastErrorWithRequestId = ({ children }: Props) => {
  const requestId = retrieveRequestIdHeader();
  const translate = useTr();

  const handleRequestIdClick = async () => {
    if (!requestId) {
      return;
    }

    try {
      await navigator.clipboard.writeText(translate('error.requestId.copy_message', { requestId }));

      Toast.info('common.copied', undefined, {
        autoClose: 1000
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy request ID to clipboard:', error);
    }
  };

  return (
    <>
      {children}
      {requestId && (
        <Typography variant="caption" onClick={handleRequestIdClick}>
          <FormattedMessage id="error.requestId.value" values={{ requestId, br: <br /> }} />
        </Typography>
      )}
    </>
  );
};
