import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLoginButton } from 'features/social/GoogleLoginButton';
import { SocialProviderComponentProps } from 'features/social/SocialRegisterOrLoginForm';

export const GoogleLoginButtonWithProvider = ({
  formName,
  newsletterSubscribed,
  isPaid,
  campaignUrl,
  preferredPricingId,
  freeTrial
}: SocialProviderComponentProps) => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SOCIAL_LOGIN_CLIENT_ID}>
    <GoogleLoginButton
      formName={formName}
      newsletterSubscribed={newsletterSubscribed}
      isPaid={isPaid}
      campaignUrl={campaignUrl}
      preferredPricingId={preferredPricingId}
      freeTrial={freeTrial}
    />
  </GoogleOAuthProvider>
);
