import { PusherProvider, PusherProviderProps } from '@harelpls/use-pusher';
import {
  getIsAuthenticated
} from 'features/customer/store/selectors';
import { ReactNode } from 'react';
import {
  apiTokenHeader
} from 'services/backofficeIntegration/http/backofficeHeaders';
import { makeBackofficeUrl } from 'services/backofficeIntegration/http/backofficeUrls';
import { useAppSelector } from 'store/hooks';

type Props = {
  children: ReactNode;
};

export const BackofficePusherProvider = ({ children }: Props) => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const pusherKey = process.env.REACT_APP_PUSHER_KEY;
  const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER;

  if (!pusherKey || !pusherCluster || !isAuthenticated) {
    return <>{children}</>;
  }

  const pusherProviderProps: PusherProviderProps = {
    clientKey: pusherKey,
    cluster: pusherCluster,
    forceTLS: false,
    authEndpoint: makeBackofficeUrl('/event-broadcast/auth'),
    auth: {
      headers: {
        Accept: 'application/json',
        ...apiTokenHeader(),
      }
    }
  };

  return <PusherProvider {...pusherProviderProps}>{children}</PusherProvider>;
};
