import { Button, Typography } from '@mui/material';
import azure from 'assets/icons/azure-logo.svg';
import LocalStorageKey from 'config/localStorageKey';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { encodeSocialLoginState } from 'features/social/encodeSocialLoginState';
import { socialButtonStyles } from 'features/social/SocialButtonStyles';
import { SocialProviderComponentProps } from 'features/social/SocialRegisterOrLoginForm';
import { useRef } from 'react';
import { getSocialProviderRedirectUri } from 'services/api/social';
import { createUuidV4 } from 'utils/createUuidV4';
import { withTestId } from 'utils/utils';

type Props = SocialProviderComponentProps;

export function AzureLoginButton({ formName, newsletterSubscribed, isPaid, campaignUrl, preferredPricingId, freeTrial }: Props) {
  const stateRef = useRef(createUuidV4());

  const state = encodeSocialLoginState({
    uuid: stateRef.current,
    newsletterSubscribed,
    isPaid,
    campaignUrl,
    preferredPricingId,
    freeTrial
  });

  const messageId = formName === 'login' ? 'social.login.azure' : 'social.signup.azure';

  const redirectUri = getSocialProviderRedirectUri('azure');
  const azureAuthLink = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_AZURE_SOCIAL_LOGIN_CLIENT_ID}&redirect_uri=${redirectUri}&scope=User.Read&response_type=code&state=${state}`;
  return (
    <Button
      css={socialButtonStyles}
      component="a"
      href={azureAuthLink}
      startIcon={<img src={azure} />}
      onClick={() => localStorage.setItem(LocalStorageKey.OauthCsrfState, state)}
      {...withTestId('azure-login-button')}
    >
      <Typography variant="subtitle1">
        <FormattedMessage id={messageId} />
      </Typography>
    </Button>
  );
}
