import { Typography } from '@mui/material';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { updateCurrentUser } from 'features/customer/store/actions';
import { getUserName } from 'features/customer/store/selectors';
import { getNewsletterSubscribed } from 'features/customer/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { clearPreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { InitialConfetti } from 'features/onboardingJourney/InitialConfetti';
import { NewUserSurveyQuestion } from 'features/onboardingJourney/newUserSurvey/NewUserSurveyQuestion';
import { useOnboardingContext } from 'features/onboardingJourney/newUserSurvey/OnboardingContextProvider';
import { useUpdateSurveyAnswers } from 'features/onboardingJourney/newUserSurvey/useUpdateSurveyAnswers';
import { OnboardingFooter } from 'features/onboardingJourney/OnboardingFooter';
import { OnboardingNewsletterCheckbox } from 'features/onboardingJourney/OnboardingNewsletterCheckbox';
import {
  onboardingContentWidth,
  onboardingMobileThreshold,
  OnboardingRoot
} from 'features/onboardingJourney/OnboardingRoot';
import { OnboardingSurveyLanguageField } from 'features/onboardingJourney/OnboardingSurveyLanguageField';
import { useIsDesktop } from 'features/onboardingJourney/useIsDesktop';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { getBrowserLangAndCountry } from 'utils/langUtils';

type Language = {
  language?: string;
  country?: string;
};

type FormValues = {
  language?: string;
  country?: string;
  newsletterSubscribed: boolean;
};

export function OnboardingSurveyOld() {
  const navigate = useNavigate();
  const tr = useTr();
  const username = useAppSelector(getUserName);
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const dispatch = useAppDispatch();
  const newsletterSubscribed = useAppSelector(getNewsletterSubscribed);

  useDisableSidebarOnMount();

  const { questions, newAnswers, existingAnswers, setNewAnswers, isLoading, refetchAnswers } =
    useOnboardingContext();

  const { mutateAsync: updateAnswers, isLoading: isUpdateAnswersLoading } =
    useUpdateSurveyAnswers(newAnswers);
  const { mutateAsync: updateCustomerPreferences } = useUpdateCustomerPreferences();
  const { data: customerPreferences } = useCustomerPreferences();

  const [browserLanguage, browserCountry] = getBrowserLangAndCountry();

  const browserLanguageModelId = models.find(
    model => model.language === browserLanguage && model.country === browserCountry
  )?.id;
  const defaultLanguageModelId = models.find(
    model => model.language === 'en' && model.country === 'us'
  )?.id;
  const initialLanguageModelId = browserLanguageModelId ?? defaultLanguageModelId;
  const initialLanguage = models.find(model => model.id === initialLanguageModelId)?.language;
  const initialCountry = models.find(model => model.id === initialLanguageModelId)?.country;

  const initialValues: FormValues = {
    language: initialLanguageModelId,
    country: browserCountry,
    newsletterSubscribed: newsletterSubscribed
  };

  const hasFinishedLoading =
    !isLoading && !isUpdateAnswersLoading && Array.isArray(questions) && questions.length > 0;

  const isDesktop = useIsDesktop();

  // Show a welcome toast only on desktop
  useEffect(() => {
    if (isLoading || !isDesktop) {
      return;
    }

    Toast.success('aiWriter.onboarding_journey.survey_view.welcome_toast', {
      name: username
    });
  }, [username, isLoading, isDesktop]);

  // TODO: Remove if redirect to onboardingUsecase is back in place
  // This is needed to be done like this to make sure that we don't run into a timing issue with navigating
  // a user back to onboarding too quickly (happens in useNavigateToOnboarding hook) after the preferences
  // update in onboarding-selection step.
  useEffect(() => {
    updateCustomerPreferences({ newUserSurveySeen: true });
  }, [updateCustomerPreferences]);

  const selectableQuestions = questions.filter(question => question.slug !== 'content_type');

  const hasAnsweredCurrentQuestions = !selectableQuestions.some(
    question => !newAnswers[question.id] && !existingAnswers[question.slug]
  );

  const handleNextClick = async (formValues: FormValues) => {
    dispatch(
      updateCurrentUser.request(
        {
          newsletterSubscribed: formValues.newsletterSubscribed
        },
        { thunk: true }
      )
    );

    // if a user has not set preferred language himself we need to set it to the default
    if (!customerPreferences?.preferredLanguage || !customerPreferences?.preferredLanguageCountry) {
      await updateCustomerPreferences({
        preferredLanguage: initialLanguage,
        preferredLanguageCountry: initialCountry
      });
    }

    // Store answers, if any given
    if (Object.entries(newAnswers).length > 0) {
      await updateAnswers();
      // User survey may affect preferred locale so we have to clear the
      // query cache to enforce fresh data. We could probably optimize and
      // check if language was actually changed, but not sure if this is worth it.
      clearPreferredLocaleQuery();
      refetchAnswers();
    }

    // This navigated to onboardingUsecase previously, but we wanna test onboarding without it
    navigate(getRoutePath('onboardingBrandVoiceCreation'));
  };

  const handleSubmit = (values: Language) => {
    const embeddingModel = models.find(model => model.id === values.language);
    const updatedValues = {
      language: embeddingModel?.language,
      country: embeddingModel?.country
    };
    updateCustomerPreferences({
      preferredLanguage: updatedValues.language,
      preferredLanguageCountry: updatedValues.country
    });
  };

  return (
    <OnboardingRoot title={tr('aiWriter.onboarding_journey.survey_view.header')}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(
          formik // This needs to be directly inside Formik
        ) => (
          <>
            <QnAContainer>
              <Form>
                <OnboardingSurveyLanguageField
                  name="language"
                  models={models}
                  onSubmit={handleSubmit}
                />
                {selectableQuestions.map(currentQuestion => (
                  <QnAPair key={currentQuestion.id}>
                    <Question variant="body1">{currentQuestion.question_text}</Question>
                    <Answer key={Math.random()}>
                      <NewUserSurveyQuestion
                        question={currentQuestion}
                        onChange={option =>
                          setNewAnswers(prevState => ({
                            ...prevState,
                            [currentQuestion.id]: option.id
                          }))
                        }
                      />
                    </Answer>
                  </QnAPair>
                ))}

                <OnboardingNewsletterCheckbox
                  name="newsletterSubscribed"
                  label={
                    <FormattedMessage id="aiWriter.onboarding_journey.newsletter_subscribed" />
                  }
                />
              </Form>
            </QnAContainer>

            {hasFinishedLoading && <InitialConfetti />}
            <OnboardingFooter
              nextButtonProps={{
                onClick: () => handleNextClick(formik.values),
                disabled: !hasAnsweredCurrentQuestions
              }}
            />
          </>
        )}
      </Formik>
    </OnboardingRoot>
  );
}

const QnAContainer = styled.div`
  width: ${onboardingContentWidth};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};

  @media (max-width: ${onboardingMobileThreshold}) {
    max-width: 100%;
    gap: ${({ theme }) => theme.spacings.two};
  }
`;

export const QnAPair = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

export const Question = styled(Typography)`
  justify-self: start;
`;

export const Answer = styled.div`
  justify-self: end;
`;
