import { Table, TableBody } from '@mui/material';
import { FeatureTableRow } from 'components/profile/components/feature-comparison/FeatureTableRow';
import { FeatureTableRowData } from 'components/profile/components/feature-comparison/FeatureTableRowData';
import {
  SectionTitle,
  StyledTableContainer
} from 'components/profile/components/pricing-plan-card/Components';
import { FormattedMessage } from 'react-intl';

const collaborationData: FeatureTableRowData[] = [
  {
    nameTranslationKey: 'features_comparison.collaboration.history_favorites',
    litePlan: true,
    starterPlan: true,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.collaboration.invite_users',
    litePlan: false,  
    starterPlan: false,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.collaboration.collaboration',
    litePlan: false,
    starterPlan: false,
    proPlan: true,
    businessPlan: true
  },
  {
    nameTranslationKey: 'features_comparison.collaboration.workspace_roles',
    litePlan: false,
    starterPlan: false,
    proPlan: true,
    businessPlan: true
  }
];

export const CollaborationTable = () => {
  return (
    <StyledTableContainer>
      <SectionTitle>
        <FormattedMessage id="features_comparison.collaboration.label" />
      </SectionTitle>
      <Table>
        <TableBody>
          {collaborationData.map(row => (
            <FeatureTableRow key={row.nameTranslationKey} row={row} />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
