import { Box, CircularProgress, InputAdornment, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import { AiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import { TonalitySelect } from 'features/aiWriter/tonality/TonalitySelect';
import { BrandVoiceSelect } from 'features/brand-voice/BrandVoiceSelect';
import { useSetDefaultBrandVoiceMutation } from 'features/brand-voice/useSetDefaultBrandVoiceMutation';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { useShowApplyInformationModal } from 'features/information/apply-information/ApplyInformationModal';
import { InformationField } from 'features/information/apply-information/InformationField';
import { FormValues } from 'features/workflowSocialPostBuilder/SocialPostBuilderFormikProvider';
import { SocialPostBuilderStepLayout } from 'features/workflowSocialPostBuilder/SocialPostBuilderStepLayout';
import { SocialBuilder } from 'features/workflowSocialPostBuilder/WorkflowSchema';
import { useFormikContext } from 'formik';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FormattedMessage } from 'react-intl';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const Step2 = () => {
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const translate = useTr();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const model = getModelById(values.embeddingModelId);
  // It's ensured in the layout that this step won't be rendered if the model doesn't support tonalities
  const languageAndCountryCode =
    `${model.language}_${model.country}` as AiWriterSupportedLanguageAndCountryCode;

  const useBrandVoice = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();
  const { mutate: setDefaultBrandVoice } = useSetDefaultBrandVoiceMutation();

  const params = { language: model.language, country: model.country };
  const defaultPersonalityQuery = useQuery({
    queryKey: httpGetDefaultPersonality.makeQueryKey(params),
    queryFn: () => httpGetDefaultPersonality.callEndpoint(params),
    onSuccess: data => {
      if (data && !values.personalityId) {
        setFieldValue('personalityId', data);
      }
    }
  });

  const showApplyInformationModal = useShowApplyInformationModal();

  const handlePersonalityChange = (personality: PersonalityDto | undefined | null) => {
    if (personality) {
      setDefaultPersonality({
        personalityId: personality.id,
        language: model.language,
        country: model.country
      });
    }

    setFieldValue('personalityId', personality);
  };

  const handleBrandVoiceChange = (brandVoiceId: string) => {
    setDefaultBrandVoice({
      brandVoiceId
    });
    setFieldValue('brandVoiceId', brandVoiceId);
  };

  const handleApplyInformation = () => {
    showApplyInformationModal({
      preselectedInformation: values.informationList,
      onApply: informationList => {
        setFieldValue('informationList', informationList);
      }
    });
  };

  const handleInformationRemove = (id: number) => {
    setFieldValue(
      'informationList',
      values.informationList?.filter(information => information.id !== id)
    );
  };

  return (
    <SocialPostBuilderStepLayout>
      <Typography variant="h6">
        <FormattedMessage id="social_post_builder.step1.title" />
      </Typography>

      <TonalitySelect
        label={translate('social_post_builder.tonality_step.label')}
        systemTonality={values.systemTonality}
        userTonality={values.userTonality}
        maxLength={2}
        languageAndCountryCode={languageAndCountryCode}
        onChange={(newSystemTonalities, newUserTonalities) => {
          setFieldValue('systemTonality', newSystemTonalities);
          setFieldValue('userTonality', newUserTonalities);
        }}
        onInputChange={inputValue => {
          // Disable nex step if user entered a tonality but didn't confirmed it
          setFieldValue('disableNextStep', inputValue.length > 0);
        }}
        gtmAttributes={withGtmInteraction(gtmIds.socialPostBuilder.tonality)}
      />

      <FullWidthContainer>
        <SocialBuilder.Label
          labelId={translate('common.brandVoice')}
          tooltipId="social_post_builder.tonality_step.brandVoice.tooltip"
          linkId="aiWriter.inspirations.aiwriter.main.personality.help_link"
        />
        {useBrandVoice ? (
          <BrandVoiceSelect value={values.brandVoiceId} onSelect={handleBrandVoiceChange} />
        ) : (
          <PersonalityCommonSelector
            value={values.personalityId}
            onChange={handlePersonalityChange}
            preselectedModelId={model.id}
            isPropsLoading={defaultPersonalityQuery.isLoading}
            size="small"
            fullWidth={true}
            startAdornment={
              <InputAdornment position="start">
                {defaultPersonalityQuery.isLoading ? (
                  <Box sx={{ display: 'grid' }}>
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  <PersonalityIcon />
                )}
              </InputAdornment>
            }
          />
        )}
      </FullWidthContainer>

      <InformationField
        informationList={values.informationList}
        onApply={handleApplyInformation}
        onRemove={handleInformationRemove}
        fontVariant="subtitle1"
      />
    </SocialPostBuilderStepLayout>
  );
};

const FullWidthContainer = styled(FlexContainer).attrs({ gap: 'one' })`
  width: 100%;
`;
