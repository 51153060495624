import { useMutation } from '@tanstack/react-query';
import { getUserId } from 'features/customer/store/selectors';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { httpPostBrandVoiceDefault } from 'services/backofficeIntegration/http/endpoints/new-brand-voice/httpPostBrandVoiceDefault';
import { useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const useSetDefaultBrandVoiceMutation = () => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const customerId = useAppSelector(getUserId);

  return useMutation({
    mutationFn: ({ brandVoiceId }: { brandVoiceId: string }) => {
      assertNonNullable(workspaceId, 'workspaceId is null');

      return httpPostBrandVoiceDefault.callEndpoint({ brandVoiceId, workspaceId, customerId });
    }
  });
};
