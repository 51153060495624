import { Container } from 'components/Container';
import FlexContainer from 'components/FlexContainer';
import { AiImagesGeneratorContextProvider } from 'features/aiImages/AiImagesPage/AiImagesGeneratorContext';
import { GeneratorForm } from 'features/aiImages/AiImagesPage/GeneratorForm';
import { ImagesBox } from 'features/aiImages/AiImagesPage/ImagesBox';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { PricingUpgradeButton } from 'features/pricing/PricingUpgradeButton';
import { UsageIndicator } from 'features/usageIndicator/UsageIndicator';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import { ReactElement } from 'react';
import styled from 'styled-components';

export const AiImagesPage = (): ReactElement => {
  useEnableSidebarOnMount();
  useNavigateToOnboarding();

  return (
    <AiImagesGeneratorContextProvider>
      <FlexContainer direction="row" justifyContent="flex-end" alignItems="flex-end" gap="three">
        <UsageBox>
          <UsageIndicator type={UsageIndicatorType.aiImages} hasAccess={true} />
        </UsageBox>
        <PricingUpgradeButton size="medium" />
      </FlexContainer>
      <Body>
        <GeneratorForm />
        <ImagesBox />
      </Body>
    </AiImagesGeneratorContextProvider>
  );
};

const Body = styled(Container).attrs({ size: 'lg' })`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.four};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.four};
`;

const UsageBox = styled.div`
  width: 100%;
  max-width: 144px;
`;
