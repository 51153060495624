import { ReactComponent as AcademyIcon } from 'assets/sidebar/icon-academy.svg';
import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import gtmIds from 'services/tracking/GTMIds';
import useTr from 'utils/hooks/useTr';

export const SidebarItemAcademyButton = () => {
  const translate = useTr();

  const handleClick = () => {
    window.open(translate('layout.sidebar.actions.notifications.academy.link'), '_blank');
  };

  return (
    <SidebarItem
      gtmId={gtmIds.sidebar.academy}
      onClick={handleClick}
      icon={<AcademyIcon />}
      name="layout.sidebar.actions.academy"
      tooltip="layout.sidebar.actions.academy"
      showHoverIcon
    />
  );
};
