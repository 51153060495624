import { ListItemIcon, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import CountryFlag from 'components/base/CountryFlag';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import { sortModelsByLanguageAlphabetically } from 'features/embeddingModels/store/utils/sortModelsByLanguage';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';
import { withTestId } from 'utils/utils';

type Props = {
  value: EmbeddingModel;
  onChange: (value: EmbeddingModel) => void;

  selectProps?: SelectProps<string>;
};

// this Selector is more generic that doesn't need to Formik
export function EmbeddingModelSelect({ value, onChange, selectProps }: Props) {
  const [modelId, setModelId] = useState(value.id);

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  const sortedModels = useMemo(() => sortModelsByLanguageAlphabetically(models), [models]);

  const handleChange = (event: SelectChangeEvent) => {
    setModelId(event.target.value);

    const model = getModelById(models, event.target.value);
    onChange(model);
  };

  return (
    <Select
      {...selectProps}
      value={modelId}
      onChange={handleChange}
      renderValue={selected => getModelById(models, selected).label}
      {...withTestId('embedding-model-select')}
    >
      {sortedModels.map(model => (
        <MenuItem key={model.id} value={model.id} {...withTestId('embedding-model-item')}>
          <ListItemIcon>
            <CountryFlag country={model.country} language={model.language} />
          </ListItemIcon>
          {model.label}
        </MenuItem>
      ))}
    </Select>
  );
}

function getModelById(models: EmbeddingModel[], id: string) {
  const model = models.find(model => model.id === id);
  assertNonNullable(model, `Embedding model with id ${id} not found`);

  return model;
}
