import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { GetPromptResponse } from 'services/api/wordEmbedding/types';
import { dataScienceAnonymousClient } from 'services/backofficeIntegration/http/client/createDataScienceClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type GetPromptsSuccessDto = GetPromptResponse;

export const httpGetPromptsByLanguageCountry = queryEndpoint({
  makeQueryKey: () => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'promptservice',
      'text-types',
      'list-by-language-country'
    ] as const;
  },
  callEndpoint: (language: string, country: string) => {
    return dataScienceAnonymousClient
      .get<GetPromptsSuccessDto>(`/promptservice/text-types/${language}/${country}`)
      .then(response => response.data);
  }
});
