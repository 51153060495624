import {
  GenerateTextsConfig,
  GenerateTextsInput
} from 'features/textGenerator/actions/generateTexts';
import { GenerateTextsRequest } from 'services/api/wordEmbedding/types';

export const prepareGenerateTextsApiPayload = (
  config: GenerateTextsConfig,
  {
    text,
    keywords,
    keywords2,
    suggestions,
    systemTonality = [],
    userTonality = [],
    length,
    personalityId,
    informationList,
    brandVoiceId
  }: GenerateTextsInput
): GenerateTextsRequest => {
  const tonality = [...systemTonality, ...userTonality];

  // #tech-debt https://app.clickup.com/t/862jfun07
  if (text) {
    return {
      project_id: config.projectId,
      audience_model_id: config.audienceModelId,
      output_type: config.outputType,
      n_times: config.nTimes,
      n_text_items: config.nTextItems,
      text,
      keywords: keywords || undefined,
      keywords2: keywords2 || undefined,
      // Avoid sending an empty array
      tonality: tonality.length === 0 ? undefined : tonality,
      length: length || null,
      personality_id: personalityId || null,
      information_ids: informationList?.map(info => info.id),
      brand_voice_id: brandVoiceId
    };
  }

  return {
    audience_model_id: config.audienceModelId, // is not a word embedding model. Available on the project.
    n_times: config.nTimes,
    n_text_items: config.nTextItems,
    output_type: config.outputType,

    keywords,
    keywords2,
    example: suggestions?.[0] || undefined,
    tonality: tonality.length === 0 ? undefined : tonality,
    length: length || null,
    personality_id: personalityId,
    information_ids: informationList?.map(info => info.id),
    brand_voice_id: brandVoiceId
  };
};
