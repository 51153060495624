import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { GetPromptResponse } from 'services/api/wordEmbedding/types';
import { dataScienceAnonymousClient } from 'services/backofficeIntegration/http/client/createDataScienceClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type GetPromptsSuccessDto = GetPromptResponse;

export const httpGetPromptsByLanguage = queryEndpoint({
  makeQueryKey: () => {
    return [
      ...makeCurrentUserQueryKeyPrefix(),
      'promptservice',
      'text-types',
      'list-by-language'
    ] as const;
  },
  callEndpoint: (language: string) => {
    return dataScienceAnonymousClient
      .get<GetPromptsSuccessDto>(`/promptservice/text-types/${language}/`)
      .then(response => response.data);
  }
});
