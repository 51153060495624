import { Stack } from '@mui/material';
import IconNeuroflashLogoSVG from 'assets/neuroflash-logo.svg';
import IconDarkNeuroflashLogoSVG from 'assets/neuroflash-mix-logo-dark-mode.svg';
import analyzeing from 'assets/onboarding/stepper-analyzing.svg';
import create from 'assets/onboarding/stepper-create.svg';
import result from 'assets/onboarding/stepper-result.svg';
import welcome from 'assets/onboarding/stepper-welcome.svg';
import { getUserName } from 'features/customer/store/selectors';
import { StepPageHero } from 'features/onboardingJourney/components/StepPageHero';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useIsDarkMode } from 'styles/new-theme/useAppThemeStore';
import { mobileWindowWidthCSS } from 'utils/hooks/useMobileVersion';

type Step = 'welcome' | 'create' | 'analyzeing' | 'result';

interface StepImageInfo {
  src: string;
  width: number;
}

const stepImages: Record<Step, StepImageInfo> = {
  welcome: { src: welcome, width: 50 },
  create: { src: create, width: 91 },
  analyzeing: { src: analyzeing, width: 158 },
  result: { src: result, width: 150 }
};

type Props = PropsWithChildren<{
  currentStep: Step;
}>;

export const OnboardingStepper = ({ currentStep, children }: Props) => {
  const name = useAppSelector(getUserName);

  const isDarkMode = useIsDarkMode();

  const neuroflashLogo = isDarkMode ? IconDarkNeuroflashLogoSVG : IconNeuroflashLogoSVG;

  return (
    <>
      <NeuroflashLogo src={neuroflashLogo} />
      <Root>
        <GridRoot>
          <GridItemContainer>
            <StepPageHero
              title={
                <FormattedMessage
                  id={`onboarding.step_${currentStep}.title`}
                  values={{ br: <br />, name }}
                />
              }
              subtitle={<FormattedMessage id={`onboarding.step_${currentStep}.subtitle`} />}
              image={
                <img
                  src={stepImages[currentStep].src}
                  alt=""
                  width={stepImages[currentStep].width}
                />
              }
            />
          </GridItemContainer>

          <GridItemContainer>
            <RightItemContentContainer spacing={4}>{children}</RightItemContentContainer>
          </GridItemContainer>
        </GridRoot>
      </Root>
    </>
  );
};

const Root = styled(Stack)`
  margin-inline: auto;
  margin-block: auto;
  /* Compensate for parent padding to maintain strict center */
  padding-bottom: 24px;
`;

const GridRoot = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacings.seven};

  align-items: center;

  @media (max-width: ${mobileWindowWidthCSS}) {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacings.four};
  }
`;

const GridItemContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 530px;

  @media (max-width: ${mobileWindowWidthCSS}) {
    min-height: 0;
  }
`;

const RightItemContentContainer = styled(Stack)`
  width: 100%;
`;

const NeuroflashLogo = styled.img`
  position: absolute;
  top: ${({ theme }) => theme.spacings.medium};
  left: ${({ theme }) => theme.spacings.medium};
  margin-bottom: 5vh;

  @media (max-width: ${mobileWindowWidthCSS}) {
    margin-top: 0;
    position: static;

    width: 200px;
  }
`;
