import { CloseModal } from 'components/modals/types';
import { closeTab } from 'features/explorer/store/slice';
import { removeList } from 'features/lists/store/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { promisifySagaThunk } from 'utils/utils';

import ConfirmModal from '../../../modals/ConfirmModal';

type Props = CloseModal;

const RemoveListModal = ({ closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const { currentList, isLoading } = useAppSelector(state => state.lists);
  const { tabs } = useAppSelector(state => state.explorer);

  return (
    <ConfirmModal
      title="common.confirm"
      message="lists.remove_confirmation"
      isLoading={isLoading}
      onCancel={closeModal}
      onOK={() => {
        const correspondingTab = Object.keys(tabs).find(id => tabs[id].listId === currentList);

        dispatch(promisifySagaThunk(removeList.request, currentList)).then(() => {
          if (correspondingTab) {
            dispatch(closeTab(correspondingTab));
          }

          closeModal();
        });
      }}
    />
  );
};

export default RemoveListModal;
