/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommentsProvider } from '@udecode/plate-comments';
import { Plate, Value } from '@udecode/plate-common';
import { aiWriterPlateId } from 'features/aiWriter/AiWriter';
import { migrateOldDocument } from 'features/aiWriter/AiWriterTextEditor/EditorProvider/migrateOldDocument';
import { updateText } from 'features/aiWriter/store/actions/editor/actions';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import {
  getActiveTab,
  getEditorValue,
  getGeneratingTextInEditor,
  getSeoHighlightedKeyword
} from 'features/aiWriter/store/selectors';
import { TooltipProvider } from 'features/plate/components/plate-ui/tooltip';
import { commentsUsers, myUserId } from 'features/plate/lib/plate/comments';
import { usePlatePluginConfiguration } from 'features/plate/lib/plate/plate-plugins';
import { throttle } from 'lodash';
import { memo, ReactElement, ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type Props = {
  readonly: boolean;
  children: ReactNode;
};

export const EditorProvider = memo(({ readonly, children }: Props): ReactElement => {
  const tabId = useAppSelector(getActiveTab).id;
  const value = useAppSelector(getEditorValue);
  const textGenerationActive = useAppSelector(getGeneratingTextInEditor);

  const dispatch = useAppDispatch();

  const onChange = throttle((value: Value) => {
    if (!tabId) {
      return;
    }

    // Also pause during text generation
    if (textGenerationActive) {
      return;
    }

    dispatch(updateText({ tabId, value }));
    dispatch(updateCurrentProjectInBackgroundThunk());
  }, 1000);

  const seoHighlightedKeyword = useAppSelector(getSeoHighlightedKeyword);
  const plugins = usePlatePluginConfiguration({ search: seoHighlightedKeyword });

  /*
  We need `key` prop here to reset editor content properly when switching tabs.
  Test case:
  1) Open any document with some content
  2) Create new document using `+` button
  If `key` is not provided then content of the previous document will stay in
  the editor, but it should be empty.
  This "trick" is related to how react identifies components. You can
  read more about it here:
  https://beta.reactjs.org/learn/preserving-and-resetting-state#option-2-resetting-state-with-a-key

  According to docs (https://plate.udecode.io/docs/Plate#plateprovider-props)
  if `PlateProvider` is defined explicitly then `value`, `onChange` and some other props
  should be placed on it instead of on `Plate` component. Even though `plugins` was not
  mentioned there it did not work otherwise so I moved it too. This code you can find code
  responsible for props drilling here:
  https://github.com/udecode/plate/blob/main/packages/core/src/components/plate/Plate.tsx#L22-L26.
  */

  return (
    <TooltipProvider>
      <DndProvider backend={HTML5Backend}>
        <CommentsProvider users={commentsUsers} myUserId={myUserId}>
          <Plate
            id={aiWriterPlateId}
            key={tabId}
            plugins={plugins}
            initialValue={migrateOldDocument(value)}
            onChange={readonly ? undefined : onChange}
          >
            {children}
          </Plate>
        </CommentsProvider>
      </DndProvider>
    </TooltipProvider>
  );
});
