import { GenerateDesignTemplatesOptions } from 'features/design-templates/form/DesignTemplatesForm';
import { PlacidTemplate } from 'features/design-templates/types';
import { useGenerateTemplateTexts } from 'features/design-templates/useGenerateTemplateTexts';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useTemplateTextGeneration = (
  templates: PlacidTemplate[] | null,
  setTemplates: Dispatch<SetStateAction<PlacidTemplate[] | null>>,
  createDesignTemplatesOptions: GenerateDesignTemplatesOptions | null
) => {
  const { mutateAsync: generateTemplateTexts } = useGenerateTemplateTexts();
  const [hasGeneratedTexts, setHasGeneratedTexts] = useState(false);

  useEffect(() => {
    if (hasGeneratedTexts || !createDesignTemplatesOptions || !templates || !templates.length) {
      return;
    }

    setHasGeneratedTexts(true);

    (async () => {
      const result = await generateTemplateTexts({
        userPrompt: createDesignTemplatesOptions.prompt
      });
      if (!result) {
        return;
      }

      // Apply texts to the canvasSchema of all templates
      setTemplates(
        prevTemplates =>
          prevTemplates?.map(template => {
            const updatedCanvasSchema = {
              ...template.canvasSchema,
              headline: {
                ...template.canvasSchema.headline,
                text: result.headline.text
              },
              subtitle: {
                ...template.canvasSchema.subtitle,
                text: result.subtitle.text
              },
              'primary-color': {
                ...template.canvasSchema['primary-color'],
                color: result['primary-color'].color
              }
            };

            return { ...template, canvasSchema: updatedCanvasSchema };
          }) ?? null
      );
    })();
  }, [
    createDesignTemplatesOptions,
    generateTemplateTexts,
    hasGeneratedTexts,
    templates,
    setTemplates
  ]);

  const resetTextGeneration = () => {
    setHasGeneratedTexts(false);
  };

  return {
    hasGeneratedTexts,
    resetTextGeneration
  };
};
