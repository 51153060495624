import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { RecaptchaProvider } from 'components/RecaptchaProvider';
import { TrialEnforcedRegistrationPanel } from 'components/register/TrialEnforcedRegistrationPanel';
import { encodeSocialLoginState } from 'features/social/encodeSocialLoginState';
import { JwtLoginSnippet } from 'pages/login/LoginPage';
import { useSearchParams } from 'react-router-dom';
import { authService } from 'services/auth/AuthService';

export const TrialEnforcedRegistrationPage = () => {
  useRedirectWhenAuthenticated();

  const [searchParams] = useSearchParams();

  if (authService.isEnabled() && authService.isGlobal()) {
    const preferredPricingIdParam = searchParams.get('preferred_pricing_id');
    const enforceFreeTrialParam = searchParams.get('free_trial');
    const preferredPricingId = preferredPricingIdParam ? parseInt(preferredPricingIdParam, 10) : undefined;
    const enforceFreeTrial = enforceFreeTrialParam === 'true' ? true : enforceFreeTrialParam === 'false' ? false : undefined;

    const state = encodeSocialLoginState({
      preferredPricingId,
      freeTrial: enforceFreeTrial,
    }); 

    return <JwtLoginSnippet state={state} prompt="create"></JwtLoginSnippet>;
  }

  return (
    <RecaptchaProvider>
      <TrialEnforcedRegistrationPanel />
    </RecaptchaProvider>
  );
};
