import { useMutation } from '@tanstack/react-query';
import { insertText } from '@udecode/plate';
import { findNode, insertNodes, removeNodes } from '@udecode/plate-common';
import { TElement } from '@udecode/slate';
import { createProgressToast } from 'components/toasts/createProgressToast';
import Toast from 'components/toasts/Toast';
import useEditor from 'features/aiWriter/AiWriterTextEditor/hooks/useEditor';
import { markdownToSlate } from 'features/aiWriter/markdown/markdownToSlate';
import { setGeneratingTextInEditor } from 'features/aiWriter/store/actions/editor/actions';
import { updateCurrentProjectInBackgroundThunk } from 'features/aiWriter/store/actions/project/thunks/updateCurrentProjectInBackground';
import {
  getActiveTab,
  getCurrentTabId,
  getGenerateTextConfig,
  getGeneratingTextInEditor
} from 'features/aiWriter/store/selectors';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { setWordsLimitReached } from 'features/customer/store/actions';
import { invalidateWordsUsageQueries } from 'features/wordsUsage/invalidateWordsUsageQueries';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import {
  EndEvent,
  httpGenerateTextStream,
  StartEvent,
  StreamError,
  UpdateEvent
} from 'services/backofficeIntegration/http/endpoints/textGeneration/httpGenerateTextStream';
import { ResponseError } from 'services/backofficeIntegration/http/processEventStream';
import { useAppDispatch, useAppSelector } from 'store/hooks';

/**
 * This is a custom Slate node type that is used to mark the generated text.
 * This type is used to identify the current node during the generation process.
 * Once the generation is complete, we remove all nodes with this type and
 * replace them with the inner children.
 */
export const ELEMENT_GENERATE_TEXT_MARKER = 'text-generation-marker';

type GeneratorConfig = {
  outputType: string;
  toasts: {
    loading: JSX.Element;
    success: JSX.Element;
    error: JSX.Element;
  };
};

type GeneratorProps = {
  text: string;
  keywords?: string;
};

export function useGenerateTextInDocument(config: GeneratorConfig) {
  const { toasts, outputType } = config;
  const editor = useEditor();
  const dispatch = useAppDispatch();
  const currentTabId = useAppSelector(getCurrentTabId);
  const tab = useAppSelector(getActiveTab);
  const textGenerationActive = useAppSelector(getGeneratingTextInEditor);
  const { audienceId } = useAppSelector(getGenerateTextConfig);
  const { audienceModelId } = useAudienceConfig({ audienceId });

  const abortControllerRef = useRef<AbortController>();
  const textBufferRef = useRef('');

  const onStreamStart = () => {
    textBufferRef.current = '';
  };

  const onStreamEnd = () => {
    const nodeAndPath = findNode(editor, {
      match: n => n.type === ELEMENT_GENERATE_TEXT_MARKER
    });

    if (!nodeAndPath) {
      // eslint-disable-next-line no-console
      console.error('Failed to find custom generation node.');
      return;
    }

    const [node] = nodeAndPath;

    // Create a copy from the children and remove the node
    const nodeChildren = [...(node as TElement).children];
    removeNodes(editor, {
      at: [],
      match: n => n.type === ELEMENT_GENERATE_TEXT_MARKER
    });

    // Add back only the children
    // Note: This is required to allow them being editable, draggable, etc.
    insertNodes(editor, nodeChildren);
  };

  const onUpdateText = (chunk: string) => {
    const { selection } = editor;

    // Means the document is not focused
    if (!selection) {
      insertText(editor, chunk);
      return;
    }

    textBufferRef.current += chunk;

    // To allow removing the nodes easy, we're wrapping all in a parent node
    const partialMarkdownAsEditorNode = {
      type: ELEMENT_GENERATE_TEXT_MARKER,
      children: markdownToSlate(textBufferRef.current)
    };

    // Nothing to add yet
    if (partialMarkdownAsEditorNode.children.length === 0) {
      return;
    }

    // Node: find & replaceNode doesn't work, because for some reason the second findNode() call si undefined again
    //       resulting in the node is added twice.
    removeNodes(editor, {
      at: [],
      match: n => n.type === ELEMENT_GENERATE_TEXT_MARKER
    });

    insertNodes(editor, partialMarkdownAsEditorNode);
  };

  const actionQueue = useRef<
    Array<
      | { action: 'start'; event: StartEvent }
      | { action: 'update'; event: UpdateEvent }
      | { action: 'end'; event: EndEvent }
    >
  >([]);

  /**
   * If we won't abort the streaming it will keep writing to a non existing editor.
   * When I last checked it did not caused any errors but still it would be a smell.
   * We also have to close the progress toast and aborting the stream takes care of
   * that.
   */
  useEffect(() => {
    return () => {
      // Cancel request
      const controller = abortControllerRef.current;
      if (controller) {
        controller.abort();
        abortControllerRef.current = undefined;
      }

      // Clear action queue
      actionQueue.current = [];
    };
  }, [currentTabId]);

  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();
  // Time in ms between processing actions
  const tickInterval = 10;

  const animate: FrameRequestCallback = time => {
    const deltaTime = previousTimeRef.current
      ? // Calculate elapsed time since last frame
        time - previousTimeRef.current
      : // First frame, ensure it's processed
        tickInterval + 1;

    // Only process actions if enough time has passed
    if (deltaTime > tickInterval && actionQueue.current.length > 0) {
      const actionItem = actionQueue.current.shift();
      if (actionItem?.action === 'start') {
        onStreamStart();
      } else if (actionItem?.action === 'update') {
        onUpdateText(actionItem.event.text_delta);
      } else if (actionItem?.action === 'end') {
        onStreamEnd();
        // Stop the animation loop after end event
        if (requestRef.current) {
          cancelAnimationFrame(requestRef.current);
          requestRef.current = undefined;
        }
        return;
      }

      // Reset the time after processing an action
      previousTimeRef.current = time;
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  return useMutation({
    mutationFn: async (props: GeneratorProps) => {
      // Starting another generation if one is already active may lead to unexpected behavior
      if (textGenerationActive) {
        return;
      }

      // Clean up any existing animation frame
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
        requestRef.current = undefined;
      }

      // Reset refs
      previousTimeRef.current = undefined;
      actionQueue.current = [];

      // Start new animation frame loop
      requestRef.current = requestAnimationFrame(animate);

      const { text, keywords } = props;

      const abortController = new AbortController();
      const abortSignal = abortController.signal;
      let activeTextItemId: string | undefined = undefined;
      abortControllerRef.current = abortController;

      dispatch(setGeneratingTextInEditor({ tabId: currentTabId, active: true }));

      const progressToast = createProgressToast(toasts.loading);

      const informationIds = tab.generateTextConfig.informationList?.map(info => info.id);

      try {
        await httpGenerateTextStream.callStreamEndpoint({
          request: {
            text,
            keywords,
            audience_model_id: audienceModelId,
            output_type: outputType,
            n_text_items: 1,
            n_times: 1,
            personality_id: tab.generateTextConfig.personalityId ?? undefined,
            information_ids: informationIds
          },
          handlers: {
            onStart: e => {
              if (activeTextItemId) {
                return;
              }

              activeTextItemId = e.text_item_id;
              actionQueue.current.push({ action: 'start', event: e });
            },
            onUpdate: e => {
              if (activeTextItemId !== e.text_item_id) {
                return;
              }

              actionQueue.current.push({ action: 'update', event: e });
            },
            onEnd: e => {
              actionQueue.current.push({ action: 'end', event: e });
            }
          },
          abort: abortSignal
        });

        if (abortSignal.aborted) {
          progressToast.close();
        } else {
          invalidateWordsUsageQueries();
          invalidateCustomerAllLimitationsQueries();

          progressToast.success({
            render: toasts.success
          });
        }
      } catch (e: unknown) {
        // eslint-disable-next-line no-console
        console.error(e);

        if (e instanceof StreamError) {
          await editor?.undo();
          dispatch(updateCurrentProjectInBackgroundThunk());
          progressToast.error({
            render: (
              <FormattedMessage id="aiWriter.textGeneration.error.ERROR_CONVERSATION_STREAMING_FAILED" />
            )
          });
          return;
        }
        progressToast.error({
          render: toasts.error
        });

        if (e instanceof ResponseError) {
          const data: { message?: string } = await e.response.json().catch(() => ({}));
          if (data.message === 'ERROR_ALL_WORDS_USED') {
            dispatch(setWordsLimitReached(true));
            return;
          }
          if (data.message === 'ERROR_INVALID_STREAMING_TYPE') {
            Toast.error(`aiWriter.textGeneration.error.${data.message}`);
            return;
          }
          if (data.message === 'ERROR_RESPONSE_NOT_SUCCESS') {
            Toast.error(`aiWriter.textGeneration.error.${data.message}`);
            return;
          }
          if (data.message === 'ERROR_STREAMING') {
            Toast.error(`aiWriter.textGeneration.error.${data.message}`);
            return;
          }
          if (data.message) {
            Toast.backendError(...handleGenerateTextErrorsWithHyperlink(data.message));
          }
        }
      } finally {
        abortControllerRef.current = undefined;
        dispatch(setGeneratingTextInEditor({ tabId: currentTabId, active: false }));
      }
    }
  });
}
