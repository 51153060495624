enum LocalStorageKey {
  AiWriterCommandTemplatesHint = 'aiWriterCommandTemplatesHint',
  AiWriterEditorStatisticPreference = 'aiWriterEditorStatisticPreference',
  AiWriterModel = 'aiWriterPreviousModel',
  AiWriterProject = 'aiWriterPreviousProject',
  APIToken = 'apiToken',
  IsApiCustomer = 'isApiCustomer',
  AppLanguage = 'language',
  BrandEquityColors = 'brandColors',
  BrandEquityModel = 'brandEquityModel',
  CustomerPicture = 'image',
  CustomerTimezone = 'userTimezone',
  CustomerUsername = 'userName',
  CustomerIsFirstLogin = 'isFirstLogin',
  DimensionDropdownOwner = 'goalDimensionsBelongsTo',
  DimensionsTableOwner = 'dimensionsBelongsTo',
  ExplorerGuideShown = 'exploringGuideShown',
  ExplorerModel = 'currentModel',
  ExplorerModelMapper = 'explorerModelMapper',
  ExplorerVerticalLayout = 'verticalLayout',
  HideInternalFeatures = 'hideInternalFeatures',
  HomeTeamView = 'teamView',
  LastVisitedPage = 'lastVisitedPage',
  TesterColors = 'testingColors',
  TesterGuideShown = 'testingGuideShown',
  TesterMapper = 'testerPreviousMapper',
  TesterModel = 'testerPreviousModel',
  TesterAudience = 'testerPreviousAudience',
  TextToImageCategory = 'textToImageCategory',
  TextToImageCountry = 'textToImageCountry',
  ToolbarCountry = 'addWordsCountry',
  ToolbarLocked = 'toolbarLocked',
  IsFirstAiImagesEntry = 'isFirstAiImagesEntry',
  OauthCsrfState = 'oauthCsrfState',
  BrowserExtensionHintSeen = 'browserExtensionHintSeen',
  UserTonalities = 'userTonalities',
  ImagesUpgradeModalSeen = 'imagesUpgradeModalSeen',
  DefaultGptModel = 'defaultGptModel1',
  IsSocialRegisterRoute = 'isSocialRegisterRoute',
  AiWriterSidebarChatClicked = 'aiWriterSidebarChatClicked',
  AiWriterEditorPersistBackgroundColor = 'aiWriterEditorPersistBackgroundColor',
  AiWriterEditorLanguageToolsIndicatorSeen = 'aiWriterEditorLanguageToolsIndicatorSeen',
  AiWriterBrandVoiceNotificationSeen = 'aiWriterBrandVoiceNotificationSeen',
  AiWriterTagsBannerShown = 'AiWriterTagsBannerShown',
  AiWriterFlashScoreOneTimeInfoSeen = 'aiWriterFlashScoreOneTimeInfoSeenV2',
  MobileWarningModalShow = 'mobileWarningModalShow',
  ShouldShowUserIsNowOnFree = 'should_show_user_is_now_on_free-'
}

export default LocalStorageKey;
