import FlexContainer from 'components/FlexContainer';
import { PricingUpgradeButton } from 'features/pricing/PricingUpgradeButton';
import { UsageIndicator } from 'features/usageIndicator/UsageIndicator';
import { UsageIndicatorType } from 'features/usageIndicator/UsageIndicatorType';
import styled from 'styled-components';

export const TemplateHeader = () => (
  <FlexContainer direction="row" justifyContent="flex-end" alignItems="flex-end" gap="three">
    <UsageBox>
      <UsageIndicator type={UsageIndicatorType.aiImages} hasAccess={true} />
    </UsageBox>
    <PricingUpgradeButton size="medium" />
  </FlexContainer>
);

const UsageBox = styled.div`
  width: 100%;
  max-width: 144px;
`;
