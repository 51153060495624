import { useMutation } from '@tanstack/react-query';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { designTemplatesServiceClient } from 'services/backofficeIntegration/http/client/createDesignTemplatesServiceClient';

type MutationOptions = {
  layers: LayerConfiguration;
  templateId: string;
};

type Image = {
  id: number;
  imageUrl: string;
};

// No types in swagger
type ApiCustomPayloadDto = {
  layers: LayerConfiguration;
};

export const useGenerateTemplateImage = () => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  return useMutation({
    mutationFn: async (options: MutationOptions) => {
      const response = await designTemplatesServiceClient.post<Image>(
        `/workspaces/${workspaceId}/placid-templates/${options.templateId}/images`,
        {
          layers: options.layers
        } as ApiCustomPayloadDto
      );
      return response.data;
    }
  });
};
