import { useQuery } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { mapBackofficePricingPlan } from 'features/customer/store/utils';
import { isTypeBusinessPlan, PricingPlan } from 'features/pricing/types';
import { ACLPricing, SubscriptionPeriod } from 'services/api/customer/types';
import PricingAPI, { PricingContextParams } from 'services/api/pricing';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';

type Options = {
  period?: SubscriptionPeriod;
  context?: PricingContextParams['context'];
};

export const useAvailablePlans = ({ period, context }: Options) =>
  useQuery({
    queryKey: [...makeCurrentUserQueryKeyPrefix(), 'pricings'],
    queryFn: async () => {
      const response = await PricingAPI.getPricings({ context });
      if (!response.status) {
        throw new Error(handleCommonApiError(response.data.message));
      }

      return response.data;
    },
    onError: err => {
      if (err instanceof Error) {
        Toast.backendError(err.message);
      }
    },
    select: data => {
      const allPlans = data
        // Anything else is for testing or some other special events and not open for subscription
        .filter(pricing => pricing.type === 'Enterprise' || pricing.allow_registration === 1)
        .map<ACLPricing>(mapBackofficePricingPlan);

      const plansForPeriod = !period
        ? allPlans
        : allPlans.filter(plan => plan.periodName === period);

      const freePlan = plansForPeriod.find(isFreePlan);
      const litePlan = plansForPeriod.find(isLitePlan);
      const basicPlan = plansForPeriod.find(isBasicPlan);
      const powerPlans = plansForPeriod.filter(isPowerVariant).sort(orderByCost);
      const premiumPlan = plansForPeriod.find(isPremiumPlan);
      const standardPlan = plansForPeriod.find(isStandardPlan);
      const starterPlan = plansForPeriod.find(isStarterPlan);

      const proPlan = plansForPeriod.find(isProPlan);
      const businessPlan = plansForPeriod.find(isBusinessPlan);

      const enterprisePlan = plansForPeriod.find(isEnterprisePlan);

      const businessPlanMonthlyCost = allPlans
        .filter(isBusinessPlan)
        .find(plan => plan.periodName === 'month')?.cost;

      return {
        allPlans,
        filteredPlans: plansForPeriod,
        freePlan,
        litePlan,
        basicPlan,
        powerPlans,
        premiumPlan,
        standardPlan,
        starterPlan,
        proPlan,
        enterprisePlan,
        businessPlan,
        businessPlanMonthlyCost
      };
    }
  });

function isPowerVariant(plan: ACLPricing): boolean {
  return (
    plan.type === PricingPlan.power ||
    plan.type === PricingPlan.powerLower ||
    plan.type === PricingPlan.powerHigher
  );
}

const isFreePlan = (plan: ACLPricing) => plan.type === PricingPlan.free;
const isLitePlan = (plan: ACLPricing) => plan.type === PricingPlan.lite;
const isBasicPlan = (plan: ACLPricing) => plan.type === PricingPlan.basic;
const isPremiumPlan = (plan: ACLPricing) => plan.type === PricingPlan.premium;
const isStandardPlan = (plan: ACLPricing) => plan.type === PricingPlan.standard;
const isStarterPlan = (plan: ACLPricing) => plan.type === PricingPlan.starter;
// Note: We previously had a "Business" plan, which had the type "pro",
//       therefore exclude it from the pro plans if the name is "Business"
const isProPlan = (plan: ACLPricing) => plan.type === PricingPlan.pro && plan.name !== 'Business';
const isBusinessPlan = isTypeBusinessPlan;
const isEnterprisePlan = (plan: ACLPricing) => plan.type === PricingPlan.enterprise;

const orderByCost = (planA: ACLPricing, planB: ACLPricing) =>
  Number(planA.cost) - Number(planB.cost);
