import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import { withApiToken } from 'services/backofficeIntegration/http/client/withApiToken';
import { withAuthenticationRedirect } from 'services/backofficeIntegration/http/client/withAuthenticationRedirect';
import { withRequestIdSupport } from 'services/backofficeIntegration/http/client/withRequestIdSupport';
import { getApiBaseUrl } from 'services/backofficeIntegration/http/getApiBaseUrl';

export function createApiClient() {
  const client = axios.create({
    baseURL: getApiBaseUrl()
  });

  axiosBetterStacktrace(client);

  withApiToken(client);
  withAuthenticationRedirect(client);
  withRequestIdSupport(client);

  return client;
}

export const apiClient = createApiClient();
