import { brandVoiceServiceClient } from 'services/backofficeIntegration/http/client/createBrandVoiceServiceClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import {
  ModelsBrandUpdatable,
  ModelsGormMap
} from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoiceFromUrl';

export type CreateBrandVoiceParams = {
  workspaceId: string;
  brandVoice: ModelsBrandVoiceCreatable;
};

type SuccessDto = ModelsBrandVoice;

export const httpCreateBrandVoice = mutationEndpoint({
  callEndpoint: ({ workspaceId, brandVoice }: CreateBrandVoiceParams) =>
    brandVoiceServiceClient
      .post<SuccessDto>(`/workspaces/${workspaceId}/brand-voices`, brandVoice)
      .then(response => response.data)
});

type ModelsBrandVoiceCreatable = {
  abbreviationRules?: string;
  active?: boolean;
  activeVoice?: boolean;
  advancedRules?: Array<string>;
  /**
   * TODO: remove this field. Read can be done via embedded and write should be done via the brand endpoints
   */
  brand?: ModelsBrandUpdatable;
  contentExamples?: Array<ModelsContentExample>;
  country: string;
  creationSourceDocumentID?: string;
  creationSourceType: 'manual' | 'document' | 'url';
  creationSourceURL?: string;
  currencyFormat?: string;
  dateFormat?: string;
  description?: string;
  donts?: string;
  emojiUsage?: string;
  gendering?: Array<string>;
  generalTone?: ModelsGormMap;
  glossaries?: Array<ModelsGlossary>;
  headlinePunctuation?: Array<string>;
  hyphenationRules?: Array<string>;
  industryJargon?: string;
  language: string;
  name: string;
  numberFormat?: string;
  personal?: boolean;
  perspective?: Array<string>;
  phoneNumberFormat?: string;
  /**
   * base64 encoded image
   * TODO: remove this field and extract thumbnail into its own entity
   */
  thumbnailBase64?: string;
  timeFormat?: string;
  useControversialEmojis?: boolean;
  useFormal?: boolean;
  useHeaderPunctuation?: boolean;
  useHyphens?: boolean;
  vocabulary?: Array<string>;
};

type ModelsContentExample = {
  content?: string;
  source?: 'website' | 'instagram' | 'facebook' | 'linkedin' | 'blog' | 'newsletter' | 'other';
  url?: string;
};

type ModelsGlossary = {
  extended?: string;
  shortcut?: string;
};

export type ModelsBrandVoice = {
  /**
   * context information depending on the customer that made the request
   */
  _embedded?: ModelsBrandVoiceEmbedded;
  abbreviationRules?: string;
  active?: boolean;
  activeVoice?: boolean;
  advancedRules?: Array<string>;
  /**
   * TODO: remove this field. Read can be done via embedded and write should be done via the brand endpoints
   */
  brand?: ModelsBrandUpdatable;
  /**
   * TODO: is this coherent field unused? can it be removed? If not how to fill from DS?
   */
  coherent?: boolean;
  completenessRating?: number;
  contentExamples?: Array<ModelsContentExample>;
  country: string;
  createdAt?: string;
  /**
   * not set for workspaces imported from legacy team
   */
  createdByUserId?: string;
  creationSourceDocumentID?: string;
  creationSourceType: 'manual' | 'document' | 'url';
  creationSourceURL?: string;
  currencyFormat?: string;
  dateFormat?: string;
  description?: string;
  donts?: string;
  emojiUsage?: string;
  gendering?: Array<string>;
  generalTone?: ModelsGormMap;
  glossaries?: Array<ModelsGlossary>;
  headlinePunctuation?: Array<string>;
  hyphenationRules?: Array<string>;
  id?: string;
  industryJargon?: string;
  language: string;
  /**
   * while this flag is true, the brand voice cannot yet be edited and it needs to be converted into a non-legacy brand voice first
   * -> this conversion should also update the brand to be non-legacy
   */
  legacy?: boolean;
  name: string;
  numberFormat?: string;
  personal?: boolean;
  perspective?: Array<string>;
  phoneNumberFormat?: string;
  /**
   * base64 encoded image
   * TODO: remove this field and extract thumbnail into its own entity
   */
  thumbnailBase64?: string;
  thumbnailUrl?: string;
  timeFormat?: string;
  updatedAt?: string;
  useControversialEmojis?: boolean;
  useFormal?: boolean;
  useHeaderPunctuation?: boolean;
  useHyphens?: boolean;
  vocabulary?: Array<string>;
  workspaceId?: string;
};

type ModelsBrandVoiceEmbedded = {
  brand?: ModelsBrand;
  permissions?: ModelsPermission;
};

type ModelsBrand = {
  address?: string;
  createdAt?: string;
  /**
   * not set for workspaces imported from legacy team
   */
  createdByUserId?: string;
  email?: string;
  facebookLink?: string;
  id?: string;
  instagramLink?: string;
  /**
   * while this flag is true, the brand cannot yet be edited and it needs to be converted into a non-legacy brand voice first
   */
  legacy?: boolean;
  linkedinLink?: string;
  name: string;
  ownerLegacyCustomerId?: number;
  phones?: Array<string>;
  updatedAt?: string;
  values?: Array<string>;
  website?: string;
};

type ModelsPermission = {
  canDelete?: boolean;
  canUpdate?: boolean;
};
