import { brandVoiceServiceClient } from 'services/backofficeIntegration/http/client/createBrandVoiceServiceClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type httpPostBrandVoiceDefaultParams = {
  workspaceId: string;
  customerId: number;
  brandVoiceId: string;
};

export const httpPostBrandVoiceDefault = mutationEndpoint({
  callEndpoint: ({ workspaceId, customerId, brandVoiceId }: httpPostBrandVoiceDefaultParams) =>
    brandVoiceServiceClient
      .post(`/workspaces/${workspaceId}/customers/${customerId}/default-brand-voices`, {
        brandVoiceId
      })
      .then(response => response.data)
});
