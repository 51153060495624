import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { queryEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';
import { safeEnum } from 'utils/safeEnum';

const NotificationType = safeEnum.make({
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',

  // Special types
  teamMemberNeedRefresh: 'team_member_need_refresh',
  customerSubscriptionPlanChanged: 'customer_subscription_plan_changed',
  customerSubscriptionEndedAfterCancellation: 'customer_subscription_ended_after_cancellation'
} as const);

type NotificationType = safeEnum.infer<typeof NotificationType>;

export { NotificationType };

export type NotificationDto = {
  id: string;
  data: {
    title?: string;
    content: string;
    type: NotificationType;
  };
  read_at: null;
};

export type NotificationListDto = NotificationDto[];

type SuccessDto = BaseSuccessDto<NotificationListDto>;

export const getUnreadNotifications = queryEndpoint({
  makeQueryKey: () => {
    return [...makeCurrentUserQueryKeyPrefix(), 'notifications', 'unread'] as const;
  },
  callEndpoint: () => {
    return backofficeClient
      .get<SuccessDto>('/notifications?status=unread')
      .then(response => response.data.data);
  }
});
