import { useMutation } from '@tanstack/react-query';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { designTemplatesServiceClient } from 'services/backofficeIntegration/http/client/createDesignTemplatesServiceClient';

type MutationOptions = {
  title: string;
  width: number;
  height: number;
  templateId: string;
};

// No types in swagger
type ApiCustomPayloadDto = {
  title: string;
  width: number;
  height: number;
  from_template: string;
};

type PlacidToken = {
  templateId: string;
  writeToken: string;
};

export const useGetTemplateEditToken = () => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  return useMutation({
    mutationFn: async (options: MutationOptions) =>
      designTemplatesServiceClient
        .post<PlacidToken>(
          `/workspaces/${workspaceId}/placid-templates/${options.templateId}/edit-tokens`,
          {
            ...options,
            from_template: options.templateId
          } as ApiCustomPayloadDto
        )
        .then(response => response.data)
  });
};
