import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import { withApiToken } from 'services/backofficeIntegration/http/client/withApiToken';
import { withErrorLogging } from 'services/backofficeIntegration/http/client/withErrorLogging';
import { withRateLimitSupport } from 'services/backofficeIntegration/http/client/withRateLimitSupport';
import { getLoggingServiceApiUrl } from 'services/backofficeIntegration/http/getLoggingServiceApiUrl';

const createLoggingServiceClient = () => {
  const client = axios.create({
    baseURL: getLoggingServiceApiUrl()
  });

  axiosBetterStacktrace(client);

  withErrorLogging(client);
  withApiToken(client);
  withRateLimitSupport(client);

  return client;
};

export const loggingServiceClient = createLoggingServiceClient();
