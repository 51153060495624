import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, IconButton, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import ModalTitle from 'components/modals/ModalTitle';
import { CloseModal } from 'components/modals/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useNotificationCenterEntries } from 'features/notifications/useNotificationCenterEntries';
import { ReactNode } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';

const NoNotificationsContent = () => {
  return (
    <Box>
      <Typography variant="body2">
        <FormattedMessage id="layout.sidebar.actions.notifications.empty" />
      </Typography>
    </Box>
  );
};

type NotificationBoxProps = {
  title: ReactNode;
  subtitle: ReactNode;
  actionButtonConfirmText: ReactNode;
  actionButtonCancelText: ReactNode;
  gtmId?: string;
  onClick?: () => void;
  onClickAway: () => void;
  onActionButtonConfirm?: () => void;
  onActionButtonCancel?: () => void;
};

const NotificationBox = ({
  title,
  subtitle,
  actionButtonConfirmText,
  actionButtonCancelText,
  gtmId,
  onClick,
  onClickAway,
  onActionButtonConfirm,
  onActionButtonCancel
}: NotificationBoxProps) => {
  const handleClickAway = (e: React.MouseEvent) => {
    e.stopPropagation();

    onClickAway();
  };

  const handleActionButtonConfirm = (e: React.MouseEvent) => {
    e.stopPropagation();

    onActionButtonConfirm?.();
  };

  const handleActionButtonCancel = (e: React.MouseEvent) => {
    e.stopPropagation();

    onActionButtonCancel?.();
  };

  const titleSection = (
    <FlexContainer direction="row" justifyContent="space-between">
      <FlexContainer direction="row" gap="small" alignItems="center">
        <Typography variant="h6">{title} </Typography>
        <StyledOpenInNewIcon fontSize="small" />
      </FlexContainer>

      <IconButton
        {...withGtmInteraction(gtmIds.sidebar.notificationsModal.clickAway)}
        size="small"
        onClick={handleClickAway}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <NotificationDot />
    </FlexContainer>
  );

  return (
    <Box onClick={onClick} {...withGtmInteraction(gtmId)}>
      <Block target="_blank">
        <FlexContainer gap="small">
          {titleSection}
          <Typography variant="body1">{subtitle}</Typography>
          {onActionButtonConfirm && (
            <FlexContainer gap="small" direction="row" justifyContent="flex-end">
              <Button variant="contained" onClick={handleActionButtonConfirm} size="small">
                {actionButtonConfirmText}
              </Button>

              <Button variant="outlined" onClick={handleActionButtonCancel} size="small">
                {actionButtonCancelText}
              </Button>
            </FlexContainer>
          )}
        </FlexContainer>
      </Block>
    </Box>
  );
};

type Props = CloseModal;

export const NotificationsModal = ({ closeModal }: Props) => {
  const notifications = useNotificationCenterEntries();

  const title = (
    <Typography variant="h5">
      <FormattedMessage id="layout.sidebar.actions.notifications" />
    </Typography>
  );

  return (
    <Root>
      <ModalTitle title={title} onCancel={closeModal} />

      {notifications.length === 0 && <NoNotificationsContent />}

      {notifications.map(notification => (
        <NotificationBox
          key={notification.type}
          gtmId={notification.gtmId}
          title={
            <FormattedMessage
              id={`layout.sidebar.actions.notifications.${notification.type}.title`}
              values={notification.titleTranslationValues}
            />
          }
          subtitle={
            <FormattedMessage
              id={`layout.sidebar.actions.notifications.${notification.type}.subtitle`}
              values={notification.subtitleTranslationValues}
            />
          }
          onClick={notification?.onClick}
          onClickAway={notification.onRemove}
          actionButtonConfirmText={
            notification?.onActionButtonConfirm && (
              <FormattedMessage
                id={`layout.sidebar.actions.notifications.${notification.type}.action_button.confirm`}
              />
            )
          }
          actionButtonCancelText={
            notification?.onActionButtonCancel && (
              <FormattedMessage
                id={`layout.sidebar.actions.notifications.${notification.type}.action_button.cancel`}
              />
            )
          }
          onActionButtonConfirm={notification?.onActionButtonConfirm}
          onActionButtonCancel={notification?.onActionButtonCancel}
        />
      ))}
    </Root>
  );
};

const Root = styled(FlexContainer).attrs({ gap: 'medium' })`
  padding: ${({ theme }) => theme.spacings.xmedium};
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const Block = styled.a`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  outline: 0;

  padding: ${({ theme }) =>
    `${theme.spacings.small} ${theme.spacings.medium} ${theme.spacings.small} ${theme.spacings.xmedium}`};
  border-radius: 4px;
  transition: background-color 0.1s linear;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.actionHover};
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: pointer;

    ${StyledOpenInNewIcon} {
      opacity: 1;
    }
  }
`;

const NotificationDot = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary};

  width: 0.5rem;
  height: 0.5rem;

  border-radius: 100%;

  top: 20px;
  left: 10px;
`;
