import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  title: string | ReactNode;
  subtitle: string | ReactNode;
  image?: ReactNode;
};

export const StepPageHero = ({ title, subtitle, image }: Props) => (
  <StyledStack spacing={1}>
    {image}
    <div>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {subtitle}
      </Typography>
    </div>
  </StyledStack>
);

const StyledStack = styled(Stack)`
  max-width: 432px;
`;
