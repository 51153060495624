import { Button, Typography } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import { ColoredTypography } from 'components/ColoredTypography';
import AlertError from 'components/forms/AlertError';
import { CloseModal } from 'components/modals/types';
import { PasswordInput } from 'components/profile/modals/editProfile/PasswordInput';
import Toast from 'components/toasts/Toast';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { Form, Formik } from 'formik';
import { useMemo, useState } from 'react';
import { apiClient } from 'services/backofficeIntegration/http/client/createApiClient';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { passwordRules } from 'utils/validationRules';
import { object, ref, string } from 'yup';

type FormValues = {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
};

type Props = CloseModal;

const isError = (error: unknown): error is Error =>
  error instanceof Error || Object.prototype.toString.call(error) === '[object Error]';

type AxiosErrorTypes = AxiosResponse | AxiosError | Error | string | null;

const AxiosErrorResponseHandler = (response: AxiosErrorTypes) => {
  if (!response) {
    return null;
  }

  if (typeof response === 'string') {
    const message = `Unknown error - ${response}`
    return <AlertError>{message}</AlertError>;
  }

  if ('response' in response && response.response) {
    response = response.response;
  }
  if ('data' in response) {
    if ('translationKey' in response.data && response.data.translationKey) {
      return (
        <AlertError>
          <FormattedMessage id={response.data.translationKey} />
        </AlertError>
      );
    } else if ('error' in response.data && response.data.error) {
      return <AlertError>{response.data.error}</AlertError>;
    }
  }

  const message = `Unknown error - ${response}`
  return <AlertError>{message}</AlertError>;
};

const ChangePasswordModal = ({ closeModal }: Props) => {
  const translate = useTr();

  const validationSchema = useMemo(
    () =>
      object().shape({
        password: passwordRules(translate),
        passwordConfirm: string()
            .required('validation.field_required')
            .oneOf([ref('password')], translate('profile.passwords_dont_match')),
      }),
    [translate]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null as AxiosErrorTypes);

  return (
    <SpinnerDimmer active={isLoading} loader>
      <Root>
        <ColoredTypography variant="h6">
          <FormattedMessage id="profile.edit_profile" />
        </ColoredTypography>

        {AxiosErrorResponseHandler(error)}

        <Formik
          initialValues={{
            currentPassword: '',
            password: '',
            passwordConfirm: ''
          }}
          validationSchema={validationSchema}
          onSubmit={values => updateAndCloseModal(values)}
        >
          {() => (
            <FormContainer>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="profile.current_password" />
                </Typography>
                <PasswordInput name="currentPassword" />
              </div>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="profile.new_password" />
                </Typography>
                <PasswordInput name="password" />
              </div>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="profile.confirm_password" />
                </Typography>
                <PasswordInput name="passwordConfirm" />
              </div>
              <ButtonContainer>
                <Button size="large" onClick={closeModal}>
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button size="large" type="submit" variant="contained" color="primary">
                  <FormattedMessage id="common.confirm" />
                </Button>
              </ButtonContainer>
            </FormContainer>
          )}
        </Formik>
      </Root>
    </SpinnerDimmer>
  );

  async function updateAndCloseModal(
    values: FormValues
  ): Promise<void> {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const response = await apiClient.post('/auth-service/v1/current-user/password', values) as AxiosResponse;
      if (response.status === 200 || response.status === 201) {
        Toast.success('profile.success');
        closeModal();
      } else {
        setError(response);
      }
    } catch(e) {
      if (isError(e)) {
        setError(e);
      } else {
        setError(String(e));
      }
    }
    setIsLoading(false);
  }
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacings.four};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.two};

  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.two};
`;

export default ChangePasswordModal;
