import { BrandHubV2IframeWrapper } from 'features/brand-hub-v2/BrandHubV2IframeWrapper';
import { useIframeMessageListener } from 'features/brand-hub-v2/useIframeMessageListener';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import styled from 'styled-components';

export function BrandHubV2Page() {
  useIframeMessageListener();
  useEnableSidebarOnMount();

  return (
    <StyledContainer>
      <BrandHubV2IframeWrapper />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;
