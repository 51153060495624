import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { useField } from 'formik';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { onboardingMobileThreshold } from './OnboardingRoot';

type Props = {
    name: string;
    label?: ReactNode;
} & CheckboxProps;

export const OnboardingNewsletterCheckbox = ({ name, label, color = 'primary', ...rest }: Props) => {
    const [field] = useField({ name, type: 'checkbox' });

    return (
        <StyledFormControl
            control={
                <CustomCheckbox
                    {...rest}
                    {...field}
                    checked={field.value}
                    color={color}
                    size="small"
                />
            }
            label={label}
        />
    );
};

const CustomCheckbox = styled(Checkbox)`
  align-self: flex-start;
  padding-top: 2px;
`;

const StyledFormControl = styled(FormControlLabel)`
  padding-top: 24px;
  @media (max-width: ${onboardingMobileThreshold}) {
    padding-bottom: 74px;
  }
`;