import { Button, Typography } from '@mui/material';
import icon from 'assets/icons/apartment.svg';
import LocalStorageKey from 'config/localStorageKey';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { encodeSocialLoginState } from 'features/social/encodeSocialLoginState';
import { socialButtonStyles } from 'features/social/SocialButtonStyles';
import { SocialProviderComponentProps } from 'features/social/SocialRegisterOrLoginForm';
import { useRef } from 'react';
import { authService } from 'services/auth/AuthService';
import { createUuidV4 } from 'utils/createUuidV4';
import { withTestId } from 'utils/utils';

type Props = SocialProviderComponentProps;

export function OidcLoginButton({ formName, newsletterSubscribed, isPaid, campaignUrl, preferredPricingId, freeTrial }: Props) {
  const stateRef = useRef(createUuidV4());

  if (!authService.isEnabled()) {
    return null;
  }

  const state = encodeSocialLoginState({
    uuid: stateRef.current,
    newsletterSubscribed,
    isPaid,
    campaignUrl,
    preferredPricingId,
    freeTrial
  });

  const messageId = formName === 'login' ? 'social.login.oidc' : 'social.signup.oidc';

  function onClick() {
    localStorage.setItem(LocalStorageKey.OauthCsrfState, state);
    authService.loginRedirect({state: state});
  }

  return (
    <Button
      css={socialButtonStyles}
      component="a"
      startIcon={<img src={icon} />}
      onClick={onClick}
      {...withTestId('oidc-login-button')}
    >
      <Typography variant="subtitle1">
        <FormattedMessage id={messageId} />
      </Typography>
    </Button>
  );
}
