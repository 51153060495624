import { encodeSocialLoginState } from 'features/social/encodeSocialLoginState';
import { useTeamInvitation } from 'features/teamInvitation/useTeamInvitation';
import { JwtLoginSnippet } from 'pages/login/LoginPage';
import { TeamInvitationExistingCustomerLandingpage } from 'pages/teamInvitationExistingCustomerLandingpage';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { authService } from 'services/auth/AuthService';
import { useAppSelector } from 'store/hooks';

export const TeamInvitationNewCustomerLandingpage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = useAppSelector(state => state.customer.email);

  const { emailParam } = useTeamInvitation();

  // Ensure user is logged in (old approach)
  useEffect(() => {
    if (!userEmail && !(authService.isEnabled() && authService.isGlobal())) {
      navigate('/login');
    }
  }, [userEmail, navigate]);

  // Ensure user is logged in (new approach)
  if (!userEmail && authService.isEnabled() && authService.isGlobal()) {
    const state = encodeSocialLoginState({
      campaignUrl: location.pathname + location.search
    });

    return <JwtLoginSnippet state={state} prompt="create" login_hint={emailParam || undefined}></JwtLoginSnippet>;
  }

  return TeamInvitationExistingCustomerLandingpage();
};
