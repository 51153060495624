import { ReactComponent as HelpIcon } from 'assets/sidebar/icon-help.svg';
import { SidebarItem } from 'features/pageSidebar/sidebarItems/SidebarItem';
import React from 'react';
import gtmIds from 'services/tracking/GTMIds';
import useTr from 'utils/hooks/useTr';
export const SidebarItemHelpCenter = (): React.ReactElement => {
  const translate = useTr();

  const handleClick = () => {
    window.open(translate('sidebar.item.help.link'), '_blank');
  };
  return (
    <SidebarItem
      gtmId={gtmIds.sidebar.help}
      onClick={handleClick}
      icon={<HelpIcon />}
      name="layout.sidebar.actions.help"
      tooltip="layout.sidebar.actions.help"
      showHoverIcon
    />
  );
};
