import { useQuery } from '@tanstack/react-query';
import { makeCurrentUserQueryKeyPrefix } from 'features/currentUser/currentUserQueries';
import { getIsAuthenticated, getUserId } from 'features/customer/store/selectors';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { httpGetWorkspaceMembers } from 'services/backofficeIntegration/http/endpoints/workspace/httpGetWorkspaceMembers';
import { useAppSelector } from 'store/hooks';
import { isNotProd } from 'utils/isNotProd';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const useGetWorkspaceId = () => {
  const customerId = useAppSelector(getUserId);
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const newBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  return useQuery({
    enabled: isNotProd() && newBrandHubEnabled && isAuthenticated && !!customerId,
    queryKey: getWorkspaceIdQueryKey(),
    queryFn: async () => {
      assertNonNullable(customerId);

      // This is a list (a workspace member entity) for every workspace the user is a member of
      const myWorkspaceMembershipEntries = await httpGetWorkspaceMembers.callEndpoint({
        customerId
      });

      // If there's only one, use it
      if (myWorkspaceMembershipEntries.length === 1) {
        return myWorkspaceMembershipEntries[0].workspaceId;
      }

      // In case of multiple, use the first there the customer is NOT the owner
      return (
        myWorkspaceMembershipEntries.find(member => member.role !== 'OWNER')?.workspaceId || null
      );
    },
    // You've to clear it manually
    staleTime: Infinity
  });
};

const getWorkspaceIdQueryKey = () => [...makeCurrentUserQueryKeyPrefix(), 'workspaceId'] as const;
