import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { RecaptchaProvider } from 'components/RecaptchaProvider';
import { FreeRegistrationPanel } from 'components/register/FreeRegistrationPanel';
import { encodeSocialLoginState } from 'features/social/encodeSocialLoginState';
import { JwtLoginSnippet } from 'pages/login/LoginPage';
import { authService } from 'services/auth/AuthService';

export const FreeRegistrationPage = () => {
  useRedirectWhenAuthenticated();

  if (authService.isEnabled() && authService.isGlobal()) {
    const state = encodeSocialLoginState({
      isPaid: false,
    });

    return <JwtLoginSnippet state={state} prompt="create"></JwtLoginSnippet>;
  }

  return (
    <RecaptchaProvider>
      <FreeRegistrationPanel />
    </RecaptchaProvider>
  );
};
