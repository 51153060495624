import { CircularProgress, TextField } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { OnboardingStepper } from 'features/onboardingJourney/components/OnboardingStepper';
import { OnboardingFooter } from 'features/onboardingJourney/OnboardingFooter';
import { onboardingContentWidth } from 'features/onboardingJourney/OnboardingRoot';
import { useCreateBrandVoiceFromUrlMutation } from 'features/onboardingJourney/useCreateBrandVoiceFromUrlMutation';
import {
  GeneralTone,
  mapRecordToGeneralTone
} from 'features/onboardingJourney/utils/mapRecordToCharacteristics';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { CreateBrandVoiceFromUrlResponse } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoiceFromUrl';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import useTr from 'utils/hooks/useTr';
import { useValidateUrlAndUpdateState } from 'utils/hooks/useValidateUrlAndUpdateState';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export type OnboardingBrandVoiceTextExtractionLocationState = {
  brandVoiceName: string;
  brandName: string;
  website: string;
  generalTone: GeneralTone[];
  keywords: string[];
  description: string;
  rest: CreateBrandVoiceFromUrlResponse;
};

export const OnboardingBrandVoiceCreationNew = () => {
  const translate = useTr();
  const navigate = useNavigate();
  const { data: customerPreferences } = useCustomerPreferences();

  useDisableSidebarOnMount();

  const [text, setText] = useState('');

  const [debouncedText] = useDebounce(text, 1000);
  const [isUrlValid, setIsUrlValid] = useState(true);

  useValidateUrlAndUpdateState(debouncedText, setIsUrlValid);

  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const {
    mutateAsync: createBrandVoiceFromUrl,
    error,
    isLoading: isExtractingBrandVoiceTextLoading
  } = useCreateBrandVoiceFromUrlMutation();

  // We need an extra debouncedText.length === 0 check here because the URL validation
  // is also true for empty inputs, but we want to disable the button in that case
  const isFooterButtonDisabled =
    !isUrlValid || debouncedText.length === 0 || isExtractingBrandVoiceTextLoading;

  const language = customerPreferences?.preferredLanguage ?? fallbackModelLanguage;
  const country = customerPreferences?.preferredLanguageCountry ?? fallbackModelCountry;
  const audiences = useAppSelector(getUserAudiences);
  const audience = getPreferredAudience({ audiences, locale: { language, country } });

  const handleSkipClick = () => {
    GAEvents.onboardingPersonalitySkipClick();

    navigate(getRoutePath('home'), { state: { skippedPersonalityCreation: true } });
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleAnalysisClick = async () => {
    if (!audience || !audience.model_id || !audience.language || !audience.country) {
      throw new Error(`Audience (${JSON.stringify(audience || {})}) not found`);
    }

    GAEvents.onboardingPersonalityAnalysisClick();

    assertNonNullable(workspaceId, 'workspaceId is null');

    const creationResult = await createBrandVoiceFromUrl({
      url: text,
      audienceModelId: audience.model_id,
      country: audience.country,
      language: audience.language,
      workspaceId
    });

    if (!creationResult && error && error?.message) {
      if (error?.message === 'ERROR_BRAND_VOICE_SCRAPE') {
        Toast.backendError(
          'personality.creation_modal.analysisView.share_url.ERROR_BRAND_VOICE_SCRAPE'
        );
        return;
      }

      Toast.apiError();
      return;
    }

    const state: OnboardingBrandVoiceTextExtractionLocationState = {
      brandVoiceName: creationResult.brandVoice?.name ?? '',
      brandName: creationResult.brand?.name ?? '',
      website: creationResult.brand?.website ?? '',
      generalTone: mapRecordToGeneralTone(creationResult.brandVoice?.generalTone ?? {}),
      keywords: creationResult.brandVoice?.vocabulary ?? [],
      description: creationResult.brandVoice?.description ?? '',
      rest: creationResult
    };

    navigate(getRoutePath('onboardingBrandVoiceFinished'), { state });
  };

  return (
    <OnboardingStepper currentStep={isExtractingBrandVoiceTextLoading ? 'analyzeing' : 'create'}>
      <InputContainer>
        <StyledTextField
          value={text}
          onChange={handleChangeText}
          fullWidth={true}
          error={!isUrlValid}
          helperText={
            <FormattedMessage
              id={
                isUrlValid
                  ? 'personality_creation.share_URL_view.input_help_text'
                  : 'personality_creation.share_URL_view.input_error'
              }
            />
          }
          placeholder={translate('personality_creation.share_URL_view.input_placeholder')}
          disabled={isExtractingBrandVoiceTextLoading}
        />
      </InputContainer>

      <OnboardingFooter
        enableMobileLayout={false}
        nextButtonProps={{
          onClick: handleAnalysisClick,
          disabled: isFooterButtonDisabled
        }}
        nextButtonChildren={
          isExtractingBrandVoiceTextLoading ? (
            <CircularProgress size={24} />
          ) : (
            <FormattedMessage id="onboarding.personality_creation.next.button" />
          )
        }
        skipButtonProps={
          isExtractingBrandVoiceTextLoading
            ? undefined
            : {
                onClick: handleSkipClick
              }
        }
      />
    </OnboardingStepper>
  );
};

const InputContainer = styled(FlexContainer).attrs({ justifyContent: 'center' })`
  max-width: ${onboardingContentWidth};
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  .Mui-disabled {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`;
