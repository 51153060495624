import { useMutation } from '@tanstack/react-query';
import Toast from 'components/toasts/Toast';
import { httpCreateBrandVoiceFromUrl } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoiceFromUrl';

export const useCreateBrandVoiceFromUrlMutation = () =>
  useMutation({
    mutationFn: httpCreateBrandVoiceFromUrl.callEndpoint,
    onError: (error: { message?: string }) => {
      if (error?.message === 'ERROR_BRAND_VOICE_SCRAPE') {
        Toast.backendError(
          'personality.creation_modal.analysisView.share_url.ERROR_BRAND_VOICE_SCRAPE'
        );
      }
    }
  });
