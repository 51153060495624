import { CircularProgress, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { OnboardingFooter } from 'features/onboardingJourney/OnboardingFooter';
import { onboardingContentWidth, OnboardingRoot } from 'features/onboardingJourney/OnboardingRoot';
import { useDisableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { httpExtractBrandVoiceTextFromUrl } from 'services/backofficeIntegration/http/endpoints/personalities/httpExtractBrandVoiceTextFromUrl';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import useTr from 'utils/hooks/useTr';
import { useValidateUrlAndUpdateState } from 'utils/hooks/useValidateUrlAndUpdateState';

export type OnboardingBrandVoiceTextExtractionLocationState = {
  extractedText: string;
  audienceLanguage: string;
  audienceCountry: string;
};

export const OnboardingBrandVoiceCreationOld = () => {
  const translate = useTr();
  const navigate = useNavigate();
  const { data: customerPreferences } = useCustomerPreferences();

  useDisableSidebarOnMount();

  const [text, setText] = useState('');

  const [debouncedText] = useDebounce(text, 1000);
  const [isUrlValid, setIsUrlValid] = useState(true);

  useValidateUrlAndUpdateState(debouncedText, setIsUrlValid);

  const {
    mutateAsync: extractBrandVoiceTextFromUrl,
    isLoading: isExtractingBrandVoiceTextLoading
  } = useMutation({
    mutationFn: httpExtractBrandVoiceTextFromUrl.callEndpoint
  });

  // We need an extra debouncedText.length === 0 check here because the URL validation
  // is also true for empty inputs, but we want to disable the button in that case
  const isFooterButtonDisabled =
    !isUrlValid || debouncedText.length === 0 || isExtractingBrandVoiceTextLoading;

  const language = customerPreferences?.preferredLanguage ?? fallbackModelLanguage;
  const country = customerPreferences?.preferredLanguageCountry ?? fallbackModelCountry;
  const audiences = useAppSelector(getUserAudiences);
  const audience = getPreferredAudience({ audiences, locale: { language, country } });

  const handleSkipClick = () => {
    GAEvents.onboardingPersonalitySkipClick();

    navigate(getRoutePath('home'), { state: { skippedPersonalityCreation: true } });
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleAnalysisClick = async () => {
    if (!audience || !audience.model_id || !audience.language || !audience.country) {
      throw new Error(`Audience (${JSON.stringify(audience || {})}) not found`);
    }

    GAEvents.onboardingPersonalityAnalysisClick();

    const creationResult = await extractBrandVoiceTextFromUrl({
      url: text
    });

    if (!creationResult || !creationResult.status) {
      if (creationResult?.message === 'ERROR_BRAND_VOICE_SCRAPE') {
        Toast.backendError(
          'personality.creation_modal.analysisView.share_url.ERROR_BRAND_VOICE_SCRAPE'
        );
        return;
      }

      Toast.apiError();
      return;
    }

    const state: OnboardingBrandVoiceTextExtractionLocationState = {
      extractedText: creationResult.data.personality_text,
      audienceLanguage: audience.language,
      audienceCountry: audience.country
    };

    navigate(getRoutePath('onboardingBrandVoiceFinished'), { state });
  };

  return (
    <OnboardingRoot
      title={translate('onboarding.personality_creation.title')}
      subtitle={translate('onboarding.personality_creation.subtitle')}
    >
      {isExtractingBrandVoiceTextLoading ? (
        <InputContainer>
          <LoadingAnimationWithAlternatingMessages />
        </InputContainer>
      ) : (
        <InputContainer>
          <TextField
            value={text}
            onChange={handleChangeText}
            fullWidth={true}
            error={!isUrlValid}
            helperText={
              <FormattedMessage
                id={
                  isUrlValid
                    ? 'personality_creation.share_URL_view.input_help_text'
                    : 'personality_creation.share_URL_view.input_error'
                }
              />
            }
            placeholder={translate('personality_creation.share_URL_view.input_placeholder')}
          />
        </InputContainer>
      )}

      <OnboardingFooter
        nextButtonProps={{
          onClick: handleAnalysisClick,
          disabled: isFooterButtonDisabled
        }}
        nextButtonChildren={
          isExtractingBrandVoiceTextLoading ? (
            <CircularProgress size={24} />
          ) : (
            <FormattedMessage id="onboarding.personality_creation.next.button" />
          )
        }
        skipButtonProps={
          isExtractingBrandVoiceTextLoading
            ? undefined
            : {
                onClick: handleSkipClick
              }
        }
      />
    </OnboardingRoot>
  );
};

const InputContainer = styled(FlexContainer).attrs({ justifyContent: 'center' })`
  max-width: ${onboardingContentWidth};
  width: 100%;

  // Simulate a larger content
  min-height: 300px;
`;
