import { ColoredTypography } from 'components/ColoredTypography';
import ConfirmModal from 'components/modals/ConfirmModal';
import { CloseModal } from 'components/modals/types';
import { sendModalResult } from 'features/brand-hub-v2/newAppPostMessage';
import { ModalBodyWithColumnDirectionAndSpaceBetween } from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

type Props = CloseModal & {
  brandVoiceId: string;
};

export const DeleteBrandVoiceModal = ({ closeModal, brandVoiceId }: Props) => {
  return (
    <ConfirmModal
      message="brand_hub.modal.delete_brand_voice_confirm.message"
      messageValues={{
        br: <br />
      }}
      cancelText="common.cancel"
      okText="common.delete"
      onCancel={() => {
        sendModalResult('BRAND_HUB_V2_DELETE_BRAND_VOICE_CONFIRM', {
          brandVoiceId,
          confirmed: false
        });
        closeModal();
      }}
      onOK={() => {
        sendModalResult('BRAND_HUB_V2_DELETE_BRAND_VOICE_CONFIRM', {
          brandVoiceId,
          confirmed: true
        });
        closeModal();
      }}
      slots={{
        bodyContainer: CustomModalBody
      }}
    >
      <ColoredTypography variant="h5">
        <FormattedMessage id="brand_hub.modal.delete_brand_voice_confirm.title" />
      </ColoredTypography>
    </ConfirmModal>
  );
};

const CustomModalBody = styled(ModalBodyWithColumnDirectionAndSpaceBetween)({
  paddingTop: 0
});
