import { Close } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery } from '@tanstack/react-query';
import FlexContainer from 'components/FlexContainer';
import { useModal } from 'components/modals';
import { CloseModal } from 'components/modals/types';
import { SearchControlled } from 'components/SearchControlled';
import Toast from 'components/toasts/Toast';
import { getRoutePath } from 'config/routes';
import { getCustomerRole } from 'features/customer/store/selectors';
import { CustomerRole } from 'features/customer/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { CategoryFilter } from 'features/information/apply-information/CategoryFilter';
import { InformationSelection } from 'features/information/apply-information/InformationSelection';
import { useShowInformationModal } from 'features/information/useShowInformationModal';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import {
  ModalBodyWithColumnDirection,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { BrandHubWarningBanner } from 'pages/brand-hub/warningBanner';
import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { InformationCategoryDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationCategories';
import { httpGetInformationLimitations } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationLimitations';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { httpValidateInformation } from 'services/backofficeIntegration/http/endpoints/infomration/httpValidateInformation';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce/lib';

export type CustomModalProps = {
  onApply: (informationList: InformationDto[]) => void;
  preselectedInformation?: InformationDto[];
  applyGtmId?: string;
};

type ModalProps = CloseModal & CustomModalProps;

export function ApplyInformationModal(props: ModalProps) {
  const { closeModal, onApply, preselectedInformation, applyGtmId } = props;

  const [information, setInformation] = useState<InformationDto[]>(preselectedInformation ?? []);
  const [error, setError] = useState<string | undefined>();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 700);

  const [filteredTag, setFilteredTag] = useState<InformationCategoryDto | undefined>();

  const customerRole = useAppSelector(getCustomerRole);
  const isNotOwner = customerRole !== CustomerRole.OWNER;

  const customerHasTeam = useAppSelector(state => state.customer.hasTeam);
  const isTeamMember = customerHasTeam && isNotOwner;

  const { mutate: validateInformation, isLoading } = useMutation({
    mutationFn: httpValidateInformation.callEndpoint,
    onSuccess: () => {
      closeModal();
      onApply(information);
    },
    onError: (error: unknown) => {
      const errorCode = getErrorCodeFromAxiosError(error);

      if (errorCode) {
        setError(`information.error.${errorCode}`);
        return;
      }

      Toast.apiError();
    }
  });


  const { data: limitationData, isSuccess: isLimitationQuerySuccess } = useQuery({
    queryKey: httpGetInformationLimitations.makeQueryKey(),
    queryFn: () => httpGetInformationLimitations.callEndpoint()
  });

  const isOneAvailableWarning = isLimitationQuerySuccess && limitationData.data.available === 1;
  const isOutOfCreditsWarning =
    isLimitationQuerySuccess &&
    !limitationData.data.isUnlimited &&
    limitationData.data.available === 0;

  function handleSearchChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(e.target.value);
  }

  function handleDeselectAllClick() {
    setInformation([]);
  }

  function handleApplyClick() {
    if (information.length === 0) {
      closeModal();
      onApply([]);
      return;
    }

    validateInformation(information.map(info => info.id));
  }

  const showInformationModal = useShowInformationModal();
  const showApplyInformationModal = useShowApplyInformationModal();

  function handleCreateInformationClick() {
    closeModal();
    showInformationModal({
      fromApplyInformationModal: true,
      modalProps: {
        onApply: (newInformationList) => {
          showApplyInformationModal({
            onApply,
            preselectedInformation: newInformationList,
            applyGtmId
          });
        },
        preselectedInformation,
        applyGtmId
      }
    });
  }

  return (
    <ModalWithDividedHeaderLayout
      title={<FormattedMessage id="common.information" />}
      helpLink="information.apply_modal.help_link"
      additionalHeaderActions={
        <Button component={Link} target="_blank" to={getRoutePath('brandHubOverviewInformation')}>
          <FormattedMessage id="information.apply_modal.add_new" />
        </Button>
      }
      slots={{
        bodyContainer: ModalBodyWithColumnDirection
      }}
      closeModal={closeModal}
    >
      <FlexContainer gap="two">
        <SearchControlled value={searchQuery} onChange={handleSearchChange} />
        <CategoryFilter
          value={filteredTag}
          setValue={setFilteredTag}
          selectedInformationList={information}
        />
      </FlexContainer>

      {error && (
        <WarningBox>
          <Typography variant="caption">
            <FormattedMessage id={error} />
          </Typography>
          <Close color="error" onClick={() => setError(undefined)} />
        </WarningBox>
      )}

      {isOutOfCreditsWarning && (
        <BrandHubWarningBanner
          text={
            isTeamMember
              ? 'flashHub.information.modal.limitations.out_of_credits.no_owner_message'
              : 'flashHub.information.modal.limitations.out_of_credits'
          }
          variant="orange"
          gtmUpgradeId="flashHub.information.upgrade"
          rounded={true}
        />
      )}
      {isOneAvailableWarning && (
        <BrandHubWarningBanner
          text={
            isTeamMember
              ? 'flashHub.information.modal.limitations.one_left.no_owner_message'
              : 'flashHub.information.modal.limitations.one_left'
          }
          variant="yellow"
          gtmUpgradeId="flashHub.information.upgrade"
          rounded={true}
        />
      )}

      <InformationSelection
        value={information}
        onChange={setInformation}
        searchQuery={debouncedSearchQuery}
        filteredTag={filteredTag}
      />

      <ActionBox>
        <Button
          onClick={handleDeselectAllClick}
          disabled={information.length === 0}
        >
          <FormattedMessage id="information.apply_modal.deselect_all" />
        </Button>
        <GapBox>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            disabled={isOutOfCreditsWarning}
            onClick={handleCreateInformationClick}
            {...withGtmInteraction(gtmIds.brandHub.information.attemptToCreateInformation)}
          >
            <FormattedMessage id="common.new" />
          </Button>
          <Button
            {...withGtmInteraction(applyGtmId)}
            variant="contained"
            onClick={handleApplyClick}
            disabled={isLoading}
          >
            <FormattedMessage id="information.apply_modal.apply" />
          </Button>
        </GapBox>
      </ActionBox>
    </ModalWithDividedHeaderLayout>
  );
}

const ActionBox = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};

  display: flex;
  justify-content: space-between;
`;

const GapBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
`;

const WarningBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: rgba(211, 47, 47, 0.08);
  color: ${({ theme }) => theme.colors.error};
  margin: ${({ theme }) => `${theme.spacings.medium} 0 ${theme.spacings.xmedium} 0`};
  & > svg {
    cursor: pointer;
  }
`;

export const useShowApplyInformationModal = () => {
  const { showModal } = useModal();

  return (props: CustomModalProps) => {
    showModal('APPLY_INFORMATION', { ...props, size: 600 });
  };
};
