import { Link } from '@mui/material';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { useUpdateCustomerPreferences } from 'features/customerPreferences/useUpdateCustomerPreferences';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { AppLanguage } from 'features/language/store/types';
import { ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import useTr from 'utils/hooks/useTr';

// currently product tour comes from navattic but we want to keep product tour name
export type NotificationCenterType =
  | 'browser_extension'
  | 'product_tour'
  | 'webinar'
  | 'languagetool'
  | 'academy';
// type of FormattedMessage values prop
type FormattedMessageValues = ComponentProps<typeof FormattedMessage>['values'];

export type NotificationCenterEntry = {
  type: NotificationCenterType;
  titleTranslationValues?: FormattedMessageValues;
  subtitleTranslationValues?: FormattedMessageValues;
  gtmId?: string;
  onClick?: () => void;
  onRemove: () => void;
  onActionButtonConfirm?: () => void;
  onActionButtonCancel?: () => void;
};

export const useNotificationCenterEntries = (): NotificationCenterEntry[] => {
  const tr = useTr();
  const { data: preferences } = useCustomerPreferences();
  const appLanguage = useAppSelector(getCurrentLanguage);

  const { mutate: updatePreferences } = useUpdateCustomerPreferences();

  const shouldSeeWebinarNotification =
    !preferences?.webinarSeen && appLanguage === AppLanguage.German;
  const shouldSeeLanguageToolNotification = !preferences?.languageToolSeen;
  const shouldSeeAcademyInvitation = appLanguage === AppLanguage.German;

  const webinarLink = tr('layout.sidebar.actions.notifications.webinar.link');
  const languageToolLink = tr('layout.sidebar.actions.notifications.languagetool.subtitle.link');
  const academyLink = tr('layout.sidebar.actions.notifications.academy.link');

  const notifications = new Array<NotificationCenterEntry>();

  if (shouldSeeWebinarNotification) {
    notifications.push({
      type: 'webinar',
      subtitleTranslationValues: {
        b: (text: string) => <strong>{text}</strong>,
        br: <br />
      },
      onClick: () => {
        updatePreferences({ webinarSeen: true });
        window.open(webinarLink, '_blank');
      },
      onRemove: () => {
        updatePreferences({ webinarSeen: true });
      }
    });
  }

  if (shouldSeeAcademyInvitation) {
    notifications.push({
      type: 'academy',
      subtitleTranslationValues: {
        b: (text: string) => <strong>{text}</strong>,
        br: <br />,
        a: (text: string) => (
          <Link href={academyLink} target="_blank">
            {text}
          </Link>
        )
      },
      gtmId: gtmIds.sidebar.notificationsModal.academy,
      onClick: () => {
        updatePreferences({ academySeen: true });
        window.open(academyLink, '_blank');
      },
      onRemove: () => {
        updatePreferences({ webinarSeen: true });
      }
    });
  }

  if (shouldSeeLanguageToolNotification) {
    notifications.push({
      type: 'languagetool',
      subtitleTranslationValues: {
        a: (text: string) => (
          <Link href={languageToolLink} target="_blank">
            {text}
          </Link>
        )
      },
      onRemove: () => {
        updatePreferences({ languageToolSeen: true });
      },
      onActionButtonConfirm() {
        updatePreferences({ languageToolEnabled: true });
        updatePreferences({ languageToolSeen: true });
      },
      onActionButtonCancel() {
        updatePreferences({ languageToolEnabled: false });
        updatePreferences({ languageToolSeen: true });
      }
    });
  }

  return notifications;
};
