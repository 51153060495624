import CategoryIcon from '@mui/icons-material/Category';
import UndoIcon from '@mui/icons-material/Undo';
import { LoadingButton } from '@mui/lab';
import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { ReactComponent as AiWriterIcon } from 'assets/icons/icon-ai-writer.svg';
import { ExtendedPromptInput } from 'components/ExtendedPromptInput';
import FlexContainer from 'components/FlexContainer';
import { CloseModal } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import {
  useStoredActiveConversationId,
  useStoredPersonalityId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { getPersonalityTemplateByLanguage } from 'features/aiWriter/AiWriterSidebar/steps/chat/getPersonalityTemplateByLanguage';
import {
  isValidPersonalityFormData,
  personalityDescriptionMaxLength,
  personalityLabelMaxLength
} from 'features/aiWriter/AiWriterSidebar/steps/chat/isValidPersonalityFormData';
import { PERSONALITY_DEFINITION_MAX_WORDS_LIMIT } from 'features/aiWriter/AiWriterSidebar/steps/chat/PersonalityAutomaticCreationView';
import { useCreatePersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useCreatePersonality';
import { invalidatePersonalitiesQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/usePersonalitiesQuery';
import { useUpdateChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateChatPersonality';
import { useModelTranslation } from 'features/aiWriter/hooks/useModelTranslation';
import { SharingPermission } from 'features/aiWriter/store/types';
import { EmbeddingModelSelect } from 'features/embeddingModels/EmbeddingModelSelect';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import {
  ContentToAnalyze,
  personalityCreationModalHeight,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import { useShowPersonalityLibraryModal } from 'features/personality/PersonalityLibraryModal';
import { usePromptOptimizerControlled } from 'features/textGenerator/promptOptimizer/usePromptOptimizerContorlled';
import { ModalWithDividedHeaderLayout } from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import { ChangeEvent, FormEvent, useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { getWordCount } from 'utils/getWordCount';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

const PERSONALITY_OPTIMIZER_OUTPUT_TYPE = 'user_personality_optimizer';

const fieldLabel = 'label';
const fieldDescription = 'description';
const fieldDefinition = 'definition';
const fieldSharingPermission = 'sharingPermission';
const embeddingModelField = 'embeddingModel';

type FormValues = {
  [fieldLabel]: string;
  [fieldDescription]: string;
  [fieldSharingPermission]: SharingPermission;
  [fieldDefinition]: string;
  [embeddingModelField]?: string;
};

type Props = {
  personality: PersonalityDto | null;
  automaticDefinedPersonality?: string;
  onCancelClick: () => void;
  onPersonalitySelect: (personality: PersonalityDto | null) => void;
  setView: (view: ViewMode) => void;
  defaultModel: EmbeddingModel;
  isLanguageEditable?: boolean;
  contentToAnalyze?: ContentToAnalyze;
  setContentToAnalyze: (value: ContentToAnalyze) => void;
  analyzedPersonality: string | undefined;
  closeOnBack?: boolean;
} & CloseModal;

export const sharePermissionOptions = [
  {
    value: SharingPermission.private,
    id: SharingPermission.private,
    name: <FormattedMessage id="common.sharing_permission.private" />
  },
  {
    value: SharingPermission.team,
    id: SharingPermission.team,
    name: <FormattedMessage id="common.sharing_permission.team" />
  }
];

export const PersonalityEditOrCreateView = ({
  personality,
  defaultModel,
  isLanguageEditable,
  analyzedPersonality,
  closeOnBack,
  onCancelClick,
  onPersonalitySelect,
  setView,
  closeModal
}: Props) => {
  const isEditing = !!personality;

  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const editedModel = models.find(
    model => model.language === personality?.language && model.country === personality?.country
  );

  const [model, setModel] = useState<EmbeddingModel>(editedModel ?? defaultModel);
  const language = model?.language;
  const country = model?.country;

  const { label: languageLabel } = useModelTranslation({ language, country });

  const completePersonalityTask = useCompleteTask(TASK_TYPE.CREATE_PERSONALITY);

  const [formValues, setFormValues] = useState<FormValues>({
    [fieldLabel]: personality?.label ?? '',
    [fieldDescription]: personality?.description ?? '',
    [fieldSharingPermission]: personality?.sharing_permission ?? sharePermissionOptions[0].value,
    [fieldDefinition]: personality?.definition ?? analyzedPersonality ?? ''
  });

  const { isUndoVisible, setInitialPrompt, optimizePromptMutation, undoPromptOptimization } =
    usePromptOptimizerControlled({
      prompt: formValues[fieldDefinition],
      setPrompt: (prompt: string) => setFormValues({ ...formValues, [fieldDefinition]: prompt }),
      locale: { language, country },
      initialPrompt: '',
      outputType: PERSONALITY_OPTIMIZER_OUTPUT_TYPE,
      callback: () =>
        trackingWrapper.track('personalityOptimizedPrompt', {
          text: formValues[fieldDefinition]
        })
    });

  const [personalityCreatedOrUpdated, setPersonalityCreatedOrUpdated] = useState(false);
  const currentPersonalityId = useStoredPersonalityId();

  const { mutate: updatePersonality, isLoading: updateInProgress } = useUpdateChatPersonality();
  const { mutate: createPersonality, isLoading: creationInProgress } = useCreatePersonality();

  const isCreating = personality === null;
  const requestIsRunning = creationInProgress || updateInProgress;

  const currentConversationId = useStoredActiveConversationId();

  const isValidFormData = isValidPersonalityFormData(formValues);
  const definitionWordCount = getWordCount(formValues[fieldDefinition]);
  const isDefinitionWordCountExceedLimit =
    definitionWordCount > PERSONALITY_DEFINITION_MAX_WORDS_LIMIT;

  const isNameExceedLimit = formValues[fieldLabel].length > personalityLabelMaxLength;
  const isDescriptionExceedLimit =
    formValues[fieldDescription]?.length > personalityDescriptionMaxLength;

  const { mutate: optimizePrompt, isLoading: isOptimizationLoading } = optimizePromptMutation;

  const personalityTemplate = getPersonalityTemplateByLanguage({ language });

  const translate = useTr();

  const showPersonalityLibraryModal = useShowPersonalityLibraryModal();

  const handleOptimizeClick = () => {
    setInitialPrompt(formValues[fieldDefinition]);
    optimizePrompt();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setPersonalityCreatedOrUpdated(false);

    if (isCreating) {
      createPersonality(
        {
          label: formValues.label,
          definition: formValues.definition,
          description: formValues.description || null,
          country,
          language,
          is_active: true,
          sharing_permission: formValues.sharingPermission
        },
        {
          onSuccess: data => {
            setPersonalityCreatedOrUpdated(true);
            invalidatePersonalitiesQuery();
            completePersonalityTask();

            onPersonalitySelect(data);

            Toast.success('personality.creation_modal.toast.success');
          }
        }
      );

      return;
    }

    if (formValues[fieldSharingPermission] !== personality?.sharing_permission) {
      trackingWrapper.track('personalitySharingPermissionChange', {
        personalityId: personality.id,
        newSharingPermission: formValues[fieldSharingPermission],
        oldSharingPermission: personality?.sharing_permission ?? sharePermissionOptions[0].value
      });
    }

    updatePersonality(
      {
        personalityId: personality.id,
        label: formValues.label,
        definition: formValues.definition,
        description: formValues.description || null,
        language: personality.language,
        country: personality.country,
        sharing_permission: formValues.sharingPermission
      },
      {
        onSuccess: () => {
          Toast.success('personality.creation_modal.toast.success');
          setPersonalityCreatedOrUpdated(true);
          // Reset conversation if the personality was used in the current conversation
          if (currentPersonalityId === personality.id || isLanguageEditable) {
            onPersonalitySelect(personality);
          } else if (defaultModel) {
            closeModal();

            showPersonalityLibraryModal({
              languageModelId: defaultModel.id,
              onPersonalitySelect
            });
          }
        },
        onError: () => Toast.apiError()
      }
    );
  };

  const handleBackClick = () => {
    onCancelClick();

    if (closeOnBack) {
      closeModal();
      return;
    }

    if (analyzedPersonality) {
      setView(ViewMode.analysis_completed);
      return;
    }

    if (isCreating) {
      setView(ViewMode.choice);
      return;
    }

    if (defaultModel) {
      closeModal();
      showPersonalityLibraryModal({
        languageModelId: defaultModel.id,
        onPersonalitySelect
      });
    }
  };

  const handleTemplateClick = () => {
    if (formValues.definition !== '') {
      const definition = [formValues.definition, ...personalityTemplate].join('\n');
      setFormValues({ ...formValues, [fieldDefinition]: definition });

      return;
    }
    setFormValues({ ...formValues, [fieldDefinition]: personalityTemplate.join('\n') });
  };

  const hint = !isCreating && currentConversationId && (
    <UserHint>
      <Typography variant="body2">
        <FormattedMessage
          id="aiWriter.inspirations.chat.personalities.library_modal.edit_hint"
          values={{
            b: (text: string) => <strong>{text}</strong>
          }}
        />
      </Typography>
    </UserHint>
  );

  return (
    <ModalWithDividedHeaderLayout
      title={
        <FormattedMessage
          id={
            isCreating
              ? 'aiWriter.inspirations.chat.personalities.library_modal.create_headline'
              : 'aiWriter.inspirations.chat.personalities.library_modal.edit_headline'
          }
        />
      }
      helpLink="aiWriter.inspirations.aiwriter.main.personality.help_link"
      closeModal={closeModal}
      height={personalityCreationModalHeight}
      footer={
        <>
          {personalityCreatedOrUpdated ? (
            <Button variant="text" onClick={closeModal} disabled={requestIsRunning}>
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.action_close" />
            </Button>
          ) : (
            <Button variant="text" onClick={handleBackClick} disabled={requestIsRunning}>
              <FormattedMessage id="common.back" />
            </Button>
          )}
          <LoadingButton
            {...withGtmInteraction(
              isEditing ? gtmIds.brandHub.brandVoice.updated : gtmIds.brandHub.brandVoice.create
            )}
            onClick={handleSubmit}
            variant="contained"
            disabled={!isValidFormData}
            loading={requestIsRunning}
          >
            <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.action_save" />
          </LoadingButton>
        </>
      }
    >
      <StyledForm onSubmit={handleSubmit}>
        <FieldsBox>
          {hint}
          <SectionBox>
            <Typography variant="body2">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.name_label" />
            </Typography>
            <TextField
              name={fieldLabel}
              value={formValues.label}
              onChange={e => setFormValues({ ...formValues, [fieldLabel]: e.target.value })}
              error={isNameExceedLimit}
              helperText={
                isNameExceedLimit &&
                translate(
                  'aiWriter.inspirations.chat.personalities.library_modal.name_character_limit_exceed_text',
                  {
                    limit: personalityLabelMaxLength
                  }
                )
              }
              {...withTestId('name-input')}
            />
          </SectionBox>
          <SectionBox>
            <Typography variant="body2">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.description_label" />
            </Typography>
            <TextField
              placeholder={translate(
                'aiWriter.inspirations.chat.personalities.library_modal.description_placeholder'
              )}
              name={fieldDescription}
              value={formValues.description}
              onChange={e => setFormValues({ ...formValues, [fieldDescription]: e.target.value })}
              error={isDescriptionExceedLimit}
              helperText={
                isDescriptionExceedLimit &&
                translate(
                  'aiWriter.inspirations.chat.personalities.library_modal.description_word_count_exceed_text',
                  { limit: personalityDescriptionMaxLength }
                )
              }
              {...withTestId('description-input')}
            />
          </SectionBox>

          <SectionBox>
            <Typography variant="body2">
              <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.definition_label" />
            </Typography>
            <ExtendedPromptInput
              value={formValues.definition}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setFormValues({ ...formValues, [fieldDefinition]: e.target.value })
              }
              placeholder={translate(
                'aiWriter.inspirations.chat.personalities.library_modal.definition_placeholder'
              )}
              {...withTestId('definition-input')}
              actions={
                <>
                  <LoadingButton
                    {...withGtmInteraction(gtmIds.personalitiesLibrary.optimizePersonality)}
                    startIcon={<OptimizeIcon $active={!(formValues.definition === '')} />}
                    onClick={handleOptimizeClick}
                    loading={isOptimizationLoading}
                    disabled={formValues.definition === ''}
                    {...withTestId('optimize-button')}
                  >
                    <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.optimize" />
                  </LoadingButton>
                  <Button
                    startIcon={<TemplateIcon />}
                    onClick={handleTemplateClick}
                    {...withTestId('template-button')}
                  >
                    <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.template" />
                  </Button>
                </>
              }
              footer={
                isUndoVisible && (
                  <StyledUndoIcon
                    {...withGtmInteraction(gtmIds.personalitiesLibrary.undoOptimizationPersonality)}
                    onClick={undoPromptOptimization}
                  />
                )
              }
            />
            <InputFooter>
              {isDefinitionWordCountExceedLimit ? (
                <ErrorText variant="caption" align="left">
                  <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.definition_word_count_exceed_text" />
                </ErrorText>
              ) : !analyzedPersonality ? (
                <Typography variant="caption" align="left">
                  <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.definition_help_text" />
                </Typography>
              ) : null}

              <WordCounterBox $hasError={isDefinitionWordCountExceedLimit}>
                <Typography variant="caption" align="right">
                  {definitionWordCount}/{PERSONALITY_DEFINITION_MAX_WORDS_LIMIT}
                </Typography>
              </WordCounterBox>
            </InputFooter>
          </SectionBox>
          <RowFieldsContainer>
            <SectionBox>
              <Typography variant="body2">
                <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.personality.sharing_permission.label" />
              </Typography>

              <Select
                variant="standard"
                placeholder={translate(
                  'aiWriter.inspirations.chat.personalities.library_modal.personality.sharing_permission.placeholder'
                )}
                name={fieldSharingPermission}
                value={formValues[fieldSharingPermission]}
                onChange={e =>
                  setFormValues({ ...formValues, [fieldSharingPermission]: e.target.value })
                }
                {...withTestId('sharing-permission-select')}
              >
                {sharePermissionOptions.map(option => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </SectionBox>

            <SectionBox>
              <Typography variant="body2">
                <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.language" />
              </Typography>
              {isLanguageEditable ? (
                <div>
                  <EmbeddingModelSelect
                    value={model}
                    onChange={setModel}
                    selectProps={{ variant: 'standard' }}
                    {...withTestId('language-select')}
                  />
                </div>
              ) : (
                <LanguageStaticIndicator>{languageLabel}</LanguageStaticIndicator>
              )}
            </SectionBox>
          </RowFieldsContainer>
        </FieldsBox>
      </StyledForm>
    </ModalWithDividedHeaderLayout>
  );
};

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75rem;
  overflow: auto;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
`;

const UserHint = styled(FlexContainer).attrs({
  gap: 'small'
})`
  background: ${({ theme }) => theme.colors.primary50};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacings.small};
`;

const FieldsBox = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0.75rem;
`;

const OptimizeIcon = styled(AiWriterIcon)<{ $active: boolean }>`
  width: 20px;
  height: 20px;

  &,
  > svg {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.primaryColorMain : theme.colors.blackInactive};
  }
`;

const TemplateIcon = styled(CategoryIcon)`
  &,
  > svg {
    color: ${({ theme }) => theme.colors.primaryColorMain};
  }
`;

const StyledUndoIcon = styled(UndoIcon)`
  color: ${({ theme }) => theme.colors.blackInactive};
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
  width: 18px;
`;

const InputFooter = styled(FlexContainer).attrs({ direction: 'row', justify: 'space-between' })``;

const WordCounterBox = styled.div<{ $hasError?: boolean }>`
  margin-left: auto;

  color: ${({ theme, $hasError }) => $hasError && theme.colors.danger};
`;

const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.colors.danger};
`;

const RowFieldsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xmedium};

  > * {
    width: 6.5rem;
  }
`;

const LanguageStaticIndicator = styled(Typography)`
  flex: 1;
  display: flex;
  align-items: center;
`;
