import { Button, ButtonProps } from '@mui/material';
import FormattedMessage from 'features/i18n/FormattedMessage';
import {
  onboardingContentWidth,
  onboardingMobileThreshold
} from 'features/onboardingJourney/OnboardingRoot';
import { ReactNode } from 'react';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled, { css } from 'styled-components';

type Props = {
  nextButtonProps: ButtonProps;
  nextButtonChildren?: ReactNode;
  skipButtonProps?: ButtonProps;
  skipButtonChildren?: ReactNode;
  enableMobileLayout?: boolean;
};

export const OnboardingFooter = ({
  nextButtonProps,
  nextButtonChildren = <FormattedMessage id="survey.new_user.next" />,
  skipButtonProps,
  skipButtonChildren = <FormattedMessage id="aiWriter.onboarding.skip_button.label" />,
  enableMobileLayout = true
}: Props) => {
  return (
    <Root $enableMobileLayout={enableMobileLayout}>
      <ButtonContainer>
        <Button
          {...withGtmInteraction(gtmIds.onboardingJourney.survey.next)}
          color="primary"
          variant="contained"
          fullWidth={true}
          {...nextButtonProps}
        >
          {nextButtonChildren}
        </Button>
      </ButtonContainer>

      {skipButtonProps && (
        <ButtonContainer>
          <Button variant="text" fullWidth={true} {...skipButtonProps}>
            {skipButtonChildren}
          </Button>
        </ButtonContainer>
      )}
    </Root>
  );
};

const Root = styled.div<{ $enableMobileLayout?: boolean }>`
  width: 100%;
  max-width: ${onboardingContentWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.two};

  @media (max-width: ${onboardingMobileThreshold}) {
    ${({ $enableMobileLayout, theme }) =>
      $enableMobileLayout &&
      css`
        position: fixed;
        bottom: env(safe-area-inset-bottom);
        min-height: 54px;
        max-width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-inline: ${theme.spacings.four};
        background-color: ${theme.mode === 'dark'
          ? theme.colors.commonBlackMain
          : theme.colors.commonWhiteMain};
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
      `}
  }
`;

const ButtonContainer = styled.div`
  width: 100%;

  @media (max-width: ${onboardingMobileThreshold}) {
    min-width: 100px;
  }
`;
