import { Alert, AlertTitle, Button } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import { AuthCenteredPanel } from 'components/auth/AuthCenteredPanel';
import AuthForm from 'components/auth/AuthForm/AuthForm';
import useRedirectWhenAuthenticated from 'components/auth/hooks/useRedirectWhenAuthenticated';
import { Ornament } from 'components/Ornament';
import { getRoutePath } from 'config/routes';
import { login, setHasJwtToken } from 'features/customer/store/actions';
import { CustomerDataPayload } from 'features/customer/store/types';
import { getUserData, saveUserDataToStorage } from 'features/customer/store/utils';
import { decodeSocialLoginState, SocialLoginState } from 'features/social/encodeSocialLoginState';
import posthog from 'posthog-js';
import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import adcellClient from 'services/adcell';
import AxiosClient from 'services/api';
import { CustomerData } from 'services/api/customer/types';
import { authService } from 'services/auth/AuthService';
import firstPromoterClient from 'services/firstPromoterClient';
import { GAEvents } from 'services/tracking/GAEvents';
import {
  TrackingEventLoginAttributes,
  TrackingEventProviderType,
  TrackingEventRegistrationAttributes
} from 'services/tracking/types';
import { useAppDispatch } from 'store/hooks';

const trackRegistrationAndLogin = (
  userData: CustomerDataPayload,
  statusCode: number,
  type: TrackingEventProviderType
) => {
  const eventData: TrackingEventLoginAttributes | TrackingEventRegistrationAttributes = {
    userId: userData.id,
    userEmail: userData.email,
    type,
    pricingId: null
  };

  if (statusCode === 201) {
    GAEvents.userRegistration(eventData);

    posthog?.capture('Sign-up', eventData);

    // Honor promotions
    firstPromoterClient.sendReferralEmail(userData.email);
  }

  // YES, this fall through is correct because after registration the user will be logged in,
  // and we want to track the login event as well
  GAEvents.userLogin(eventData);
};

interface JwtLoginResponse {
  firstLogin: boolean;
  customer: CustomerData;
}

export const LoginCallbackPage = (): ReactElement => {
  useRedirectWhenAuthenticated();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loginInProgress, setLoginInProgress] = useState(true);

  useEffect(() => {
    authService.loginCallback().then(result => {
      if (result) {
        const passedState = result.state as string | null | undefined;
        let decodedSocialLoginState: SocialLoginState | null = null;
        if (passedState && passedState.length > 0) {
          try {
            decodedSocialLoginState = decodeSocialLoginState(passedState);
          } catch { /* empty */ }
        }

        AxiosClient.post<unknown, JwtLoginResponse>('/login/jwt', {
          bid: adcellClient.getSavedBid(),
          campaign_url: decodedSocialLoginState?.campaignUrl,
          preferred_pricing_id: decodedSocialLoginState?.preferredPricingId,
          enforce_free_trial: decodedSocialLoginState?.freeTrial,
        })
          .then(response => {
            // TODO: Move into saga
            // Create our customer object
            const userData = getUserData({
              ...response.customer,
              tax_id: '',
              tax_type: ''
            } as CustomerData);

            // Track registration and or login
            trackRegistrationAndLogin(userData, response.firstLogin ? 201 : 200, 'jwt');

            // Store data in local storage & redux (this should trigger the redirect to our app)
            saveUserDataToStorage(userData, response.firstLogin ? 1 : 0);
            dispatch(setHasJwtToken(authService.isAuthenticated()));
            dispatch(login.success(userData));

            if (decodedSocialLoginState?.campaignUrl) {
              navigate(decodedSocialLoginState.campaignUrl);
            } else {
              navigate(getRoutePath('home'));
            }
          })
          .catch(() => {
            setLoginInProgress(false);
          });
      } else {
        setLoginInProgress(false);
      }
    });
  }, [dispatch, navigate, setLoginInProgress]);

  const handleRedirectToLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigate(getRoutePath('login'));
  };

  let alert;
  if (loginInProgress) {
    alert = (
      <Alert severity="info" variant="standardFullWidth">
        <AlertTitle>
          <FormattedMessage id="login.login_in_progress" />
        </AlertTitle>
      </Alert>
    );
  } else {
    alert = (
      <>
        <Alert severity="error" variant="standardFullWidth">
          <AlertTitle>
            <FormattedMessage id="login.login_failed" />
          </AlertTitle>
        </Alert>
        <Button color="primary" size="large" onClick={handleRedirectToLogin}>
          <FormattedMessage id="common.login" />
        </Button>
      </>
    );
  }

  return (
    <AuthCenteredPanel slogan="register.slogan">
      <AuthForm
        header="login.header"
        ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
      >
        {alert}
      </AuthForm>
    </AuthCenteredPanel>
  );
};
