import { Button, TextField, Typography } from '@mui/material';
import { CloseModal } from 'components/modals/types';
import { EmbeddingModelSelect } from 'features/embeddingModels/EmbeddingModelSelect';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import {
  ContentToAnalyze,
  personalityCreationModalHeight,
  ViewMode
} from 'features/personality/creation/PersonalityCreationModal';
import {
  ModalBodyWithColumnDirection,
  ModalWithDividedHeaderLayout
} from 'features/theme-2024/ModalWithDividedHeaderLayout';
import { ChangeEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { getWordCount } from 'utils/getWordCount';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

export const PERSONALITY_DEFINITION_MAX_WORDS_LIMIT = 600;
export const MIN_WORDS_LIMIT = 65;

export function PersonalityAutomaticCreationView(
  props: {
    languageModel: EmbeddingModel;
    setLanguageModel: (model: EmbeddingModel) => void;
    isLanguageEditable?: boolean;
    setView: (view: ViewMode) => void;

    contentToAnalyze?: ContentToAnalyze;
    setContentToAnalyze: (value: ContentToAnalyze) => void;
    setShouldAnalyze: (value: boolean) => void;
    onBackClick: () => void;
  } & CloseModal
) {
  const {
    languageModel,
    isLanguageEditable,
    contentToAnalyze,
    setShouldAnalyze,
    setContentToAnalyze,
    setView,
    setLanguageModel,
    closeModal,
    onBackClick
  } = props;

  const tr = useTr();
  const [text, setText] = useState((contentToAnalyze as string) ?? '');

  const textWordCount = getWordCount(text);
  const isInputError = textWordCount > PERSONALITY_DEFINITION_MAX_WORDS_LIMIT;
  const isInputValid = isInputError || textWordCount < MIN_WORDS_LIMIT;

  const handleBackClick = () => {
    onBackClick();
    setView(ViewMode.choice);
  };

  const handleNextClick = () => {
    setContentToAnalyze(text);
    setShouldAnalyze(true);
    setView(ViewMode.analysis_completed);
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  return (
    <ModalWithDividedHeaderLayout
      title={
        <FormattedMessage id="aiWriter.inspirations.chat.personalities.library_modal.create_personality" />
      }
      //subtitle="personality.creation_modal.analysisView.subtitle"
      helpLink="aiWriter.inspirations.aiwriter.main.personality.help_link"
      closeModal={closeModal}
      height={personalityCreationModalHeight}
      slots={{
        bodyContainer: ModalBodyWithColumnDirection
      }}
      footer={
        <>
          <Button onClick={handleBackClick} {...withTestId('back-button')}>
            <FormattedMessage id="common.back" />
          </Button>
          <Button
            {...withGtmInteraction(gtmIds.personalitiesLibrary.personalityGenerated)}
            onClick={handleNextClick}
            variant="contained"
            disabled={isInputValid}
            {...withTestId('analyze-button')}
          >
            <FormattedMessage id="personality_creation.copy_paste.next" />
          </Button>
        </>
      }
    >
      <Body>
        <Typography variant="h6">
          <FormattedMessage id="personality_creation.copy_paste.title" />
        </Typography>

        <TextField
          value={text}
          onChange={handleChangeText}
          multiline
          fullWidth
          error={isInputError}
          placeholder={tr('personality_creation.input_placeholder')}
          inputProps={{
            style: {
              height: '300px',
              overflow: 'auto'
            }
          }}
          {...withTestId('sample-text-input')}
        />

        <CounterBox>
          <Typography variant="caption" align="left">
            <FormattedMessage id="personality_creation.copy_paste.hint" />
          </Typography>

          <Typography variant="caption">
            {textWordCount}/{PERSONALITY_DEFINITION_MAX_WORDS_LIMIT}
          </Typography>
        </CounterBox>

        {isLanguageEditable && (
          <EmbeddingModelSelect value={languageModel} onChange={setLanguageModel} />
        )}
      </Body>
    </ModalWithDividedHeaderLayout>
  );
}

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};

  height: 100%;
`;

export const INPUT_OUTPUT_HEIGHT = '13rem';

const CounterBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.two};
`;
