import { brandVoiceServiceClient } from 'services/backofficeIntegration/http/client/createBrandVoiceServiceClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type CreateBrandVoiceFromUrlParams = {
  audienceModelId: string;
  country: string;
  language: string;
  url: string;
  workspaceId: string;
};

export type CreateBrandVoiceFromUrlResponse = {
  brand?: ModelsBrandUpdatable;
  brandVoice?: ModelsBrandVoiceCreatable;
};

export type ModelsBrandUpdatable = {
  address?: string;
  email?: string;
  facebookLink?: string;
  instagramLink?: string;
  linkedinLink?: string;
  name: string;
  phones?: Array<string>;
  values?: Array<string>;
  website?: string;
};

export type ModelsBrandVoiceCreatable = {
  abbreviationRules?: string;
  active?: boolean;
  activeVoice?: boolean;
  advancedRules?: Array<string>;
  /**
   * TODO: remove this field. Read can be done via embedded and write should be done via the brand endpoints
   */
  brand?: ModelsBrandUpdatable;
  contentExamples?: Array<ModelsContentExample>;
  country: string;
  /**
   * TODO: are documents cleaned up if an import is run but no brand voice is created for it afterwards? Should we add a job to delete old documents after 10 days or sth if they still don't have a brand voice?
   */
  creationSourceDocumentID?: string;
  creationSourceType: 'manual' | 'document' | 'url';
  creationSourceURL?: string;
  currencyFormat?: string;
  dateFormat?: string;
  description?: string;
  donts?: string;
  emojiUsage?: string;
  gendering?: Array<string>;
  generalTone?: ModelsGormMap;
  glossaries?: Array<ModelsGlossary>;
  headlinePunctuation?: Array<string>;
  hyphenationRules?: Array<string>;
  industryJargon?: string;
  language: string;
  name: string;
  numberFormat?: string;
  personal?: boolean;
  perspective?: Array<string>;
  phoneNumberFormat?: string;
  /**
   * base64 encoded image
   * TODO: remove this field and extract thumbnail into its own entity
   */
  thumbnailBase64?: string;
  timeFormat?: string;
  useControversialEmojis?: boolean;
  useFormal?: boolean;
  useHeaderPunctuation?: boolean;
  useHyphens?: boolean;
  vocabulary?: Array<string>;
};

export type ModelsGormMap = {
  [key: string]: number;
};

type ModelsGlossary = {
  extended?: string;
  shortcut?: string;
};

type ModelsContentExample = {
  content?: string;
  source?: 'website' | 'instagram' | 'facebook' | 'linkedin' | 'blog' | 'newsletter' | 'other';
  url?: string;
};

type SuccessDto = CreateBrandVoiceFromUrlResponse;

export const httpCreateBrandVoiceFromUrl = mutationEndpoint({
  callEndpoint: (params: CreateBrandVoiceFromUrlParams) =>
    brandVoiceServiceClient
      .post<SuccessDto>(`/workspaces/${params.workspaceId}/brand-voice-url-imports`, params)
      .then(response => response.data)
});
