import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import { dataScienceClientStore } from 'services/backofficeIntegration/http/client/dataScienceClientStore';
import { withApiToken } from 'services/backofficeIntegration/http/client/withApiToken';
import { withErrorLogging } from 'services/backofficeIntegration/http/client/withErrorLogging';
import { withRateLimitSupport } from 'services/backofficeIntegration/http/client/withRateLimitSupport';
import { getDataScienceApiUrl } from 'services/backofficeIntegration/http/getDataScienceApiUrl';

/**
 * This is exported temporary so we could reuse this in
 * `/src/services/api` until we get rid of it.
 */
export function createDataScienceClient(includeAuthorization = true) {
  const client = axios.create({
    baseURL: getDataScienceApiUrl()
  });

  axiosBetterStacktrace(client);

  dataScienceClientStore.setState({ client });

  withErrorLogging(client);
  if (includeAuthorization) {
    withApiToken(client);
  }
  withRateLimitSupport(client);

  return client;
}

export const dataScienceClient = createDataScienceClient();
export const dataScienceAnonymousClient = createDataScienceClient(false);
