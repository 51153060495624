import LocalStorageKey from 'config/localStorageKey';
import * as actions from 'features/customer/store/actions';
import { CustomerRole, CustomerState } from 'features/customer/store/types';
import { ACLPricing } from 'services/api/customer/types';
import { authService } from 'services/auth/AuthService';
import { ActionType, getType } from 'typesafe-actions';

export type CustomerAction = ActionType<typeof actions>;

const initialState: CustomerState = {
  username: localStorage.getItem(LocalStorageKey.CustomerUsername) || '',
  id: 0,
  authReady: false,
  hasJwtToken: false,
  apiToken: localStorage.getItem(LocalStorageKey.APIToken) || '',
  apiCustomerToken: '',
  timezone: localStorage.getItem(LocalStorageKey.CustomerTimezone) || '',
  image: localStorage.getItem(LocalStorageKey.CustomerPicture) || '',
  firstLogin:
    // Default to true if never set
    localStorage.getItem(LocalStorageKey.CustomerIsFirstLogin) === null
      ? true
      : localStorage.getItem(LocalStorageKey.CustomerIsFirstLogin) === '1',
  pricingExpiryDate: null,
  pricing: {} as ACLPricing,
  subscription: null,
  restrictions: {
    aiWriter: true,
    exploring: true,
    aiTester: true,
    subjectLines: true,
    aiImages: true
  },
  teamRole: CustomerRole.OWNER,
  teams: [],
  groupId: 0,
  email: '',
  error: '',
  isLoading: false,
  isReady: false,
  isCustomerDataLoaded: false,
  pricingExpired: false,
  preferredPricingId: null,
  enforceFreeTrial: false,
  trialUsed: false,
  wordsLimitReached: false,
  billingInfo: {
    fullName: '',
    postalCode: '',
    city: '',
    address: '',
    country: '',
    phone: '',
    companyName: '',
    useCompanyAddress: false
  },
  cardBrand: null,
  cardLastFour: null,
  isVerified: false,
  registrationDate: '',
  sepaPaymentFailed: false,
  referralLink: '',
  wordsUsedMonthly: 0,
  isApiCustomer: false,
  hasTeam: false,
  wordsShownMonthly: 0,
  hasSeenCanceledSubscriptionForTeams: false,
  currency: 'eur',
  newsletterSubscribed: false
};

export function customer(
  state: CustomerState = initialState,
  action: CustomerAction
): CustomerState {
  switch (action.type) {
    case getType(actions.login.request):
      return { ...state, email: action.payload.email, isLoading: true };
    case getType(actions.loginWithSession.request):
      return { ...state, isLoading: true };

    case getType(actions.login.failure):
    case getType(actions.loginWithSession.failure):
      return { ...state, isLoading: false, error: action.payload };

    case getType(actions.unsubscribeFromPlan.request):
    case getType(actions.updateCurrentUser.request):
      return { ...state, isLoading: true };

    case getType(actions.unsubscribeFromPlan.failure):
    case getType(actions.updateCurrentUser.failure):
      return { ...state, isLoading: false };
    case getType(actions.login.success):
    case getType(actions.loginWithSession.success):
    case getType(actions.updateCurrentUser.success):
    case getType(actions.unsubscribeFromPlan.success):
    case getType(actions.emailVerified):
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isReady: true
      };

    case getType(actions.getCurrentUser.success):
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isCustomerDataLoaded: true,
        isReady: true
      };

    case getType(actions.getCurrentUser.failure):
      authService.clearCredentials()
      return {
        ...initialState,
        apiToken: '',
        username: '',
        hasJwtToken: false,
      };

    case getType(actions.logout.success):
    case getType(actions.forcedLogout):
      authService.clearCredentials()
      return {
        ...initialState,
        apiToken: '',
        username: '',
        hasJwtToken: false,
      };

    case getType(actions.removeError): {
      return { ...state, error: '' };
    }

    case getType(actions.welcomeMessageClosed):
      return {
        ...state,
        firstLogin: false
      };

    case getType(actions.setWordsLimitReached):
      return {
        ...state,
        wordsLimitReached: action.payload
      };

    case getType(actions.setReferralLink):
      return {
        ...state,
        referralLink: action.payload
      };

    case getType(actions.setSubscription):
      return {
        ...state,
        ...action.payload
      };
    case getType(actions.setHasTeam):
      return {
        ...state,
        hasTeam: action.payload
      };

    case getType(actions.updateApiCustomerToken):
      return {
        ...state,
        apiCustomerToken: action.payload
      };
    case getType(actions.setCanceledSubscriptionForTeamsSeen):
      return {
        ...state,
        hasSeenCanceledSubscriptionForTeams: true
      };

    case getType(actions.setAuthReady):
      return {
        ...state,
        authReady: true,
      };

    case getType(actions.setHasJwtToken):
      return {
        ...state,
        apiToken: '',
        hasJwtToken: action.payload,
      };

    default:
      return state;
  }
}
