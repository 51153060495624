import { Button, TextField, Typography } from '@mui/material';
import { Action } from '@reduxjs/toolkit';
import { SpinnerDimmer } from 'components/base/SpinnerDimmer';
import { ColoredTypography } from 'components/ColoredTypography';
import { CharacterLimiterWithLabel } from 'components/inputs/CharacterLimiterWithLabel';
import { CloseModal } from 'components/modals/types';
import { ImageUploadFieldInput } from 'components/profile/modals/editProfile/ImageUploadFieldInput';
import Toast from 'components/toasts/Toast';
import { updateCurrentUser } from 'features/customer/store/actions';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { ThunkAction } from 'redux-thunk/src/types';
import { useAppSelector } from 'store/hooks';
import store from 'store/store';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { object, string } from 'yup';

type FormValues = {
  name: string;
  image: undefined | string;
};

const PROFILE_NAME_MAX_LENGTH = 45;

type Props = CloseModal;

const EditProfileModal = ({ closeModal }: Props) => {
  const translate = useTr();
  const { username, isLoading } = useAppSelector(state => state.customer, shallowEqual);

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required().max(PROFILE_NAME_MAX_LENGTH),
      }),
    []
  );

  return (
    <SpinnerDimmer active={isLoading} loader>
      <Root>
        <ColoredTypography variant="h6">
          <FormattedMessage id="profile.edit_profile" />
        </ColoredTypography>

        <Formik
          initialValues={{
            name: username,
            image: undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={values => store.dispatch(updateAndCloseModalThunk(values))}
        >
          {({ values, handleChange, ...rest }) => (
            <FormContainer>
              <CharacterLimiterWithLabel
                label={translate('profile.username')}
                length={values.name.length}
                maxLength={PROFILE_NAME_MAX_LENGTH}
                titleVariant="subtitle2"
              >
                <TextField
                  fullWidth
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  error={rest.touched.name && Boolean(rest.errors.name)}
                />
              </CharacterLimiterWithLabel>
              <div>
                <Typography variant="subtitle2">
                  <FormattedMessage id="register.picture" />
                </Typography>
                <ImageUploadFieldInput fieldName="image" />
              </div>
              <ButtonContainer>
                <Button size="large" onClick={closeModal}>
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button size="large" type="submit" variant="contained" color="primary">
                  <FormattedMessage id="common.confirm" />
                </Button>
              </ButtonContainer>
            </FormContainer>
          )}
        </Formik>
      </Root>
    </SpinnerDimmer>
  );

  // #tech-debt: https://app.clickup.com/t/30378wn
  function updateAndCloseModalThunk(
    values: FormValues
  ): ThunkAction<void, unknown, unknown, Action> {
    return async dispatch => {
      dispatch(
        updateCurrentUser.request(
          {
            name: values.name,
            image: values.image
          },
          { thunk: true }
        )
      );

      Toast.success('profile.success');

      closeModal();
    };
  }
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacings.four};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.three};
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.two};

  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacings.two};
`;

export default EditProfileModal;
