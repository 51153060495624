import { ModelAutocomplete } from 'features/embeddingModels/ModelAutocomplete';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import {
  Answer,
  QnAPair,
  Question
} from 'features/onboardingJourney/new-onboarding-2025/old-components/onboarding-survey-old';
import { useField, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export type Language = {
  language?: string;
  country?: string;
};

export const OnboardingSurveyLanguageField = (props: {
  name: string;
  models: EmbeddingModel[];
  onSubmit: (values: Language) => void;
}) => {
  const { name, models, onSubmit } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext<Language>();
  const handleLanguage = (model: string) => {
    setFieldValue('language', model);
    onSubmit({ language: model });
  };

  return (
    <Root>
      <QnAPair>
        <Question variant="body1">
          <FormattedMessage id="aiWriter.onboarding_journey.survey_view.language.question" />
        </Question>
        <Answer key={Math.random()}>
          <ModelAutocomplete
            models={models}
            {...field}
            onSelect={handleLanguage}
            size="small"
            isSaveAsDefaultShown={false}
          />
        </Answer>
      </QnAPair>
    </Root>
  );
};

const Root = styled.div`
  padding-bottom: 8px;
`;
