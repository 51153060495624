import { Table, TableHead, TableRow, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { YellowCrownImage } from 'components/icons/YellowCrownImage';
import {
  FirstCell,
  StyledCell,
  StyledTableContainer
} from 'components/profile/components/pricing-plan-card/Components';
import { PricingPlan } from 'features/pricing/types';
import { capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

export const PricingPlanTable = () => {
  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <FirstCell>
              <Typography variant="h6">
                <FormattedMessage id="features_comparison.pricing_plan.title" />
              </Typography>
            </FirstCell>
            <BorderedCell>
              <Typography variant="h6">{capitalize(PricingPlan.lite)}</Typography>
            </BorderedCell>
            <BorderedCell>
              <Typography variant="h6">{capitalize(PricingPlan.starter)}</Typography>
            </BorderedCell>
            <StyledCell>
              <PlanNameContainer>
                <Typography variant="h6">{capitalize(PricingPlan.pro)}</Typography>
                <YellowCrownImage />
              </PlanNameContainer>
            </StyledCell>
            <StyledCell>
              <PlanNameContainer>
                <Typography variant="h6">{capitalize(PricingPlan.business)}</Typography>
                <YellowCrownImage />
              </PlanNameContainer>
            </StyledCell>
          </TableRow>
        </TableHead>
      </Table>
    </StyledTableContainer>
  );
};

const PlanNameContainer = styled(FlexContainer).attrs({
  direction: 'row',
  gap: 'one',
  alignItems: 'center',
  justifyContent: 'center'
})``;

const BorderedCell = styled(StyledCell)`
  border: ${({ theme }) => `1px solid ${theme.colors.blackHoverOverlay}`};
`;
