import { useMutation } from '@tanstack/react-query';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { getUserAudiences } from 'features/audiences/store/selectors';
import { useCustomerPreferences } from 'features/customerPreferences/useCustomerPreferences';
import { generateTexts } from 'features/textGenerator/actions/generateTexts';
import { useAppSelector } from 'store/hooks';
import { isObject } from 'utils/isObject';

type GenerateTemplateTextsOptions = {
  userPrompt: string;
};

type TextGenerationJsonSchema = {
  headline: { text: string };
  subtitle: { text: string };
  'primary-color': { color: string };
};

export const useGenerateTemplateTexts = () => {
  const audiences = useAppSelector(getUserAudiences);
  const { data: customerPreferences } = useCustomerPreferences();

  return useMutation({
    mutationFn: async ({ userPrompt }: GenerateTemplateTextsOptions) => {
      const language = customerPreferences?.preferredLanguage ?? 'en';
      const country = customerPreferences?.preferredLanguageCountry ?? 'us';
      if (!language || !country) {
        throw new Error('Language or country not found');
      }

      const audience = getPreferredAudience({ audiences, locale: { language, country } });
      if (!audience || !audience.model_id) {
        throw new Error(`Audience (${JSON.stringify(audience || {})}) not found`);
      }

      const result = await generateTexts(
        {
          audienceModelId: audience.model_id,
          nTimes: 1,
          nTextItems: 1,
          outputType: 'placid_schema_json'
        },
        { text: userPrompt }
      );

      const resultText = result?.outputs[0]?.text || '';
      if (!resultText || resultText.length === 0) {
        return null;
      }

      try {
        // Text is a formatted JSON string
        const json = JSON.parse(resultText) as TextGenerationJsonSchema;
        if (!isObject(json) || !json.headline || !json.subtitle) {
          return null;
        }

        return json;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return null;
    }
  });
};
