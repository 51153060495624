import { Table, TableBody } from '@mui/material';
import { FeatureTableRow } from 'components/profile/components/feature-comparison/FeatureTableRow';
import { FeatureTableRowData } from 'components/profile/components/feature-comparison/FeatureTableRowData';
import {
  SectionTitle,
  StyledTableContainer
} from 'components/profile/components/pricing-plan-card/Components';
import { FormattedMessage } from 'react-intl';

const aIModelsTableData: FeatureTableRowData[] = [
  {
    nameTranslationKey: 'features_comparison.ai_models.text_models',
    litePlan: 'features_comparison.ai_models.text_models_lite',
    starterPlan: 'features_comparison.ai_models.text_models_standard',
    proPlan: 'features_comparison.ai_models.text_models_pro',
    businessPlan: 'features_comparison.ai_models.text_models_business'
  },
  {
    nameTranslationKey: 'features_comparison.ai_models.image_models',
    litePlan: 'features_comparison.ai_models.image_models_lite',
    starterPlan: 'features_comparison.ai_models.image_models_standard',
    proPlan: 'features_comparison.ai_models.image_models_pro',
    businessPlan: 'features_comparison.ai_models.image_models_business'
  },
  {
    nameTranslationKey: 'features_comparison.ai_models.context_window',
    tooltipTranslationKey: 'features_comparison.ai_models.context_window.tooltip',
    litePlan: 'features_comparison.ai_models.context_window_lite',
    starterPlan: 'features_comparison.ai_models.context_window_standard',
    proPlan: 'features_comparison.ai_models.context_window_pro',
    businessPlan: 'features_comparison.ai_models.context_window_business'
  }
];

export const AIModelsTable = () => {
  return (
    <StyledTableContainer>
      <SectionTitle>
        <FormattedMessage id="features_comparison.ai_models.label" />
      </SectionTitle>
      <Table>
        <TableBody>
          {aIModelsTableData.map(row => (
            <FeatureTableRow key={row.nameTranslationKey} row={row} />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
