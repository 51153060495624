import { IFRAME_ID, routeIframe } from 'features/brand-hub-v2/newAppPostMessage';
import { useEffect, useState } from 'react';
import { authService } from 'services/auth/AuthService';
import styled from 'styled-components';
import { useAppThemeStore } from 'styles/new-theme/useAppThemeStore';

const iframeUrl = process.env.REACT_APP_BRAND_HUB_V2_URL;
const iframeEntryPoint = '/brand-hub';

export const BrandHubV2IframeWrapper = () => {
  const themeMode = useAppThemeStore(state => state.themeMode);
  const [iframeRoute, setIframeRoute] = useState('/');

  const [localAuthToken, setLocalAuthToken] = useState<string | null>(authService.getAccessToken());
  const [url, setUrl] = useState<string | null>(null);

  // Refresh local state on token change, to make sure the iframe is reloaded with the new token
  const authToken = authService.getAccessToken();
  useEffect(() => {
    setLocalAuthToken(authToken);
  }, [authToken]);

  useEffect(() => {
    setIframeRoute(iframeEntryPoint);
    setUrl(
      `${iframeUrl}${iframeEntryPoint}?isEmbedded=true&theme-mode=${themeMode}&auth-token=${localAuthToken}`
    );
  }, [localAuthToken, themeMode, url]);

  useEffect(() => {
    if (iframeRoute) {
      routeIframe(iframeRoute);
    }
  }, [iframeRoute]);

  if (!url) {
    return null;
  }

  const isActive = Boolean(iframeRoute);
  return (
    <StyledIframe id={IFRAME_ID} title="Embedded App" src={url} $isActive={isActive}></StyledIframe>
  );
};

const StyledIframe = styled.iframe<{ $isActive: boolean }>`
  border: none;
  width: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
  height: ${({ $isActive }) => ($isActive ? '100%' : '1px')};
`;
