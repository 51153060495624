import { createSelector } from '@reduxjs/toolkit';
import { ACLPricing } from 'services/api/customer/types';
import { RootState } from 'store/rootReducer';

export const getAuthReady = (state: RootState) => state.customer.authReady;
export const getUserId = (state: RootState) => state.customer.id;
export const getHasJwtToken = (state: RootState) => state.customer.hasJwtToken;
export const getUserToken = (state: RootState) => state.customer.apiToken;
export const getApiCustomerToken = (state: RootState) => state.customer.apiCustomerToken;
export const getUserName = (state: RootState) => state.customer.username;
export const getUserEmail = (state: RootState) => state.customer.email;
export const getUserImage = (state: RootState) => state.customer.image;
export const getBillingInfo = (state: RootState) => state.customer.billingInfo;
export const getPricing = (state: RootState): ACLPricing => state.customer.pricing;
export const getPricingId = (state: RootState) => state.customer.pricing.id;
export const getPricingName = (state: RootState) => state.customer.pricing.name;
export const getPricingType = (state: RootState) => state.customer.pricing.type;
export const getIsPricingExpired = (state: RootState) => state.customer.pricingExpired;
export const getEnforceFreeTrial = (state: RootState) => state.customer.enforceFreeTrial;
export const getPreferredPricing = (state: RootState) => state.customer.preferredPricingId;
export const getIsFirstLogin = (state: RootState) => state.customer.firstLogin;
export const getGroupId = (state: RootState) => state.customer.groupId;
export const getUserStripeId = (state: RootState) => state.customer.stripeId;
export const getWordsLimitReached = (state: RootState) => state.customer.wordsLimitReached;
export const getSubscriptionStatus = (state: RootState) => state.customer.subscription?.status;
export const getIsVerified = (state: RootState) => state.customer.isVerified;
export const getNewsletterSubscribed = (state: RootState) => state.customer.newsletterSubscribed;
export const getIsApiCustomer = (state: RootState) => state.customer.isApiCustomer;
export const getCustomerRole = (state: RootState) => state.customer.teamRole;
export const getCustomerTeams = (state: RootState) => state.customer.teams;
export const getCustomerWordsShownMonthly = (state: RootState) => state.customer.wordsShownMonthly;
export const getHasSeenCanceledSubscriptionForTeams = (state: RootState) =>
  state.customer.hasSeenCanceledSubscriptionForTeams;
export const getTrialUsed = (state: RootState) => state.customer.trialUsed;

export const getHasToken = createSelector(
  [getUserName, getUserToken, getHasJwtToken],
  (username, token, hasJwtToken) => !!(username && (token || hasJwtToken))
);

export const getCustomerTeamId = createSelector(getCustomerTeams, teams =>
  !!teams && teams.length > 0 ? teams[0]?.id : null
);

export const getCustomerTeamName = createSelector(getCustomerTeams, teams =>
  !!teams && teams.length > 0 ? teams[0]?.name : ''
);

export const getIsAuthenticated = createSelector(
  [getUserId, getUserToken, getHasJwtToken],
  (id, token, hasJwtToken) => !!(id && (token || hasJwtToken))
);

export const getRestrictions = createSelector(
  (state: RootState) => state.customer.restrictions,
  restrictions => restrictions
);

export const getIsAppReady = createSelector(
  getHasToken,
  (state: RootState) => state.customer.authReady,
  (state: RootState) => state.customer.isReady,
  (state: RootState) => state.models.isReady,
  (state: RootState) => !state.group.isLoading,
  (state: RootState) => state.audiences.audiences.length > 0,
  (hasToken, authReady, customerReady, modelsReady, groupsReady, hasAudiences) => {
    if (!authReady) {
      return false;
    }

    // initially logged in
    if (hasToken) {
      return customerReady && modelsReady && groupsReady && hasAudiences;
    }

    // not logged in - we don't want white screen there
    return true;
  }
);

export const canUpgradePricingPlan = createSelector(
  getPricing,
  getIsApiCustomer,
  (pricingPlan, isApiCustomer) =>
    !['premium', 'enterprise'].includes(pricingPlan.type) && !isApiCustomer
);

export const getMustVerifyEmail = createSelector(
  (state: RootState) => state.customer,
  ({ isVerified, firstLogin }) => !isVerified && !firstLogin
);
