import { useEffect } from 'react';

/**
 * Hook to handle social media app redirects on iOS devices.
 * 
 * Problem:
 * When users click links to our app from within social media apps (LinkedIn, Facebook) on iOS devices,
 * they encounter an error: "Access blocked: neuroflash's request does not comply with Google's policies"
 * This is a known issue with social media in-app browsers on iOS.
 * 
 * Solution:
 * We detect if the user is accessing our app from LinkedIn or Facebook's iOS app and redirect them to Safari,
 * where the app works correctly. This issue only affects iOS devices, not Android.
 * 
 */
export const useLinkedInFacebookInRedirect = () => {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const url = window.location.href;

    if (
      userAgent.includes('Mobile') &&
      (userAgent.includes('iPhone') || userAgent.includes('iPad')) &&
      (userAgent.includes('LinkedInApp') || userAgent.includes('FBAN') || userAgent.includes('FBAV'))
    ) {
      window.location.href = 'x-safari-' + url;
    }
  }, []);
}; 