import { CloseModal } from 'components/modals/types';
import { removeDimension } from 'features/dimensions/store/actions';
import { getCurrentDimension } from 'features/dimensions/store/selectors';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { promisifySagaThunk } from 'utils/utils';

import Alert from '../../../base/Alert';
import ConfirmModal from '../../../modals/ConfirmModal';

type Props = CloseModal & {
  id: number;
  label: string;
  unownedSelection?: boolean;
};

const RemoveListModal = ({ closeModal, unownedSelection, id, label }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.dimensions);
  const currentDimension = useAppSelector(getCurrentDimension);

  return (
    <ConfirmModal
      title="common.confirm"
      messageValues={{ label: label || currentDimension.label }}
      message="dimensions.remove_dimension.confirm"
      isLoading={isLoading}
      onCancel={closeModal}
      onOK={async () => {
        await dispatch(promisifySagaThunk(removeDimension.request, id || currentDimension.id));
        closeModal();
      }}
    >
      {unownedSelection && (
        <Alert type="info" icon="info">
          <FormattedMessage id="dimensions_mgmt.modal_remove_unowned" />
        </Alert>
      )}
    </ConfirmModal>
  );
};

export default RemoveListModal;
