import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { OnboardingBrandVoiceFinishedOld } from 'features/onboardingJourney/new-onboarding-2025/old-components/onboarding-finished-old';
import { OnboardingBrandVoiceFinishedNew } from 'features/onboardingJourney/new-onboarding-2025/onboarding-finished-new';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export const OnboardingBrandVoiceFinished = () => {
  const isNewOnboardingEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  // When the feature flag is still loading
  if (isNewOnboardingEnabled === undefined) {
    return <LoadingAnimationWithAlternatingMessages />;
  }

  return isNewOnboardingEnabled ? (
    <OnboardingBrandVoiceFinishedNew />
  ) : (
    <OnboardingBrandVoiceFinishedOld />
  );
};
