import { create } from 'zustand';

type WorkspaceStoreState = {
  workspaceId?: string;
};

type WorkspaceStoreActions = {
  setWorkspaceId: (workspaceId: string) => void;
};

const initialState: WorkspaceStoreState = {
  workspaceId: undefined
};

export const useWorkspaceStore = create<WorkspaceStoreState & WorkspaceStoreActions>()(set => ({
  ...initialState,
  setWorkspaceId: workspaceId => set({ workspaceId })
}));
