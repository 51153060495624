export type SocialLoginState = {
  uuid?: string;
  newsletterSubscribed?: boolean;
  isPaid?: boolean;
  preferredPricingId?: number;
  freeTrial?: boolean;
  campaignUrl?: string;
};

export const encodeSocialLoginState = ({
  uuid,
  newsletterSubscribed,
  isPaid,
  preferredPricingId,
  freeTrial,
  campaignUrl
}: SocialLoginState) =>
  window.btoa(
    JSON.stringify({
      uuid,
      newsletterSubscribed,
      isPaid,
      preferredPricingId,
      freeTrial,
      campaignUrl
    })
  );

export const decodeSocialLoginState = (encoded: string): SocialLoginState =>
  JSON.parse(window.atob(encoded));
