import EmailIcon from '@mui/icons-material/Email';
import { Button, Typography } from '@mui/material';
import backgroundDoodleOrnament from 'assets/background-doodle.png';
import FlexContainer from 'components/FlexContainer';
import { BoolCheckboxField } from 'components/forms/BoolCheckboxField';
import EmailField from 'components/forms/customFields/EmailField';
import NameField from 'components/forms/customFields/NameField';
import PasswordField from 'components/forms/customFields/PasswordField';
import { Ornament } from 'components/Ornament';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { CentredRegistrationFormContent } from 'components/register/RegistrationFormContent/CentredRegistrationFormContent';
import { RegistrationReturnToLogin } from 'components/register/RegistrationReturnToLogin';
import { RegistrationSuccess } from 'components/register/RegistrationSuccess';
import Toast from 'components/toasts/Toast';
import emailValidationCheck from 'features/customer/utils/emailValidationCheck';
import { getCurrentLanguage } from 'features/language/store/selectors';
import getFirstFreePlanId from 'features/pricing/utils/getFirstFreePlanId';
import { socialButtonStyles } from 'features/social/SocialButtonStyles';
import { SocialRegisterOrLoginForm } from 'features/social/SocialRegisterOrLoginForm';
import { Formik, FormikHelpers } from 'formik';
import posthog from 'posthog-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import adcellClient from 'services/adcell';
import CustomerAPI from 'services/api/customer';
import { handleCustomerError } from 'services/api/customer/errors';
import { isCreateCheckoutResponse } from 'services/api/customer/types';
import firstPromoterClient from 'services/firstPromoterClient';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import getTimezone from 'utils/getTimezone';
import { getValidationForField } from 'utils/getValidationForField';
import useTr from 'utils/hooks/useTr';
import redirectToStripe from 'utils/redirectToStripe';
import { delayExecution } from 'utils/utils';
import { passwordRules } from 'utils/validationRules';
import { object, string } from 'yup';

const nameField = 'name';
const emailField = 'email';
const passwordField = 'password';
const newsletterSubscribedField = 'newsletterSubscribed';

type FreeTrialFormValues = {
  [nameField]: string;
  [emailField]: string;
  [passwordField]: string;
  [newsletterSubscribedField]: boolean;
};

const initialValues: FreeTrialFormValues = {
  [nameField]: '',
  [emailField]: '',
  [passwordField]: '',
  [newsletterSubscribedField]: false
};

const recaptchaAction = 'register';

export const TrialEnforcedRegistrationPanel = () => {
  const tr = useTr();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const language = useAppSelector(getCurrentLanguage);
  const [signUpWithEmailClicked, setSignUpWithEmailClicked] = useState(false);
  const [preferredPricingId, setPreferredPricingId] = useState<number | undefined>(undefined);
  const [enforceFreeTrial, setEnforceFreeTrial] = useState<boolean | undefined>(undefined);

  const [registrationSuccessScreen, setRegistrationSuccessScreen] =
    useState<React.ReactElement | null>(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const preferredPricingIdParam = searchParams.get('preferred_pricing_id');
    const enforceFreeTrialParam = searchParams.get('free_trial');

    const preferredPricingId = preferredPricingIdParam ? parseInt(preferredPricingIdParam, 10) : undefined;
    setPreferredPricingId(preferredPricingId);

    const enforceFreeTrial = enforceFreeTrialParam === 'true' ? true : enforceFreeTrialParam === 'false' ? false : undefined; 
    setEnforceFreeTrial(enforceFreeTrial);
  }, [searchParams]);

  const formValidation = useMemo(
    () =>
      object().shape({
        [nameField]: string()
          .trim()
          .required(tr(getValidationForField('name')))
          .max(255),
        [emailField]: string()
          .required(tr(getValidationForField('email')))
          .email(),
        [passwordField]: passwordRules(tr)
      }),
    [tr]
  );

  const handleAccountSubmit = useCallback(
    async (
      { name, email, password, newsletterSubscribed }: FreeTrialFormValues,
      { setStatus, setSubmitting }: FormikHelpers<FreeTrialFormValues>
    ) => {
      const firstFreePlanId = await getFirstFreePlanId();
      if (!firstFreePlanId) {
        Toast.commonError();
        return;
      }

      const recaptchaResult = await executeRecaptcha?.(recaptchaAction);
      if (!recaptchaResult) {
        Toast.backendError(handleCustomerError('ERROR_INITIALIZATION_ACCOUNT_ACTIVITY'));
        return;
      }

      const createCheckoutResponse = await CustomerAPI.createCheckout({
        name,
        email,
        password,
        pricing: firstFreePlanId,
        timezone: getTimezone(),
        bid: adcellClient.getSavedBid(),
        locale: language,
        newsletterSubscribed,
        token: recaptchaResult,
        expected_action: recaptchaAction,
        enforceFreeTrial: enforceFreeTrial ?? null,
        preferredPricingId: preferredPricingId ?? null
      });

      if (!createCheckoutResponse.status) {
        setSubmitting(false);
        setStatus(
          createCheckoutResponse.data.message ||
            tr(handleCustomerError('ERROR_INITIALIZATION_ACCOUNT_ACTIVITY'))
        );

        Toast.backendError(handleCustomerError(createCheckoutResponse.message));
        return;
      }

      const { data: responseData } = createCheckoutResponse;

      // Track registration
      GAEvents.userRegistration({
        userId: responseData.id,
        userEmail: email,
        pricingId: null,
        type: 'email'
      });

      posthog?.capture('Sign-up', {
        userId: responseData.id,
        userEmail: email,
        pricingId: null,
        type: 'email'
      });

      firstPromoterClient.sendReferralEmail(email);

      // Wait for tracking to be sent
      await delayExecution(10);

      if (isCreateCheckoutResponse(responseData)) {
        redirectToStripe(responseData.checkout_url);
        return;
      }

      navigate({ search: `?ref=${firstFreePlanId}` });
      setRegistrationSuccessScreen(<RegistrationSuccess email={email} password={password} />);
    },
    [executeRecaptcha, language, navigate, tr, enforceFreeTrial, preferredPricingId]
  );

  function signUpWithEmailButton() {
    return (
      <Button
        css={socialButtonStyles}
        onClick={() => setSignUpWithEmailClicked(true)}
        startIcon={<EmailIcon />}
      >
        <Typography variant="subtitle1">
          <FormattedMessage id="register.free_trial.email" />
        </Typography>
      </Button>
    );
  }

  if (registrationSuccessScreen) {
    return registrationSuccessScreen;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      initialErrors={{ tos: 'tos must be one of the following values: true' }}
      key="account"
      validationSchema={formValidation}
      onSubmit={handleAccountSubmit}
    >
      {({ isSubmitting, status, isValid, values }) => (
        <CentredRegistrationFormContent
          header="register.free_trial.header"
          status={status}
          isLoading={isSubmitting}
          ornaments={<Ornament src={backgroundDoodleOrnament} bottom="0%" right="0%" />}
          socials={
            <SocialRegisterOrLoginForm
              formName="register"
              newsletterSubscribed={values[newsletterSubscribedField]}
              preferredPricingId={preferredPricingId ?? undefined}
              freeTrial={enforceFreeTrial ?? undefined}
            />
          }
          extra={<RegistrationReturnToLogin />}
        >
          {signUpWithEmailClicked ? (
            <>
              <NameField name={nameField} />
              <EmailField
                name={emailField}
                validate={(email: string) => emailValidationCheck(email, tr)}
              />
              <PasswordField name={passwordField} />
            </>
          ) : (
            signUpWithEmailButton()
          )}
          <FlexContainer gap="medium">
            <BoolCheckboxField
              name={newsletterSubscribedField}
              label={tr('register.mail_subscription')}
            />
            <PrivacyPolicy />
            <Actions>
              <Button
                variant="contained"
                color="primary"
                id="next-button"
                disabled={!isValid}
                type="submit"
                fullWidth
              >
                <FormattedMessage id="freeTrial.form.submit" />
              </Button>
            </Actions>
          </FlexContainer>
        </CentredRegistrationFormContent>
      )}
    </Formik>
  );
};

const Actions = styled.div`
  display: flex;
  justify-content: center;
`;
