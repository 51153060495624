import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getUserId } from 'features/customer/store/selectors';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import React, { useRef, useState } from 'react';
import { httpPostBrandVoiceDefault } from 'services/backofficeIntegration/http/endpoints/new-brand-voice/httpPostBrandVoiceDefault';
import { useAppSelector } from 'store/hooks';

import { useGetBrandVoicesInfinite } from './useGetBrandVoicesInfinite';

type BrandVoiceSelectProps = {
  value?: string;
  onSelect?: (brandVoiceId: string) => void;
};

export const BrandVoiceSelect = ({ value, onSelect }: BrandVoiceSelectProps) => {
  // State for selected brand voice
  const [selectedBrandVoice, setSelectedBrandVoice] = useState<string>(value || '');

  // Workspace and customer IDs
  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const customerId = useAppSelector(getUserId);

  // Fetch brand voices
  const { brandVoices, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetBrandVoicesInfinite({
      size: 10,
      sort: 'updated_at'
    });

  // Reference to the menu list container for infinite scrolling
  const menuListRef = useRef<HTMLUListElement>(null);

  // Handle scroll event for infinite loading
  const handleMenuScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const target = event.currentTarget;
    const isNearBottom = target.scrollHeight - target.scrollTop <= target.clientHeight + 50;

    if (isNearBottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  // Set default brand voice mutation
  const { mutate: setDefaultBrandVoice } = useMutation({
    mutationFn: ({ brandVoiceId, workspaceId }: { brandVoiceId: string; workspaceId: string }) =>
      httpPostBrandVoiceDefault.callEndpoint({
        workspaceId,
        customerId,
        brandVoiceId
      })
  });

  // Handle select change
  const handleChange = (event: SelectChangeEvent<string>) => {
    const brandVoiceId = event.target.value;
    setSelectedBrandVoice(brandVoiceId);
    onSelect?.(brandVoiceId);
    if (brandVoiceId && workspaceId) {
      setDefaultBrandVoice({ brandVoiceId, workspaceId });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="brand-voice-select-label">Brand Voice</InputLabel>
      <Select
        labelId="brand-voice-select-label"
        id="brand-voice-select"
        value={selectedBrandVoice}
        label="Brand Voice"
        onChange={handleChange}
        MenuProps={{
          MenuListProps: {
            sx: { maxHeight: 250 },
            onScroll: handleMenuScroll,
            component: 'ul',
            ref: menuListRef
          }
        }}
        renderValue={selected => {
          const brandVoice = brandVoices.find(bv => bv.id === selected);
          return brandVoice ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ width: 24, height: 24, mr: 1 }} src={brandVoice.thumbnailUrl} />
              <span>{brandVoice.name || 'Unnamed Brand Voice'}</span>
            </Box>
          ) : (
            'Select a Brand Voice'
          );
        }}
      >
        {isLoading && (
          <MenuItem disabled>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            Loading...
          </MenuItem>
        )}

        {!isLoading && brandVoices.length === 0 && (
          <MenuItem disabled>No brand voices found</MenuItem>
        )}

        {!isLoading &&
          brandVoices.length > 0 &&
          brandVoices.map(brandVoice => (
            <MenuItem key={brandVoice.id} value={brandVoice.id || ''}>
              <ListItemIcon>
                <Avatar sx={{ width: 24, height: 24 }} src={brandVoice.thumbnailUrl} />
              </ListItemIcon>
              <ListItemText>{brandVoice.name || 'Unnamed Brand Voice'}</ListItemText>
            </MenuItem>
          ))}

        {isFetchingNextPage && (
          <MenuItem disabled sx={{ justifyContent: 'center', py: 1 }}>
            <CircularProgress size={20} />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
